//import wkx from 'wkx';
import bcrypt from 'bcryptjs'; 
import PostGraphileApi from '../modules/PostGraphileApi';

// -----------------------------------------------------------------------

// https://betterexplained.com/articles/how-to-optimize-your-site-with-gzip-compression/
// https://webmasters.stackexchange.com/questions/4607/is-there-an-easy-way-to-see-amount-of-compression-in-chrome
const Helper={

  isNullOrUndefined(v) {
    return v===null || v===undefined;
  },

  utc2LocalStr(dateStr) {
    return (Date.parse(dateStr))
      ?new Date(new Date(dateStr).getTime()+new Date(dateStr).getTimezoneOffset()*60000).toISOString()
      :dateStr // wenn kein valides datum, dann unveraendert zurueck (wegen MUI DateTimePicker und react)
  },

  local2UTCStr(dateStr) {
    if (!Date.parse(dateStr)) { return dateStr } // wenn kein valides datum, dann unveraendert zurueck (wegen MUI DateTimePicker und react)
    let isodateUTCstring=null;
    //    try {
          console.log("Helper.local2UTCStr() newValue="+dateStr)
        console.log("Helper.local2UTCStr() newValue.toISOString()="+dateStr.toISOString())
        const isodate = new Date(dateStr.toISOString())
        //this.addHours(1,isodate)
        const isodateUTC=new Date(isodate.getTime()-isodate.getTimezoneOffset()*60000)
        console.log("Helper.local2UTCStr() isodateUTC="+isodateUTC)
        isodateUTCstring=isodateUTC.toISOString()
        console.log("Helper.local2UTCStr() isodateUTCstring="+isodateUTCstring)
    //  } catch (error) {
        //console.error(error);
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
        //return
    //  }
    return isodateUTCstring
  },

  bcrypty(text) {
     // https://flaviocopes.com/javascript-bcrypt/
    // https://www.npmjs.com/package/bcryptjs
    //const text="ralf";
    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(text, salt);
    // Store hash in your password DB.
    console.log("bcrypt.compareSync="+bcrypt.compareSync(text, hash));
    return hash;
  },
/*
  initFetchOpts: (query) => {
    return {
      method: "POST",
      headers: { 
        "Accept": "application/json", // response data format
        "Content-Type": "application/json", // request body data format
        "Accept-Encoding": "gzip, deflate" // kann fehlen, der browser erkennt's trotzdem
      },
      body: JSON.stringify({ query })
    };
  },
*/
  // -----------------------------------------------------------------------------------
  // https://material.io/tools/icons/?style=baseline
  // -----------------------------------------------------------------------------------

  // private:
  desc: (a, b, orderBy) => {
    //console.log("b[orderBy]="+b[orderBy]);
    if (orderBy.includes(".")) { // auswahllisten-daten
      const str=orderBy.split(".");
      //console.log("orderBy="+orderBy+" "+str[0]+" "+str[1]);
      if (b[str[0]][str[1]] < a[str[0]][str[1]]) { return -1; }
      if (b[str[0]][str[1]] > a[str[0]][str[1]]) { return 1; }
      return 0;  
    }
    /*    
    if (orderBy==="customerFirstName") {
      if (b["customer"]["firstName"] < a["customer"]["firstName"]) { return -1; }
      if (b["customer"]["firstName"] > a["customer"]["firstName"]) { return 1; }
      return 0;  
    }  
    */  
    if (b[orderBy] < a[orderBy]) { return -1; }
    if (b[orderBy] > a[orderBy]) { return 1; }
    return 0;
  },
  
  getSorting: (order, orderBy) => {
    return order === 'desc' 
      ? (a, b) => Helper.desc(a, b, orderBy) : 
      (a, b) => -Helper.desc(a, b, orderBy);
  },

  stableSort: (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      //console.log("stableSort()="+JSON.stringify(a)+","+JSON.stringify(b));
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  },

  isSelected: (state,id) => { return state.selected.indexOf(id) !== -1; },

  quoteRegexFilter: (filter) => {
    return filter.replace(/[[{}().*+?$|^\\]/g, '\\$&');
  },
  
  isEmptyTable: (id) => { return id===-1; },

  newDispatchLogObj: (fetch_url,tableName,tableId,licSpProductKey,infoEmailContacts,accessToken) => {
    //console.log("infoEmailContacts="+infoEmailContacts);
    const now = new Date().toISOString();
    const graphqlParameter= `
    status: "Pending"
    comment: ""
    createdAt: "${now}"
    sentAt: "3000-01-01T00:00:00+00:00"
    tableName: "${tableName}"
    tableId: ${tableId}
    licSpProductKey: "${licSpProductKey}"
    baseEmailRecipients: ${textareaList2Array(infoEmailContacts)}
    `;

    const query=`
    mutation {
      createDispatchLog(input: {dispatchLog: {
      `+graphqlParameter+`
      }}) 
      {
        dispatchLog {id}
      }
    }
    `;
    console.log("new-dispatchlog-query="+query);
  
    PostGraphileApi.fetchRequest(fetch_url,PostGraphileApi.httpRequestData(query,accessToken),"Helper.newDispatchLogObj() fehler_1=")
    .then(({data}) => { 
          // this.setState({
          //  selectedId:data.createPurchase.purchase.id,  // im formular neue id anzeigen
          //  selected:[data.createPurchase.purchase.id],  // checkbox on bei new
          //  editmode:'update',
          //});
          //this.loadGraphql(LOAD_GRAPHQL(GRAPHQL_PARAMETER,this.props.customerIdFix),stateKey,key);
          //this.loadGraphqlCustomer(LOAD_SELECTIONLIST_CUSTOMERS_GRAPHQL,customerStateKey,customersKey);  //S3
          //this.loadGraphqlProduct(LOAD_SELECTIONLIST_PRODUCTS_GRAPHQL,productStateKey,productsKey);  //S3
        }
    )
    .catch(function(error) { console.log("newDispatchLogObj() fehler="+error); });
  },

  handleRequestSort: function (event, property) {
    const orderBy = property;
    //console.log("orderBy="+orderBy);
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  },

  handleSelectAllClick: function (event) {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  },    

  handleChangePage: function (event, page) {
    this.setState({ page });
  },
  
  handleChangeRowsPerPage: function (event) {
    this.setState({ rowsPerPage: event.target.value });
  },
/*
  handleTextfieldChange: function (name) {
    return  event => {
      //console.log("Helper.handleTextfieldChange(), event.target.value="+event.target.value+" name="+name);
      this.setState({
        [name]: event.target.value, // siehe https://stackoverflow.com/questions/11508463/javascript-set-object-key-by-variable
      });
    }
  },  
*/
  handleTextfieldChangeW: function (name) {
    return  event => {
      //console.log("Helper.handleTextfieldChangeW(), event.target.value="+event.target.value+" name="+name);
      this.setState({
        workobject:{...this.state.workobject,[name]: event.target.value,} // siehe https://stackoverflow.com/questions/11508463/javascript-set-object-key-by-variable
      });
    }
  },  

  handleTextfieldChangeY: function (name) {
    return  (event,newVal) => {
      //console.log("Helper.handleTextfieldChange(), event.target.value="+event.target.value+" name="+name);
      this.setState({
        workobject:{...this.state.workobject,[name]: newVal}, // siehe https://stackoverflow.com/questions/11508463/javascript-set-object-key-by-variable
      });
    }
  },  

  handleTextfieldChangeWithParameterWidgets: function (name,selections) {
    return  event => {
      console.log("Helper.handleTextfieldChange(), selections[event.target.value][0]="+selections[event.target.value][0]);
      this.setState({
        workobject:{
          ...this.state.workobject,
          [name]: event.target.value, // siehe https://stackoverflow.com/questions/11508463/javascript-set-object-key-by-variable
          param1: selections[event.target.value][0],
          param2: selections[event.target.value][0],
          param3: selections[event.target.value][0],
          param4: selections[event.target.value][0],
          param5: selections[event.target.value][0],
          param6: selections[event.target.value][0],
          param7: selections[event.target.value][0],
          param8: selections[event.target.value][0],
          param9: selections[event.target.value][0],
          param10: selections[event.target.value][0],
          param11: selections[event.target.value][0],
          param12: selections[event.target.value][0],
          param13: selections[event.target.value][0],
          param14: selections[event.target.value][0],
          param15: selections[event.target.value][0],
          param16: selections[event.target.value][0],
          param17: selections[event.target.value][0],
          param18: selections[event.target.value][0],
          param19: selections[event.target.value][0],
          param20: selections[event.target.value][0],
        }
      });
    }
  },  
/*
  handleCheckboxChange: function (name) {
    return event => {
      //console.log("Helper.handleCheckboxChange(), event.target.checked="+event.target.checked+" name="+name);
      this.setState({ ...this.state, [name]: (event.target.checked) });
    }
  },
*/
  handleCheckboxChangeW: function (name) {
    return event => {
      //console.log("Helper.handleCheckboxChange(), event.target.checked="+event.target.checked+" name="+name);
      this.setState({
        workobject:{...this.state.workobject,[name]: event.target.checked,} // siehe https://stackoverflow.com/questions/11508463/javascript-set-object-key-by-variable
      });
    }
  },

/*
  addHours(numOfHours, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date;
  }
*/
  // TODO: besser mit moment.isvalid() oder Date.parse() oder try-catch?
  handleTextfieldChangeDate: function(name, newValue) {
      this.setState({ 
      workobject:{...this.state.workobject,
        [name]: Helper.local2UTCStr(newValue), 
      }          
    })
    return;
  },

  handleAlertDialogDisagree: function () {
    //console.log('Helper.handleAlertDialogDisagree()');
    this.setState({isOpenAlertDialog:false,isOpenErrorDialog:false,isOpenSendDialog:false});
  },
     
  handleAlertDialogClose: function () {
    //console.log('Helper.handleAlertDialogClose()');
    this.setState({isOpenAlertDialog:false,isOpenErrorDialog:false,isOpenSendDialog:false});
  },
  
  handleTabChange: function (event, newValue) {
    this.setState({tabvalue:newValue});
  },

  updateProtocolLoginName: function (LOAD_GRAPHQL,stateKey,key,fetch_url,accessToken) {
    const query=`
    mutation {
      updateProtocolLoginName(
        input: {
          id: 1
          patch: {
            loginName: "`+this.props.loginName+`" 
          }
        }
      ) {
        protocolLoginName {id}
      }
    }
    `;
    console.log(query);
    return PostGraphileApi.fetchRequest(fetch_url,PostGraphileApi.httpRequestData(query,accessToken),"Helper.updateProtocolLoginName() fehler_1=")
    .then(({data}) => { 
           console.log("updateProtocolLoginName="+query);
           //this.setState({
           // editmode:'update',
           //});
           //this.loadGraphql(LOAD_GRAPHQL,stateKey,key);
           //this.loadGraphql(loadContactGraphql,contactStateKey,contactsKey);
           //console.log('update response=',JSON.stringify({ data }));
         }
    )
    .catch((error)=> { console.log("updateProtocolLoginName() fehler="+error.message); this.setState({isOpenErrorDialog:true,errorMsg:error.message}) });
  },
};

// -----------------------------------------------------------------------------------

function handleCheckboxProductKeysChange(ac,inac,id) {
  return event => {
    let p=[...this.state.workobject[ac]];
    if (event.target.checked && !this.state.workobject[ac].includes(id)) {
      p.push(id);
    } else {
      p=p.filter(function(value, index, arr){ return value !== id; });
    }
    let pp=[...this.state.workobject[inac]];
    if (!event.target.checked && !this.state.workobject[inac].includes(id)) {
      pp.push(id);
    } else {
      pp=pp.filter(function(value, index, arr){ return value !== id; });
    }
    //console.log("Helper.handleCheckboxChange(), event.target.checked="+event.target.checked+" name="+name);
    //console.log(p);
    //console.log(pp);
    this.setState({ workobject:{...this.state.workobject, [ac]: p, [inac]: pp }});
  }
};

// -----------------------------------------------------------------------------------

// https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex/50767210
/*
function hex(arrayBuffer) {
  const byteToHex = [];
  for (let n = 0; n <= 0xff; ++n) {
    const hexOctet = n.toString(16).padStart(2, "0");
    byteToHex.push(hexOctet);
  }

  const buff = new Uint8Array(arrayBuffer);
  const hexOctets = []; // new Array(buff.length) is even faster (preallocates necessary array size), then use hexOctets[i] instead of .push()

  for (let i = 0; i < buff.length; ++i) { 
    hexOctets.push(byteToHex[buff[i]]);
  }      

  return hexOctets.join("");
}
*/
/*
function pointstr2GeoJSON(p) {
  const s=p.slice(1,-1);
  const a=s.split(",");
  const geojsonstr='{"type":"Point","coordinates":['+a[0]+','+a[1]+']}';
  return JSON.parse(geojsonstr); 
}
*/
/*
function pointstr2WKB(pointstr) {
  const geojson=pointstr2GeoJSON(pointstr);
  //const geojson=JSON.parse(geojsonstr);
  const wkbBuffer10 = new wkx.Point(geojson.coordinates[0], geojson.coordinates[1]).toWkb();
  const wkbstring=hex(wkbBuffer10);
  console.log("wkbstring="+wkbstring);
  return wkbstring;
}
*/
/*
function WKB2GeoJSON(wkb) {
  const wkbBuffer = new Buffer(wkb, 'hex');
  const geometry = wkx.Geometry.parse(wkbBuffer);
  const geojson=geometry.toGeoJSON();
  console.log(JSON.stringify(geojson));
  //return JSON.stringify(geojson);
  return geojson;
}
*/
/*
function geoJSON2PointTuple(geojson) {
  return "("+geojson.coordinates[0]+","+geojson.coordinates[1]+")";
}
*/
function textareaList2Array(str) {
  return '['+str.split(/\n+/).map(x=>'"'+x+'"').join(',')+']';
}
/*
function textareaList2Array2(str) {
  return '['+str.split(/,/).map(x=>'"'+x+'"').join(',')+']';
}
*/
/*
function textareaList2Array3(str) {
  return '['+str.split(/,/).join(',')+']';
}
*/
function array2Textarea(a) {
  return JSON.stringify(a).replace(/\[/g,"").replace(/\]/g,"").replace(/"/g,"").replace(/,/g,"\n");
}

// -----------------------------------------------------------------------------------

export {  
  Helper, 
  handleCheckboxProductKeysChange,array2Textarea,textareaList2Array,
  //hex,pointstr2WKB,pointstr2GeoJSON,WKB2GeoJSON,geoJSON2PointTuple,
  //textareaList2Array2,textareaList2Array3,
};


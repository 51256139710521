import React from 'react';
import PropTypes from 'prop-types';
import {Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';

export default function AlertDialog(props) {
  return (
    <div>
      <Dialog
        open={props.isOpenAlertDialog}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Meldung"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.buttonDisagreeText!=="" ?           
            <Button color="primary" onClick={props.handleDisagree}>
              {props.buttonDisagreeText}
            </Button>
          : ""}          
          <Button color="primary" autoFocus onClick={props.handleAgree}>
            {props.buttonAgreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  text: PropTypes.string.isRequired,
};

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  return {

    tableRow: {
      height: "100%",
    },

    smallTableCell: {
      padding: "0 0",
      fontSize: theme.typography.fontSizeSmall,
    },

    headerCell: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },

    headerCellTeaser: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingBottom: "5px"
    },

    smallTableHeaderCell: {
      height: "100%",
      padding: "2px",
      fontSize: theme.typography.fontSizeSmall,
      backgroundColor: theme.palette.background.paper,
    },

    limitInput: {
      border: "1px solid #e0e0e0",
      width: "7ch",
      color: theme.palette.primary.dark,
    },

    inputWrapper: {
      height: "24px",
    },

    checkBoxWrapper: {
      height: "42px",
    },

    tableCellBodyStyled: {
      padding: "2px",
    },

    tableCellLevel1: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastTextLight,
    },

    tableCellLevel2: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastTextMain,
    },

    // aus Spot Weather Warnings
    tableCellLevelorange: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastTextLight,
    },

    tableCellLevelred: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastTextMain,
    },
  }
})

export default useStyles

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return ( {
        errorSnack: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.error.main,
        }
    })
})

export default useStyles
import { PageNumbers } from '../../modules/pageNumbers'

/**
 * Einfügen neuer Pages:
 * - page number vergeben in pageNumbers.js
 * - hier in getUserPages an geeigneter Stelle unter geeigneten Bedingungen hinzufügen
 * - benötigte Texte in i18n.js hinzufügen
 * - ggf. (neues) icon in icon.js definieren
 * - ggf. in DashBoard.js Teaser für die Page hinzufügen
 * - in LandingPage.js hinzufügen
 */

function getHomePage(pages) {
    return pages[0]
}

function findPage(pages, pageNumber, args) {
    let found = { item: undefined }
    findPageItem(pages, pageNumber, args, found )
    return found.item
}

function findPageItem(pages, pageNumber, args, found) {
    if (pages && pages.length > 0) {
        let filtered = pages.filter(page => page.pageNumber === pageNumber && args.loopIndex === page.loopIndex)
        if (filtered.length > 0) {
            found.item = filtered[0]
            return true
        }
        return pages.some(page => {
            return findPageItem(page.subItems, pageNumber, args, found)
        })
    }

    return false
}

function getUserPages (payload, configuration) {
    let pages = []
    let access = payload.access

    let idx = 0
    
    pages.push({index: idx++, menu: true, pageNumber: PageNumbers.Home, text: "page_home", icon: "home"})

    let allSpotWeatherPages = []
    let allSpotWeatherPagesSeparated = []
    if (access.includes("sc_demo") || access.includes("map") || access.includes("sc_all")) {
        if ((configuration.customerContext && access.includes("sc_demo")) || (configuration.customerContext && access.includes("map")) || (configuration.customerContext && access.includes("sc_all"))) {
            pages.push({index: idx++, menu: true, pageNumber: PageNumbers.WeatherMap, text: "page_weathermap_text", teaser_text: "page_weathermap_teaser", icon: "visibility"})
        }}

    if (access.includes("sc_demo") || access.includes("sc_customer") || access.includes("sc_all") || access.includes("sc_spot_separate")) {
        if ((configuration.customerContext && access.includes("sc_demo")) || (configuration.customerContext && access.includes("sc_customer")) || (configuration.customerContext && access.includes("sc_all"))) {
            pages.push({index: idx++, menu: true, pageNumber: PageNumbers.Synopsis, text: "page_synopsis_text", teaser_text: "page_synopsis_teaser", icon: "visibility"})
        }

    if (access.includes("sc_demo") || access.includes("wetterwelt_demo") || access.includes("sc_all")) {
        if ((configuration.customerContext && access.includes("sc_demo")) || (configuration.customerContext && access.includes("wetterwelt_demo")) || (configuration.customerContext && access.includes("sc_all"))) {
            pages.push({index: idx++, menu: true, pageNumber: PageNumbers.SingleSpotweather, text: "page_singlespotweather_text", teaser_text: "page_singlespotweather_teaser", icon: "visibility"})
        }




//        if (access.includes("sc_all")) {
//            let forecasts = configuration?.forecast?.forecasts
//            if (forecasts) {
//                let forecastPages = forecasts.map((f, index) => {
//                    return {
//                        index: idx++, 
//                        loopIndex: index, 
//                        textArg: f.location, 
//                        menu: true, 
//                        pageNumber: PageNumbers.SpotWeather, 
//                        text: "page_spotweather_text", 
//                        title: "page_spotweather_title", 
//                        teaser_text: "page_spotweather_teaser", 
//                        icon: "zoomIn"
//                    }
//                })
//    
//                allSpotWeatherPages = allSpotWeatherPages.concat(forecastPages)
//            }
        }

        let spotweatherRequests = configuration?.spotweather?.spotweatherRequests
        if (spotweatherRequests) {
            spotweatherRequests.forEach((spr, index) => {
                if (access.includes("sc_demo") || access.includes("sc_all") || access.includes("sc_spot_default")) {
                    spr.spotweatherLocations.nodes.forEach((location, lIndex) => {
                        allSpotWeatherPages.push({
                            index: idx++, 
                            loopIndex: index + '.' + lIndex, 
                            textArg: location.locationName, // TODO bei mehreren Reqeust:  + ' (' + spr.name + ')', 
                            menu: true, 
                            pageNumber: PageNumbers.SpotWeatherMeteo, 
                            text: "page_spotweather_text", 
                            title: "page_spotweather_title", 
                            teaser_text: "page_spotweather_teaser", 
                            icon: "zoomIn"
                        })
                    })
                }
            })
                
            spotweatherRequests.forEach((spr, index) => {
                if (access.includes("sc_spot_separate")) {
                    spr.spotweatherLocations.nodes.forEach((location, lIndex) => {
                        let subPages = []
                        subPages.push({
                            index: idx++, 
                            loopIndex: index + '.' + lIndex, 
                            textArg: location.locationName,
                            menu: true, 
                            pageNumber: PageNumbers.SpotWeatherTableOnly, 
                            text: "page_spotweather_table", 
                            title: "page_spotweather_table_title", 
                            teaser_text: "page_spotweather_table_teaser", 
                            icon: "tableChart"
                        })
                        subPages.push({
                            index: idx++, 
                            loopIndex: index + '.' + lIndex, 
                            textArg: location.locationName,
                            menu: true, 
                            pageNumber: PageNumbers.SpotWeatherGraphicOnly, 
                            text: "page_spotweather_graphic", 
                            title: "page_spotweather_graphic_title", 
                            teaser_text: "page_spotweather_graphic_teaser", 
                            icon: "timeLime"
                        })
                        allSpotWeatherPagesSeparated.push({ 
                            index: idx++, menu: true, 
                            text: "page_spotweather_location_group_text",
                            textArg: location.locationName,
                            subItems: subPages
                        })
                    })
                }
            })
        }
    }

    if (allSpotWeatherPages.length > 0) {
        pages.push(
            { 
                index: idx++, menu: true, text: "page_spotweather_group_text",
                subItems: allSpotWeatherPages
            },
        )
        if (access.includes("sc_all")) {
//            pages.push({index: idx++, menu: true, pageNumber: PageNumbers.RiskAssessment, text: "page_riskassessment_text", title: "page_riskassessment_title", teaser_text: "page_riskassessment_teaser", icon: "warning"})
        }
    }

    if (allSpotWeatherPagesSeparated.length > 0) {
        pages.push(
            { 
                index: idx++, menu: true, text: "page_spotweather_group_text",
                subItems: allSpotWeatherPagesSeparated
            },
        )
    }

    /*
    if (access.includes("sc_all") && (allSpotWeatherPages.length > 0 || allSpotWeatherPagesSeparated.length > 0)) {
        pages.push({index: idx++, menu: true, pageNumber: PageNumbers.RiskAssessment, text: "page_riskassessment_text", title: "page_riskassessment_title", teaser_text: "page_riskassessment_teaser", icon: "warning"})
    }

    if (access.includes("sc_map") || access.includes("sc_all")) {
        if (allSpotWeatherPages.length > 0) {
            // TODO icon auswählen
            pages.push({index: idx++, menu: true, pageNumber: PageNumbers.SpotWeatherMap, text: "page_spotweathermap_text", teaser_text: "page_spotweathermap_teaser", icon: "visibility"})
        }
    }
    */

    if (access.includes("sc_service") || access.includes("sc_all")) {
        pages.push(
            { 
                index: idx++, menu: true, text: "page_group_service",
                subItems: [
                    {index: idx++, filter: true, pageNumber: PageNumbers.Products, text: "page_products_text", icon: "book"},
                    {index: idx++, filter: true, pageNumber: PageNumbers.Customers, text: "page_customers_text", icon: "wc"},
                    {index: idx++, filter: true, pageNumber: PageNumbers.Authusers, text: "page_accounts_text", icon: "lockOpen"},

                    {index: idx++, filter: true, pageNumber: PageNumbers.Purchases, text: "page_purchases_text", icon: "shoppingCart"},
                    {index: idx++, filter: true, pageNumber: PageNumbers.RequestInfoSps, text: "page_requestInfoSps_text", icon: "info"},
                    {index: idx++, filter: true, pageNumber: PageNumbers.DispatchLogs, text: "page_dispatchLogs_text", icon: "send"},
                ]
            },
        )
    }

    if (access.includes("sc_spotweather_request") || access.includes("sc_all")) {
        let subItems = []
        subItems.push({index: idx++, filter: true, pageNumber: PageNumbers.SpotweatherRequests, text: "page_spotweather_requests_text", icon: "phone"})
        subItems.push({index: idx++, filter: true, pageNumber: PageNumbers.SpotweatherLocations, text: "page_spotweather_locations_text", icon: "myLocation"})
        subItems.push({index: idx++, filter: true, pageNumber: PageNumbers.SpotweatherParameters, text: "page_spotweather_parameters_text", icon: "list"})
        subItems.push({index: idx++, filter: true, pageNumber: PageNumbers.Spotweathers, text: "page_spotweathers_text", icon: "wbSunny"})

        pages.push({ index: idx++, menu: true, text: "page_group_spotweather_admin", subItems: subItems})
    }

    if (access.includes("sc_ndrCustomerDtns") || access.includes("sc_all")) {
        pages.push(
            {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtns, text: "page_ndr_customer_dtn_text_wetterdaten",
            subItems: [
                //{index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtns, text: "page_ndr_customer_dtn_text"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextUnwetterwarnung, text: "page_ndr_customer_dtn_text_unwetterwarnung"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextDeutschlandNacht, text: "page_ndr_customer_dtn_text_text_deutschland_nacht"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTexteNorddeutschland, text: "page_ndr_customer_dtn_text_texte_norddeutschland"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTexteNorddeutsche_bundeslaender, text: "page_ndr_customer_dtn_text_texte_norddeutsche_bundeslaender"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextNiedersachsenRegional, text: "page_ndr_customer_dtn_text_text_niedersachsen_regional"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextAgrarBioPollen, text: "page_ndr_customer_dtn_text_text_agrar_bio_pollen"},               
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextWasser, text: "page_ndr_customer_dtn_text_text_wasser"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextWetterspezial, text: "page_ndr_customer_dtn_text_text_wetterspezial"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsMesswerteNorddeutschland, text: "page_ndr_customer_dtn_text_messwerte_norddeutschland"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsMesswerteD_EU_WELT, text: "page_ndr_customer_dtn_text_messwerte_d_eu_welt"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextWintersport, text: "page_ndr_customer_dtn_text_text_wintersport"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsVorhersagen, text: "page_ndr_customer_dtn_text_vorhersagen_dito"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextNdrTeletext, text: "page_ndr_customer_dtn_text_ndr_teletext"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextNdrOnline, text: "page_ndr_customer_dtn_text_ndr_online"},
                {index: idx++, menu: true, filter: true, pageNumber: PageNumbers.NdrCustomerDtnsTextInfo, text: "page_ndr_customer_dtn_text_info"},
                        ]
        }
        )
        }

    if (access.includes("sc_mvd") || access.includes("sc_all")) {
      pages.push(
        {index: idx++, menu: true, text: "page_agrartext_text",
          subItems: [
            {index: idx++, filter: true, pageNumber: PageNumbers.AgrarTexts, text: "page_agrartext_text_all"},
            {index: idx++, filter: true, pageNumber: PageNumbers.AgrarTextsFrühling, text: "page_agrartext_text_frühling"},
            {index: idx++, filter: true, pageNumber: PageNumbers.AgrarTextsSommer, text: "page_agrartext_text_sommer"},
            {index: idx++, filter: true, pageNumber: PageNumbers.AgrarTextsHerbst, text: "page_agrartext_text_herbst"},
            {index: idx++, filter: true, pageNumber: PageNumbers.AgrarTextsWinter, text: "page_agrartext_text_winter"}
          ]
        }
      )
    }

    

    if (access.includes("sc_mvd") || access.includes("sc_all")) {
        pages.push(
          {index: idx++, menu: true, text: "page_biotext_text",
            subItems: [
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTexts, text: "page_biotext_text_all"},
              {index: idx++, menu: true, text: "page_biotext_text_frühling",
              subItems: [
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühling, text: "page_biotext_text_frühling"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingSchoenwetter_angenehm, text: "page_biotext_text_schoenwetter_angenehm", title: "page_biotext_text_schoenwetter_angenehm"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingExtrem_mild_sehr_heiss, text: "page_biotext_text_extrem_mild_sehr_heiss", title: "page_biotext_text_extrem_mild_sehr_heiss"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingNaehe_zum_Tiefauslaeufer, text: "page_biotext_text_naehe_zum_tiefauslaeufer", title: "page_biotext_text_naehe_zum_tiefauslaeufer"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingFront_und_Tiefkernbereich, text: "page_biotext_text_front_und_tiefkerngebereich", title: "page_biotext_text_front_und_tiefkerngebereich"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingWarmsektor, text: "page_biotext_text_warmsektor", title: "page_biotext_text_warmsektor"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingRueckseitenwetter, text: "page_biotext_text_rueckseitenwetter", title: "page_biotext_text_rueckseitenwetter"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingNasskalt_Nebel, text: "page_biotext_text_nasskalt_nebel", title: "page_biotext_text_nasskalt_nebel"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingStrenge_Winterlage, text: "page_biotext_text_strenge_winterlage", title: "page_biotext_text_strenge_winterlage"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsFrühlingEgal, text: "page_biotext_text_egal", title: "page_biotext_text_egal"}
              ]
            },
                {index: idx++, menu: true, text: "page_biotext_text_sommer",
                subItems: [
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommer, text: "page_biotext_text_sommer"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerSchoenwetter_angenehm, text: "page_biotext_text_schoenwetter_angenehm", title: "page_biotext_text_schoenwetter_angenehm"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerExtrem_mild_sehr_heiss, text: "page_biotext_text_extrem_mild_sehr_heiss", title: "page_biotext_text_extrem_mild_sehr_heiss"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerNaehe_zum_Tiefauslaeufer, text: "page_biotext_text_naehe_zum_tiefauslaeufer", title: "page_biotext_text_naehe_zum_tiefauslaeufer"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerFront_und_Tiefkernbereich, text: "page_biotext_text_front_und_tiefkerngebereich", title: "page_biotext_text_front_und_tiefkerngebereich"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerWarmsektor, text: "page_biotext_text_warmsektor", title: "page_biotext_text_warmsektor"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerRueckseitenwetter, text: "page_biotext_text_rueckseitenwetter", title: "page_biotext_text_rueckseitenwetter"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerNasskalt_Nebel, text: "page_biotext_text_nasskalt_nebel", title: "page_biotext_text_nasskalt_nebel"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerStrenge_Winterlage, text: "page_biotext_text_strenge_winterlage", title: "page_biotext_text_strenge_winterlage"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsSommerEgal, text: "page_biotext_text_egal", title: "page_biotext_text_egal"}
                ]
            },
            {index: idx++, menu: true, text: "page_biotext_text_herbst",
            subItems: [
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbst, text: "page_biotext_text_herbst"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstSchoenwetter_angenehm, text: "page_biotext_text_schoenwetter_angenehm", title: "page_biotext_text_schoenwetter_angenehm"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstExtrem_mild_sehr_heiss, text: "page_biotext_text_extrem_mild_sehr_heiss", title: "page_biotext_text_extrem_mild_sehr_heiss"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstNaehe_zum_Tiefauslaeufer, text: "page_biotext_text_naehe_zum_tiefauslaeufer", title: "page_biotext_text_naehe_zum_tiefauslaeufer"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstFront_und_Tiefkernbereich, text: "page_biotext_text_front_und_tiefkerngebereich", title: "page_biotext_text_front_und_tiefkerngebereich"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstWarmsektor, text: "page_biotext_text_warmsektor", title: "page_biotext_text_warmsektor"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstRueckseitenwetter, text: "page_biotext_text_rueckseitenwetter", title: "page_biotext_text_rueckseitenwetter"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstNasskalt_Nebel, text: "page_biotext_text_nasskalt_nebel", title: "page_biotext_text_nasskalt_nebel"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstStrenge_Winterlage, text: "page_biotext_text_strenge_winterlage", title: "page_biotext_text_strenge_winterlage"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsHerbstEgal, text: "page_biotext_text_egal", title: "page_biotext_text_egal"}
                ]
            },
            {index: idx++, menu: true, text: "page_biotext_text_winter",
            subItems: [
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinter, text: "page_biotext_text_winter"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterSchoenwetter_angenehm, text: "page_biotext_text_schoenwetter_angenehm", title: "page_biotext_text_schoenwetter_angenehm"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterExtrem_mild_sehr_heiss, text: "page_biotext_text_extrem_mild_sehr_heiss", title: "page_biotext_text_extrem_mild_sehr_heiss"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterNaehe_zum_Tiefauslaeufer, text: "page_biotext_text_naehe_zum_tiefauslaeufer", title: "page_biotext_text_naehe_zum_tiefauslaeufer"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterFront_und_Tiefkernbereich, text: "page_biotext_text_front_und_tiefkerngebereich", title: "page_biotext_text_front_und_tiefkerngebereich"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterWarmsektor, text: "page_biotext_text_warmsektor", title: "page_biotext_text_warmsektor"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterRueckseitenwetter, text: "page_biotext_text_rueckseitenwetter", title: "page_biotext_text_rueckseitenwetter"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterNasskalt_Nebel, text: "page_biotext_text_nasskalt_nebel", title: "page_biotext_text_nasskalt_nebel"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterStrenge_Winterlage, text: "page_biotext_text_strenge_winterlage", title: "page_biotext_text_strenge_winterlage"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsWinterEgal, text: "page_biotext_text_egal", title: "page_biotext_text_egal"}
             ]
            },
              {index: idx++, menu: true, text: "page_biotext_text_allgemein",
              subItems: [
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemein, text: "page_biotext_text_allgemein"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinSchoenwetter_angenehm, text: "page_biotext_text_schoenwetter_angenehm", title: "page_biotext_text_schoenwetter_angenehm"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinExtrem_mild_sehr_heiss, text: "page_biotext_text_extrem_mild_sehr_heiss", title: "page_biotext_text_extrem_mild_sehr_heiss"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinNaehe_zum_Tiefauslaeufer, text: "page_biotext_text_naehe_zum_tiefauslaeufer", title: "page_biotext_text_naehe_zum_tiefauslaeufer"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinFront_und_Tiefkernbereich, text: "page_biotext_text_front_und_tiefkerngebereich", title: "page_biotext_text_front_und_tiefkerngebereich"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinWarmsektor, text: "page_biotext_text_warmsektor", title: "page_biotext_text_warmsektor"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinRueckseitenwetter, text: "page_biotext_text_rueckseitenwetter", title: "page_biotext_text_rueckseitenwetter"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinNasskalt_Nebel, text: "page_biotext_text_nasskalt_nebel", title: "page_biotext_text_nasskalt_nebel"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinStrenge_Winterlage, text: "page_biotext_text_strenge_winterlage", title: "page_biotext_text_strenge_winterlage"},
              {index: idx++, filter: true, pageNumber: PageNumbers.BioTextsAllgemeinEgal, text: "page_biotext_text_egal", title: "page_biotext_text_egal"}
              ]
            },
            ]
          }
        )
      }
  
    if (access.includes("sc_service") || access.includes("sc_all")) {
        pages.push(
            { 
                index: idx++, menu: true, text: "page_group_protocols",
                subItems: [
                    {index: idx++, filter: true, pageNumber: PageNumbers.Protocols, text: "page_protocol_service_text", title: "page_protocol_service_title", icon: "changeHistory"},
                    {index: idx++, filter: true, pageNumber: PageNumbers.MeteoModelImportPaths, text: "page_meteo_model_import_path_text", icon: "changeHistory"},


                ]
            },
        )
    }

    if (access.includes("sc_ports")) {
        pages.push(
            { 
                index: idx++, menu: true, text: "page_group_geodata",
                subItems: [
                    { index: idx++, filter: true, pageNumber: PageNumbers.WetterweltPorts, text: "page_geodata_ports_text", icon: "rowing"},
                ]
            },
        )
    }

    if (access.includes("sc_all")) {
        pages.push(
            { 
                index: idx++, text: "page_group_test_graphic",
                subItems: [
                    {index: idx++, pageNumber: PageNumbers.SpotWeatherD3, text: "page_spotweather_text_d3", icon: "zoomIn"},
                    {index: idx++, pageNumber: PageNumbers.SpotWeatherVictory, text: "page_spotweather_text_victory", teaser_text: "page_spotweather_teaser_victory",icon: "zoomIn"},
                    {index: idx++, pageNumber: PageNumbers.SpotWeatherVega, text: "page_spotweather_text_vega", icon: "zoomIn"},
//                    {index: idx++, pageNumber: PageNumbers.SpotWeatherNivo, text: "page_spotweather_text_nivo", icon: "zoomIn"},
//                    {index: idx++, pageNumber: PageNumbers.SpotWeatherRecharts, text: "page_spotweather_text_recharts", icon: "zoomIn"},
                    {index: idx++, pageNumber: PageNumbers.SpotWeatherAnni1, text: "page_spotweather_text_anni_1", icon: "zoomIn"},
                    {index: idx++, pageNumber: PageNumbers.SpotWeatherAnni2, text: "page_spotweather_text_anni_2", icon: "zoomIn"},
                ]
            }
        )
    }

    if (access.includes("sc_all")) {
        pages.push(
            { 
                index: idx++, text: "page_group_datavis",
                subItems: [
                    { index: idx++, pageNumber: PageNumbers.AstroParent, text: "page_datavis_samples_text", icon: "equalizer"},
                   { index: idx++, pageNumber: PageNumbers.TestDatavizMain3, text: "page_datavis_samples_text3", icon: "equalizer"},
                ]
            }
        )
    }

    if (access.includes("sc_easteregg")) {
        pages.push(
            { 
                index: idx++, text: "page_group_bighistory",
                subItems: [
                    { index: idx++, filter: true, pageNumber: PageNumbers.TaxonomyFamilies, text: "page_bighistory_taxonomyFamilies_text", icon: "bugReport"},
                    { index: idx++, filter: true, pageNumber: PageNumbers.TaxonomyGenera, text: "page_bighistory_TaxonomyGenera_text", icon: "bugReport"},
                    { index: idx++, filter: true, pageNumber: PageNumbers.O4PaleoMaps, text: "page_bighistory_O4PaleoMaps_text", icon: "map"},
                    { index: idx++, filter: true, pageNumber: PageNumbers.PaleoMaps, text: "page_bighistory_PaleoMaps_text", icon: "map"},
                    { index: idx++, filter: true, pageNumber: PageNumbers.AstroSuns, text: "page_bighistory_AstroSuns_text", icon: "public"},
                    { index: idx++, filter: true, pageNumber: PageNumbers.Spacecrafts, text: "page_bighistory_Spacecrafts_text", icon: "flightTakeoff"},
                ]
            }
        )
    }
    pages.push({index: idx++, pageNumber: PageNumbers.Impressum, text: "footer_impressum"})
    pages.push({index: idx++, pageNumber: PageNumbers.AGB, text: "footer_agb"})
    {/*pages.push({index: idx++, pageNumber: PageNumbers.Contact, text: "footer_contact"})*/}
    return pages
}

export {
    getHomePage, getUserPages, findPage
}
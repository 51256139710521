import { Typography } from '@mui/material'

const AGB = ( { styles } ) => {

    return (
        <div>
            <Typography variant="h4" className={styles.moduleText} align="left">AGB</Typography>
            <br></br>
            <Typography variant="h5" className={styles.moduleText} align="left">Allgemeine Geschäftsbedingungen der WetterWelt GmbH</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">{'(Stand 16.10.2020)'}</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">1. Geltungsbereich – allgemeine Bedingungen</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">1.1. Die nachfolgend aufgeführten allgemeinen Geschäftsbedingungen der Firma WetterWelt GmbH (im Folgenden WetterWelt) regeln die vertraglichen Beziehungen zwischen der WetterWelt und deren Kunden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Diese allgemeinen Geschäftsbedingungen gelten auch für alle zukünftigen Geschäftsbeziehungen, auch wenn sie nicht nochmals ausdrücklich vereinbart werden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Spätestens mit der Unterzeichnung eines Vertrages, dessen Bestandteil die Geschäftsbedingungen bilden, bei Abgabe einer Bestellung im online Shop oder per Bestellformular oder bei Entgegennahme von Leistungen der WetterWelt erkennt der Kunde die Geschäftsbedingungen an.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">1.2. Gegenbestätigungen des Kunden unter Hinweis auf seine Geschäftsbedingungen wird hiermit widersprochen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">2. Pflichten des Kunden</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">2.1. Der Kunde trägt Sorge für das Bereithalten der notwendigen Software und der technischen Voraussetzungen zum Download, zur Nutzung, zur Anzeige, zur Speicherung und zur sonstigen Verwendung der von WetterWelt bereit gestellten Produkte.</Typography>
            <br></br>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">2.2. Der Kunde hat für die Richtigkeit und Deckung der zum Zwecke der Zahlungsabwicklung angegebenen Konten / Kreditkarten Sorge zu tragen und Änderungen rechtzeitig bekannt zu geben. Entstehen durch eine fehlgeschlagene Abbuchung, für deren Fehlschlagen nicht WetterWelt verantwortlich ist, Kosten so trägt dieser der Kunde.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">2.3. Bei Überweisungen des Kunden von einem Konto außerhalb der EU trägt der Kunde alle anfallenden Gebühren mit der Form „OUR (sender pays costs)“</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">2.4. Der Kunde stellt sicher, dass seine hinterlegte postalische und E-Mail-Adresse aktuell gehalten sind und dass E-Mails der WetterWelt nicht als SPAM bzw. Junk E-Mails eingestuft werden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">3. Angebote, Projekt- und Leistungsbeschreibung</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">3.1 Angebote von WetterWelt sind stets freibleibend es sei denn, eine Bindefrist ist im Angebot schriftlich erwähnt.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">3.2. Angebotsunterlagen, Kostenvoranschläge und Zeichnungen bleiben das Eigentum von WetterWelt. Ohne schriftliche Zustimmung von WetterWelt dürfen sie weder weitergegeben noch vervielfältigt werden; urheberrechtliche Verwertungsrechte stehen allein WetterWelt, bzw. deren Kooperationspartnern zu. </Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">4. Vertragsdauer</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">4.1. Mit der Auftragserteilung des Kunden oder Entgegennahme von Leistungen durch den Kunden tritt der Vertrag in Kraft.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">4.2. Verträge über einen unbegrenzten Zeitraum können von jeder Vertragspartei mit einer Frist von 3 Monaten zum Ende eines jeden Quartals gekündigt werden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">4.3. Produkte mit der Kennzeichnung ABO: Die Vertragslaufzeit für diese Services beträgt ein Jahr und verlängert sich automatisch um ein weiteres Jahr, wenn nicht fristgerecht ein Monat zum Vertragsende gekündigt wird.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">4.3. Jegliche Kündigung hat schriftlich zu erfolgen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">4.4. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt hiervon unberührt.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Im Falle des vertragswidrigen Verhaltens des Kunden ist WetterWelt zur fristlosen Kündigung berechtigt. Bis zur Vertragsbeendigung trägt der Kunde sämtliche resultierenden Kosten.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">5. Preise</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">5.1. Die Preise entsprechen jeweils der bei Vertragsbeginn vorliegenden aktuellen Preisliste.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">5.2. WetterWelt ist berechtigt, jederzeit Preisänderungen vorzunehmen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">5.3. Rechnungen sind sofort und ohne Abzug fällig und zahlbar. WetterWelt ist berechtigt, im kaufmännischen Geschäftsverkehr ab Fälligkeit ansonsten bei Zahlungsverzug des Kunden nach erfolgter Mahnung Zinsen in Höhe von 4 % über dem jeweiligen Basiszinssatz zu berechnen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Die Zinsen sind sofort fällig. Kommt ein Kunde seinen Zahlungsverpflichtungen nicht nach, stellt er seine Zahlung ein oder wurden Umstände bekannt, die seine Zahlungsfähigkeit in Frage stellen, so werden alle offenen Forderungen von WetterWelt gegen den Kunden sofort fällig, auch wenn WetterWelt Wechsel oder Schecks erfüllungshalber angenommen hat. Außerdem ist WetterWelt berechtigt, die den Forderungen zugrundeliegenden Verträge fristlos zu kündigen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">5.4. Die gesetzliche Mehrwertsteuer und etwaige andere gesetzliche Abgaben werden, wenn nicht anders angegeben, zusätzlich jeweils in Höhe der zum Zeitpunkt der Rechnungsstellung gültigen Sätze berechnet.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">5.5. WetterWelt ist berechtigt, monatlich anfallende Gebühren im Voraus einzuziehen. Außerdem behält sich WetterWelt das Recht vor, Leistungen erst nach erfolgtem Zahlungseingang freizuschalten.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">6. Eigentumsvorbehalt</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Der Kaufgegenstand bleibt bis zur vollständigen Bezahlung Eigentum von WetterWelt.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">7. Besondere Bedingungen für offene Tagesseminare & persönliche Beratungen</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">7.1. Als offene Tagesseminare gelten solche, bei denen eine Buchung als einzelner Teilnehmer möglich ist. Für diese Seminare gilt eine Mindestteilnehmerzahl von 15 Personen. Wird diese Zahl unterschritten behält sich WetterWelt eine Anpassung der Stundenzahl bei gleichbleibenden Kosten oder eine Terminverschiebung vor.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">7.2. Kündigung / Stornierungen; eine Kündigung des Präsenz-Seminars ist bis 4 Wochen vorm Termin kostenfrei möglich. Bei späteren Kündigungen bis 14 Tage vor Beginn des Seminars fallen 20 % des Seminarpreises als Stornogebühren an. Bei einer späteren Kündigung fällt der volle Seminarpreis an. Dem Kunden bleibt es unbenommen, WetterWelt nachzuweisen, dass die durch seine Kündigung ersparten Aufwendungen eine wesentlich geringere Stornogebühr oder überhaupt keine Stornogebühr rechtfertigen. Bei Fernbleiben vom Seminar ohne Kündigung ist der volle Seminarpreis zu zahlen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">7.3. Als persönliche Beratungen gelten alle Produkte die eine individuelle Einschätzung eines Meteorologen / einer Meteorologin beinhalten, Beispiele sind: schriftliche und telefonische Törnberatungen, schriftliche und telefonische Regattaberatungen. </Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">7.4. Stornierungen; eine Stornierung eine persönlichen Beratung ist bis 12 Stunden vor dem vereinbarten Beratungstermin unter {'mail’at’wetterwelt.de'} kostenfrei möglich. Bei einer späteren Stornierung fällt der volle Beratungspreis an.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8. Haftung</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8.1. Der Vertragspartner erklärt sich ausdrücklich damit einverstanden, dass die Benutzung des Dienstes ausschließlich auf seine eigene Gefahr hin erfolgt.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8.2. WetterWelt beliefert den Kunden mit Daten Dritter. Diese von den Dritten bezogenen Daten werden über elektronische Kommunikationskanäle weitergeleitet. Diese Umstände liegen nicht im Einflussbereich der WetterWelt.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8.3. WetterWelt übernimmt keine Garantie dafür, dass die von dritter Seite geführten Daten fristgerecht vollständig und richtig zur Verfügung gestellt werden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8.4. WetterWelt übernimmt keine Garantie dafür, dass die verwendeten Kommunikationskanäle jederzeit betriebsbereit sind und die Daten über diese Kanäle fristgerecht, vollständig und richtig weitergeleitet werden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Verzögerungen insbesondere bei Belieferung mobiler Endgeräte können daher erfolgen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8.5. WetterWelt weist darauf hin, dass Übertragungsfehler bei der verwendeten Technologie nicht auszuschließen sind.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Dem Kunden ist daher bekannt, dass dieser ein Risiko eingeht, insbesondere wenn Entscheidungen ausschließlich oder im Wesentlichen auf Basis der von WetterWelt übermittelten Daten getroffen werden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8.6. Für eventuelle Schäden einschließlich Schäden aus ergangenem Gewinn, die durch die Nutzung der über WetterWelt erhaltenen Informationen oder deren Interpretation, Weiterverarbeitung oder Speicherung entstehen, übernimmt WetterWelt keine Haftung.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">8.7. Auf keinen Fall haftet WetterWelt für Schäden und Folgeschäden, die auf Entscheidungen oder Handlungen des Kunden zurückzuführen sind, die auf einer Interpretation der von WetterWelt gelieferten Informationen beruhen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">9. Passwort und Benutzerkennung</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">9.1. Der Kunde ist für den Schutz des ihm mitgeteilten und zugeteilten Teilnehmerkennung {'(Passwort/Benutzerkennung)'} verantwortlich und trägt sämtliche aus der Nutzung der Teilnehmerkennung entstehenden Gebühren. Bei unberechtigter Weitergabe der Teilnehmerkennung hat der Kunde WetterWelt den Hierdurch entstehenden Schaden zu ersetzen. Diese Verpflichtung besteht nach Beendigung des Vertrages fort.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">9.2. WetterWelt haftet nicht für Schäden, die dem Kunden durch Missbrauch oder Verlust der ihm zugeteilten Teilnehmerkennung {'(Passwort/Benutzerkennung)'} entstehen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">9.3. Der Kunde verpflichtet sich bei Verlust des Passwortes oder bei Verdacht der Kenntnis Dritter dies unverzüglich WetterWelt schriftlich mitzuteilen. Dies kann per E-Mail, Fax oder Brief erfolgen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">10. Verwendung beim Kunden</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">10.1. Die über WetterWelt erhaltenen Informationen sind ausschließlich für den persönlichen Gebrauch des Kunden bestimmt. Eine Weitergabe an Dritte ist unzulässig.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">10.2. Vervielfältigungen von Leistungen der WetterWelt gleich welcher Art sind ohne ausdrückliche schriftliche und vorherige Genehmigung von WetterWelt untersagt.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">10.3. Verstöße gegen den 10.2. berechtigen WetterWelt zur fristlosen Kündigung und Geltendmachung von Schadenersatzansprüchen gegen den Kunden.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Nach dem Vertragsverhältnis zwischen WetterWelt und deren Kooperationspartnern sind Schadenersatzforderungen der Kooperationspartner die durch den Kunden entstehen ebenfalls vom Kunden zu tragen.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">11. Benachrichtigung nach § 33 BDSG</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">11.1. WetterWelt weist darauf hin, dass diese zu Zwecken der Vertragsverwaltung, Abrechnung und statistisch Auswertung personenbezogene Daten des Kunden elektronisch speichert. Hierbei handelt es sich um Daten wie Name, Adresse, Bankverbindung sowie Daten aus der Vertragsdurchführung.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">11.2. Eine Weitergabe an nicht zur Verschwiegenheit verpflichteten Dritten erfolgt nicht.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">12. Erklärung zum Datenschutz</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">12.1. Die seitens des Kunden abgegebenen persönlichen Daten (Name, Anschrift und sonstige persönlichen Daten) zur Ausführung der Bestellung oder Dienstleistung werden von WetterWelt nach den strengen Richtlinien des Datenschutzgesetzes behandelt.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">13. Salvatorische Klausel / Sonstiges</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Der nach diesen Bedingungen jeweils geschlossene Vertrag bleibt auch bei rechtlicher Unwirksamkeit einzelner Punkte in seinen übrigen Teilen verbindlich. Unwirksame Bestimmungen sind von den Parteien durch wirksame zu ersetzen, die dem gewollten Zweck möglichst nahe kommen. Entsprechendes gilt im Falle einer Vertragslücke.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Gegenansprüche von WetterWelt kann der Kunde nur dann aufrechnen oder ein Zurückbehaltungsrecht geltend machen, wenn die Gegenforderung des Kunden unbestritten oder rechtskräftig ist.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">14. Gerichtsstand</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist Kiel.</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">15. Beanstandungen</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Beanstandungen gegenüber der WetterWelt sind zu richten an den Kundenservice:</Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">WetterWelt GmbH<br></br>
            Schauenburgerstraße 116<br></br>
            D-24118 Kiel / Germany<br></br>
            {'Tel: +49 (0)431/9799080'}<br></br>
            {'Fax: +49 (0)431/97990829'}<br></br>
            {'E-Mail: service@wetterwelt.net'}
            </Typography>
            <br></br>
            <Typography paragraph={true} className={styles.moduleText} align="left">Registergericht: Amtsgericht Kiel<br></br>
            Registernummer: HRB 6604 KI<br></br>
            UmsatzsteuerNr: 19 294 30746<br></br>
            Geschäftsführer: Dr. Meeno Schrader
            </Typography>
        </div>

    )
}

export {AGB}
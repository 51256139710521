import React from "react"
import ErrorSnack from "../ErrorSnack/ErrorSnack"
import { SpotWeatherDataTableMeteo } from "../SpotWeatherDataTable/SpotWeatherDataTableMeteo"
import { SpotWeather } from "./common"

const SpotWeatherTableOnly = ({ gotoArgs = {}, update }) => {

  const {weather, msg, snack, handleCloseSnack} = SpotWeather(gotoArgs, update)
console.log(weather)
  return (
    <>
    <ErrorSnack msg={msg} snackState={snack} onClose={handleCloseSnack} />
      {weather && (
        <SpotWeatherDataTableMeteo
          values={weather.values}
          params={weather.params}
          warnings={weather.warnings}
          colors={weather.colors}
        />
      )}
    </>
  )
}


export { SpotWeatherTableOnly}


import React from 'react';
import PropTypes from 'prop-types';
import {TableSortLabel,TableCell,TableHead,TableRow,Tooltip} from '@mui/material';


export default function EnhancedTableHead(props) {
  
    return ( 
        <TableHead>
          <TableRow>
          {(props.withoutSelection===undefined)?
            <TableCell key={'Auswahl'} padding="checkbox">
              Auswahl
            </TableCell>
            :<React.Fragment />
          }  
            {props.rows.map(
              row => (
                <TableCell
                  key={row.id}
                  align={props.withoutSelection===undefined && row.numeric ? 'right' : row.numeric ? 'center' : 'left'}
                  padding={row.disablePadding ? 'none' : 'normal'}
                  sortDirection={props.orderBy === row.id ? props.order : false}
                >
                  <Tooltip
                  title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={props.orderBy === row.id}
                      direction={props.order}
                      onClick={ event => { props.onRequestSort(event, row.id); } }
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ),
              this,
            )}
          </TableRow>
        </TableHead>
      ); 
}
  
  // definieren:
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    //onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from "react-i18next"

//import { Typography, CardMedia, Grid } from '@mui/material';

import { useAccessContext } from '../../modules/AccessContext'
import PostGraphileApi from '../../modules/PostGraphileApi'

import {Grid, TextField, Button} from '@mui/material';
import Typography from '@mui/material/Typography';

import markerIconPng from "leaflet/dist/images/marker-icon-2x.png"
import {Icon} from 'leaflet'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
//import proj4 from 'proj4';



const Weathermap = ( { styles, playerInterval, update } ) => {
    const { t } = useTranslation()
    const accessContext = useAccessContext()

    const [synopsisText, setSynopsisText] = useState('');
    const [warning, setWarning] = useState('');
    const [comment, setComment] = useState('');
    const [synopsisImages, setSynopsisImages] = useState([]);
    const [msg, setMessage] = useState('');

    const msgPrefix = t("error_data_api_request")
    const [snack, setSnack] = useState({ open: false });
    const handleCloseSnack = () => {
        setSnack((state) => ({ ...state, open: false}))
    }








    return (
    <div>
        <MapContainer center={[54.326914, 10.12249]} zoom={10} style={{height: "90vh", width: "90vw"}} >

      {/* OPEN STREEN MAPS TILES */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      
      </MapContainer>
    </div>
    );
}



export { Weathermap }
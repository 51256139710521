import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';

import { getUserPages } from './pages'
import { AccessContextProvider } from '../../modules/AccessContext'
import environment from './environment'
import { METEO_CONSTANTS } from '../../modules/constants'

import LandingPage from './LandingPage'
import LoginForm from '../../components/LoginForm/LoginForm'

import theme from './theme'

import PostGraphileApi from '../../modules/PostGraphileApi'

//let axios = require('axios')
import axios from 'axios'
//TODO kann das weg?
const applicationTheme = createTheme(adaptV4Theme(theme))

// Conventions DATA_API:
const APPLICATION_DATA_API_CUSTOMER_LOGO = '/logo'
const APPLICATION_DATA_API_METEO_COMMENT = '/comment'
const APPLICATION_DATA_API_METEO_WARNING = '/warning'
const APPLICATION_DATA_API_SYNOPSIS_TEXT = '/synopsis'
const APPLICATION_DATA_API_SYNOPSIS_IMAGE_1 = '/synopsis/img/1'
const APPLICATION_DATA_API_SYNOPSIS_IMAGE_2 = '/synopsis/img/2'
const APPLICATION_DATA_API_SYNOPSIS_IMAGE_3 = '/synopsis/img/3'
const APPLICATION_DATA_API_SYNOPSIS_IMAGE_4 = '/synopsis/img/4'
const APPLICATION_DATA_API_SYNOPSIS_IMAGE_5 = '/synopsis/img/5'
const APPLICATION_DATA_API_SYNOPSIS_IMAGE_6 = '/synopsis/img/6'
const APPLICATION_DATA_API_SYNOPSIS_IMAGE_7 = '/synopsis/img/7'
//const APPLICATION_DATA_API_FORECAST_1 = '/forecast/1'
//const APPLICATION_DATA_API_FORECAST_2 = '/forecast/2'

function buildConfig(payload, dataApi) {
    let config = {
        // TODO Vollzugriff auf alle Daten bei folgenden Rechten vs Sicherheit? (2022-03-16)
        customerIdFix: payload.access.includes('sc_service') || payload.access.includes('sc_all') ? undefined : payload["customerId"],
        spotweather: { // ???
            spotweatherRequests: []
        }
    }

    payload["spotweatherRequests"].forEach(item => {
        config.spotweather.spotweatherRequests.push(item)
    })

    if (dataApi) {
        config.dataApplId = dataApi.id
        let dataApiContext = dataApi.context
        config.customerContext = dataApiContext
        config.logo = { path: dataApiContext + APPLICATION_DATA_API_CUSTOMER_LOGO }
        config.meteoWarning = { path: dataApiContext + APPLICATION_DATA_API_METEO_WARNING }
        config.meteoComment = { path: dataApiContext + APPLICATION_DATA_API_METEO_COMMENT }
        config.synopsis = {
            pathSynopsisText: dataApiContext + APPLICATION_DATA_API_SYNOPSIS_TEXT,
            images: [
                {
                    path: dataApiContext + APPLICATION_DATA_API_SYNOPSIS_IMAGE_1,
                    title: "synopsis_image_title_1"
                },
                {
                    path: dataApiContext + APPLICATION_DATA_API_SYNOPSIS_IMAGE_2,
                    title: "synopsis_image_title_2"
                },
                {
                    path: dataApiContext + APPLICATION_DATA_API_SYNOPSIS_IMAGE_3,
                    title: "synopsis_image_title_3"
                },
                {
                    path: dataApiContext + APPLICATION_DATA_API_SYNOPSIS_IMAGE_4,
                    title: "synopsis_image_title_4"
                },
                {
                    path: dataApiContext + APPLICATION_DATA_API_SYNOPSIS_IMAGE_5,
                    title: "synopsis_image_title_5"
                }
            ]
        }
        // TODO : für forecasts aus dataApi, später raus nehmen?
        config.forecast = {
            forecasts: [
            ]
        }
    }

    return config
}

const Application = () => {
    const { t } = useTranslation()
    const [access, setAccess] = useState( { loggedIn: false } )
    const [error, setError] = useState( { msg: "" } )
    const [appl] = useState( { title: process.env.REACT_APP_NAME , version: process.env.REACT_APP_VERSION } )

    function logout() {
        setAccess( { loggedIn: false } )
    }
    
    useEffect(() => {
        document.title = appl.title
    }, [appl])

    async function login(credentials) {
        let api = axios.create( { baseURL: environment.auth.url } )
        let accessToken = undefined
        let status = undefined
        let payload = undefined
        let config = undefined
        let pages = undefined

        await api.post(environment.auth.path, { name: credentials.name, password: credentials.password })
        .then(result => {
            accessToken = result.data.accessToken
            let splitted = accessToken.split(".")
            if (splitted.length === 3) {
                payload = JSON.parse(atob(splitted[1]))

                // Kunden Daten aus der SC-Datenbank
                let customerPromise = new Promise((resolve, reject) => {
                    const query="{ customers (condition: {id: " + payload["customerId"] + "}) { nodes {firstName,lastName,organisationName }} }"
                    PostGraphileApi.fetchRequest(environment.sc_api.url, PostGraphileApi.httpRequestData(query,accessToken), 'customer data after login, customer id: ' + payload["customerId"])
                    .then(response => {
                        if (response?.data?.customers?.nodes?.length === 1) {
                            resolve(response.data.customers.nodes[0])
                        } else {
                            reject("customer not found")
                        }
                    }).catch(err => {
                        reject("customer error: " + err)
                    })
                })

                // Kontext Pfad für Text und Image Requests aus der USS-Datenbank (zur Zeit auch Forecast Requests)
                let dataContextPromise = new Promise((resolve, reject) => {
                    const query="{ applications(condition: {active: true, customerId: " + payload["customerId"] + "}) { nodes { context,id } } }"
                    PostGraphileApi.fetchRequest(environment.data_api.url, PostGraphileApi.httpRequestData(query,accessToken), 'data application id: ' + payload["dataId"])
                    .then(response => {
                        if (response?.data?.applications?.nodes?.length > 0) {
                            resolve(response.data.applications.nodes[0])
                        } else {
                            resolve(undefined)
                        }
                    }).catch(err => {
                        reject("data application error: " + err)
                    })
                })

                // Spot Weather Requests alleine
                let getSpotWeatherRequestPromise = (customerId, url) => {
                    return new Promise((resolve, reject) => {
                        let params = ""
                        for (let i = 1 ; i <= METEO_CONSTANTS.maxParams; i++) params = params + ("param" + i + ", ")
                        const query="{ spotweatherRequests (condition: {customerId: " + customerId + "}) {nodes{id,name,spotweatherLocations{nodes{id,locationName,lonlat}},spotweatherParameters(condition: { active: true }){nodes{id,hourFrom,minuteStep,hourTo," + params + "type}}}}} "
                        PostGraphileApi.fetchRequest(url, PostGraphileApi.httpRequestData(query,accessToken), 'spot weather requests for id: ' + customerId + ' ')
                        .then(response => {
                            if (response?.data?.spotweatherRequests?.nodes?.length > 0) {
                                resolve(response.data.spotweatherRequests.nodes)
                            } else {
                                resolve([])
                            }
                        }).catch(err => {
                            // TODO Fehlermeldung!
                            reject("spot weather requests error: " + err)
                        })
                    })
                }

                Promise.all([
                    customerPromise, 
                    dataContextPromise, 
                    getSpotWeatherRequestPromise(payload["customerId"], environment.meteo_api.url)
                ]).then(values => {
                    // customerPromise result: customer data
                    // p2 result: data application context
                    // p3 result: spotweather requests
                    let customer = values[0]
                    payload["customerName"] = customer.organisationName ? customer.organisationName : customer.firstName + customer.lastName

                    payload["spotweatherRequests"] = values[2]

                    let dataApi = values[1]
                    config = buildConfig(payload, dataApi)
                    pages = getUserPages(payload, config)

                    setError( (currentError) => ( {...currentError, msg: "" } ) )
                    status = 200
                    setAccess((currentAccess) => ({ ...currentAccess, 
                        configuration: config,
                        environment: environment,
                        pages: pages,
                        loggedIn: true, status: status, token: accessToken, payload: payload
                    }))
                }, reason => {
                    // TODO für produktiv Betrieb err ändern / rausnehmen
//                    setError( (currentError) => ( {...currentError, msg: t("login_failed_sorry") + " (" + reason + ")"} ) )
                    setError( (currentError) => ( {...currentError, msg: t("login_failed_sorry")} ) )
                    status = 500
                })
            } else {
                // TODO für produktiv Betrieb err ändern / rausnehmen
//                let err = "invalid access token"
//                console.log(err)
//                setError( (currentError) => ( {...currentError, msg: t("login_failed") + " (" + err + ")"} ) )
                setError( (currentError) => ( {...currentError, msg: t("login_failed")} ) )
                status = 500
            }
        }).catch(err => {
            setError( (currentError) => ( {...currentError, msg: t("login_failed") } ) )
            if (err.response) {
//                console.log(err)
                status = err.response.status
            } else {
                status = 500
            }
        })
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={applicationTheme}>
                { access.loggedIn 
                    ?   
                        <AccessContextProvider values={access}>
                            <LandingPage logout={logout} version={appl.version}/>
                        </AccessContextProvider>
                    :
                        <LoginForm login={login} error={error} />
                }
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default Application
import React from 'react';
import PropTypes from 'prop-types';
import {TableBody,TableCell,TableRow} from '@mui/material';

export default function TableStatus(props) {
    return(
        <TableBody>
            <TableRow>
                <TableCell>
                    <h3 style={{ color: 'gray' }}>{props.status}</h3>
                </TableCell>
            </TableRow>
        </TableBody>  
    );
}

TableStatus.propTypes = {
    status: PropTypes.string.isRequired,
};


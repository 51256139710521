import { Grid, TableContainer } from "@mui/material"
import { scaleUtc } from "d3-scale"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createContainer,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryZoomContainer,
} from "victory"
import { useAccessContext } from "../../modules/AccessContext"
import { METEO_CONSTANTS } from "../../modules/constants"
import {
  defaultColorOfParam,
  defaultDashArrayOfParam,
  defaultInterpolationVictory,
  defaultShow,
  getWarnLevelSimple,
  getWWSymbol,
  isLineParam,
  isWWParam,
  lineParamTransformation,
  xAxisTimeFormat,
} from "../../modules/layout"
import { setSpotWeather } from "../../modules/requestHelper"
import ErrorSnack from "../ErrorSnack/ErrorSnack"
import { SpotWeatherDataTableMeteo } from "../SpotWeatherDataTable/SpotWeatherDataTableMeteo"
import { CustomFlyout } from "./CustomFlyout"
import useStyles from "./styles"

// TODO styling auslagern

const SpotWeatherModuleMeteo = ({ styles, gotoArgs = {}, update }) => {
  const { t } = useTranslation()
  const accessContext = useAccessContext()

  const [weather, setWeather] = useState(undefined)
  const [msg, setMessage] = useState("")

  const msgPrefix = t("error_data_api_request")
  const [snack, setSnack] = useState({ open: false })
  const handleCloseSnack = () => {
    setSnack((state) => ({ ...state, open: false }))
  }

  const chartWidth = 600
  const chartHeight = 250
  const axis0Left = 50
  const relativeIconHeight = 0.98
  const domainHeightFactor = 1.25

  const layoutChart2 = { top: 0, bottom: 250, height: 20, legendOffset: 90 }
  const [wwParamIndex, setWWParamIndex] = useState(undefined)
  const [params, setParams] = useState([])
  const [legend, setLegend] = useState([])
  const [axis, setAxis] = useState([])
  const [checked, setChecked] = useState()
  const handleCheckChange = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked })
  }
  // const [firstCheckedLineParam, setFirstCheckedLineParam] = useState(undefined)
  const [zoom, setZoom] = useState({})
  let handleZoomVoronoi = function (domain) {
    setZoom({ zoomDomain: domain })
  }
  let handleZoomBrush = function (domain) {
    setZoom({
      zoomDomain: {
        x: domain.x,
        y: [domain.y[0], domain.y[1] * domainHeightFactor],
      },
    })
  }

  useEffect(() => {
    // init component with spot weather data
    let errorHandler = (result) => {
      setMessage(msgPrefix + result.toString())
      setSnack((state) => ({ ...state, open: true }))
    }

    let paramsList = []
    let legendData = []
    if (gotoArgs) {
      let spotweatherRequests =
        accessContext.configuration?.spotweather?.spotweatherRequests
      let requestIndex = gotoArgs.loopIndex.substring(
        0,
        gotoArgs.loopIndex.indexOf(".")
      )
      let locationIndex = gotoArgs.loopIndex.substring(
        gotoArgs.loopIndex.indexOf(".") + 1
      )

      let requestParameters =
        spotweatherRequests[requestIndex]?.spotweatherParameters.nodes
      if (requestParameters.length > 0) {
        let params = requestParameters.filter(
          (p) => p.type === METEO_CONSTANTS.paramDescriptionType
        )
        if (params && params.length > 0) {
          let paramColumns = [...new Array(METEO_CONSTANTS.maxParams)].map(
            (i, index) => params[0]["param" + (index + 1)]
          )
          paramColumns.forEach((pCol) => {
            if (pCol !== undefined && pCol[0] !== undefined) {
              paramsList.push({
                key: pCol[0],
                unit: pCol[2],
                transformation: undefined,
              })
              if (isLineParam(pCol[0])) {
                legendData.push({
                  name: t("table_header_param_" + pCol[0]),
                  labels: { fontSize: 10 },
                  symbol: {
                    fill: defaultColorOfParam(pCol[0]),
                    type:
                      defaultDashArrayOfParam(pCol[0]) === undefined
                        ? "square"
                        : "minus",
                  },
                })
              }
            }
          })
          setParams(paramsList)
          setLegend(legendData)

          let initialChecked = {}
          paramsList.forEach((p) => {
            initialChecked[p.key] = defaultShow(p.key)
          })
          setChecked(initialChecked)
        }
      }

      setSpotWeather(
        accessContext,
        spotweatherRequests,
        requestIndex,
        paramsList.length,
        locationIndex,
        t,
        setWeather,
        errorHandler
      )
    }
  }, [accessContext, msgPrefix, t, gotoArgs, update])

  useEffect(() => {
    // set dependant axis, wwParam
    let axisData = []
    let firstCheckedLineParam = undefined
    let wwParamIndex = undefined
    params.forEach((p, pIndex) => {
      if (isLineParam(p.key) && checked[p.key]) {
        let unit = p.key === "VV" && p.unit === "m" ? "km" : p.unit
        let transformation = p.transformation
        //          let existingAxis = axisData.filter(ax => ax.label === unit && ax.transformation === transformation)
        let existingAxis = axisData.filter(
          (ax) => ax.transformation === transformation
        )
        if (existingAxis.length === 0) {
          // debugger
          axisData.push({
            label: unit,
            transformation: transformation,
            orientation: axisData.length === 0 ? "left" : "right",
            offsetX:
              axisData.length === 0 ? axis0Left : 10 + axisData.length * 40,
          })
        }
        firstCheckedLineParam = firstCheckedLineParam
          ? firstCheckedLineParam
          : p.key
      }
      if (isWWParam(p.key)) {
        wwParamIndex = pIndex
      }
    })
    // debugger
    setAxis(axisData)
    // setFirstCheckedLineParam(firstCheckedLineParam)
    setWWParamIndex(wwParamIndex)
  }, [params, checked])

  useEffect(() => {
    // set zoom domain from (checked) data
    let max = 0
    if (weather) {
      params.forEach((p, pIndex) => {
        if (isLineParam(p.key) && checked[p.key]) {
          let pValues = weather.values.map((row) => {
            let parsed = parseFloat(row["param" + (pIndex + 1)])
            if (isNaN(parsed)) {
              return 0
            } else {
              return parsed
            }
          })
          let maxValue = Math.max(...pValues) // lineParamTransformation(p.key, Math.max(pValues))
          max = Math.max(max, lineParamTransformation(p.key, maxValue))
        }
      })
      max *= domainHeightFactor
      setZoom((z) => {
        if (z.zoomDomain) {
          return {
            zoomDomain: { x: z.zoomDomain.x, y: [z.zoomDomain.y[0], max] },
          }
        } else {
          return { zoomDomain: { y: [0, max] } }
        }
      })
    }
  }, [params, checked, weather])

  let lineValue = (d, pIndex, pShort) => {
    let rawValue = d["param" + (pIndex + 1)]
    let parsed = parseFloat(rawValue)

    if (isNaN(parsed)) {
      return null
    } else {
      return lineParamTransformation(pShort, parsed)
    }
  }

  let xAxisFormat = (timeValue) => xAxisTimeFormat(timeValue, t)

  /*
              <Typography variant="h5" className={styles.moduleHeader} align="left">{ t("spotweather_data_title") }</Typography>
                    <Typography variant="h5" className={styles.moduleHeader} align="left">{ t("spotweather_timeline_title") }</Typography>

    */

  const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi")

  return (
    <div className={styles.mainComponent}>
      <ErrorSnack
        msg={msg}
        snackState={snack}
        onClose={handleCloseSnack}
      ></ErrorSnack>

      <Grid container justifyContent='center' spacing={0}>
        <Grid item xs={7} className={styles.spotWeatherTableGridItem}>
          {weather && (
            <div>
              <TableContainer className={styles.spotWeatherTable}>
                <SpotWeatherDataTableMeteo
                  values={weather.values}
                  params={weather.params}
                  warnings={weather.warnings}
                  colors={weather.colors}
                  checked={checked}
                  onCheckChange={handleCheckChange}
                />
              </TableContainer>
            </div>
          )}
        </Grid>
        <Grid item xs={5} className={styles.spotWeatherTimeLineGridItem}>
          <Grid container justifyContent='center' spacing={0}>
            <Grid item xs={12}>
              {weather && (
                <div>
                  <VictoryChart
                    width={chartWidth}
                    height={chartHeight}
                    scale={{ x: scaleUtc() }}
                    theme={VictoryTheme.material}
                    containerComponent={
                      <VictoryZoomVoronoiContainer
                        voronoiDimension='x'
                        zoomDimension='x'
                        zoomDomain={zoom.zoomDomain}
                        onZoomDomainChange={handleZoomVoronoi}
                      />
                    }
                  >
                    {weather.params.map(
                      (p, pIndex) =>
                        checked[p[0]] &&
                        isLineParam(p[0]) && (
                          <VictoryLine
                            key={p[0]}
                            interpolation={defaultInterpolationVictory}
                            style={{
                              data: {
                                stroke: defaultColorOfParam(p[0]),
                                strokeWidth: 1,
                                strokeDasharray: defaultDashArrayOfParam(p[0]),
                              },
                            }}
                            data={weather.values}
                            name={p[0]}
                            x='timeAsDate'
                            y={(d) => lineValue(d, pIndex, p[0])}
                            labels={() => undefined}
                            labelComponent={
                              <VictoryTooltip
                                flyoutComponent={
                                  <CustomFlyout
                                    params={weather.params}
                                    // show={firstCheckedLineParam === p[0]}
                                    chartWidth={chartWidth}
                                    chartHeight={chartHeight}
                                    xAxisOffset={axis0Left}
                                  />
                                }
                              />
                            }
                          />
                        )
                    )}
                    {weather.params.map(
                      (p, pIndex) =>
                        checked[p[0]] &&
                        pIndex === wwParamIndex && (
                          <VictoryScatter
                            data={weather.values}
                            name='icons'
                            x='timeAsDate'
                            y={(d) =>
                              zoom.zoomDomain
                                ? zoom.zoomDomain.y[1] * relativeIconHeight
                                : 0
                            }
                            dataComponent={
                              <WeatherIconComponent
                                warnings={weather.warnings}
                                colors={weather.colors}
                                pIndex={pIndex}
                              />
                            }
                          />
                        )
                    )}
                    {axis.map((axis, axisIndex) => 
                    <VictoryAxis
                        key={axisIndex}
                        dependentAxis
                        noLabel={axis.label}
                        orientation={axis.orientation}
                        offsetX={axis.offsetX}
                        tickFormat={(t) => t}
                        style={{
                          axisLabel: { fontSize: 9, padding: 20 },
                          tickLabels: { fontSize: 8, padding: 5 },
                        }}
                      />
                      )}

                    <VictoryAxis
                      tickFormat={xAxisFormat}
                      style={{ tickLabels: { fontSize: 10 } }}
                    />
                  </VictoryChart>

                  <VictoryChart
                    padding={{
                      top: layoutChart2.top,
                      left: 50,
                      right: 50,
                      bottom: layoutChart2.bottom,
                    }}
                    width={600}
                    height={
                      layoutChart2.bottom +
                      layoutChart2.height +
                      layoutChart2.top
                    }
                    scale={{ x: scaleUtc() }}
                    theme={VictoryTheme.material}
                    containerComponent={
                      <VictoryBrushContainer
                        brushDimension='x'
                        brushDomain={zoom.zoomDomain}
                        onBrushDomainChange={handleZoomBrush}
                      />
                    }
                  >
                    <VictoryAxis
                      offsetY={layoutChart2.bottom}
                      tickFormat={xAxisFormat}
                      style={{ tickLabels: { fontSize: 10 } }}
                    />
                    {weather.params.map(
                      (p, pIndex) =>
                        checked[p[0]] &&
                        isLineParam(p[0]) && (
                          <VictoryLine
                            key={p[0]}
                            interpolation={defaultInterpolationVictory}
                            style={{
                              data: {
                                stroke: defaultColorOfParam(p[0]),
                                strokeWidth: 1,
                              },
                            }}
                            data={weather.values}
                            name={p[0]}
                            x='timeAsDate'
                            y={(d) => lineValue(d, pIndex, p[0])}
                          />
                        )
                    )}
                    <VictoryLegend
                      x={50}
                      y={
                        layoutChart2.top +
                        layoutChart2.height +
                        layoutChart2.legendOffset
                      }
                      title={t("legend_title")}
                      centerTitle
                      orientation='horizontal'
                      itemsPerRow={4}
                      gutter={15}
                      style={{
                        border: { stroke: "#004f7d" },
                        title: { fontSize: 12 },
                      }}
                      data={legend}
                    />
                    <VictoryLegend
                      x={50}
                      y={
                        layoutChart2.top +
                        layoutChart2.height +
                        layoutChart2.legendOffset +
                        100
                      }
                      title={t("legend_symbol_hint")}
                      centerTitle
                      orientation='horizontal'
                      style={{
                        border: { stroke: "#004f7d00" },
                        title: { fontSize: 12 },
                      }}
                      data={[]}
                    />
                  </VictoryChart>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const WeatherIconComponent = ({ x, y, datum, pIndex, warnings, colors }) => {
  if (pIndex !== undefined) {
    let wwCode = parseFloat(datum["param" + (pIndex + 1)][0])
    if (wwCode !== undefined) {
      let warnLevel = getWarnLevelSimple(wwCode, pIndex, warnings, colors)
      if (warnLevel !== undefined) {
        let wwSymbolNumber = getWWSymbol(wwCode)
        if (wwSymbolNumber !== undefined) {
          let imgName = "/images/w" + wwSymbolNumber + ".png"
          let size = 20
          return (
            <image
              href={imgName}
              x={x - size / 2}
              y={y}
              width={size}
              height={size}
            />
          )
        }
      }
    }
  }
  return ""
}

const SpotWeatherModuleMeteoTeaser = ({ loopIndex }) => {
  const myStyles = useStyles()
  const { t } = useTranslation()
  const accessContext = useAccessContext()

  const [weather, setWeather] = useState(undefined)

  const [zoom, setZoom] = useState({})
  let handleZoom = function (domain) {
    setZoom({ zoomDomain: domain })
  }

  useEffect(() => {
    // init component with spot weather data
    if (loopIndex) {
      let spotweatherRequests =
        accessContext.configuration?.spotweather?.spotweatherRequests
      let requestIndex = loopIndex.substring(0, loopIndex.indexOf("."))
      let locationIndex = loopIndex.substring(loopIndex.indexOf(".") + 1)

      let paramsList = []
      let requestParameters =
        spotweatherRequests[requestIndex]?.spotweatherParameters.nodes
      if (requestParameters.length > 0) {
        let params = requestParameters.filter(
          (p) => p.type === METEO_CONSTANTS.paramDescriptionType
        )
        if (params && params.length > 0) {
          let paramColumns = [...new Array(METEO_CONSTANTS.maxParams)].map(
            (i, index) => params[0]["param" + (index + 1)]
          )
          paramColumns.forEach((col) => {
            if (col !== undefined && col[0] !== undefined) {
              paramsList.push(col[0])
            }
          })
        }
        setSpotWeather(
          accessContext,
          spotweatherRequests,
          requestIndex,
          paramsList.length,
          locationIndex,
          t,
          setWeather,
          undefined
        )
      }
    }
  }, [accessContext, t, loopIndex])

  let lineValue = (d, pIndex, pShort) => {
    let rawValue = d["param" + (pIndex + 1)]
    let parsed = parseInt(rawValue)

    if (isNaN(parsed)) {
      return null
    } else {
      return lineParamTransformation(pShort, parsed)
    }
  }

  let xAxisFormat = (timeValue) => xAxisTimeFormat(timeValue, t)

  return (
    <div>
      {weather && (
        <div className={myStyles.dashboardCardMedia}>
          <VictoryChart
            width={600}
            height={250}
            scale={{ x: scaleUtc() }}
            theme={VictoryTheme.material}
            containerComponent={
              <VictoryZoomContainer
                zoomDimension='x'
                zoomDomain={zoom.zoomDomain}
                onZoomDomainChange={handleZoom}
              />
            }
          >
            {weather.params.map(
              (p, pIndex) =>
                isLineParam(p[0]) && (
                  <VictoryLine
                    key={p[0]}
                    interpolation={defaultInterpolationVictory}
                    style={{
                      data: {
                        stroke: defaultColorOfParam(p[0]),
                        strokeWidth: 1,
                      },
                    }}
                    data={weather.values}
                    name={p[0]}
                    x='timeAsDate'
                    y={(d) => lineValue(d, pIndex, p[0])}
                  />
                )
            )}
            <VictoryAxis
              tickFormat={xAxisFormat}
              style={{ tickLabels: { fontSize: 10 } }}
            />
          </VictoryChart>
        </div>
      )}
    </div>
  )
}

export { SpotWeatherModuleMeteo, SpotWeatherModuleMeteoTeaser }

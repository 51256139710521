import React from "react"
import { useState } from "react"
import ErrorSnack from "../ErrorSnack/ErrorSnack"
import SpotWeatherDataChartMeteo from "../SpotWeatherDataChart/SpotWeatherDataChartMeteo"
import SpotWeatherDataChartMeteoModularized from "../SpotWeatherDataChart/SpotWeatherDataChartMeteoModularized"
import { SpotWeather } from "./common"
import { Typography, Switch } from "@mui/material"

const SpotWeatherGraphicOnly = ({ styles, gotoArgs = {}, update }) => {

  const {weather, msg, snack, handleCloseSnack, legend} = SpotWeather(gotoArgs, update)
  const [checked, setChecked] = useState(true);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
    {/* <Switch  checked={checked} onChange={handleChange} color="default" /> */}
    
    <ErrorSnack msg={msg} snackState={snack} onClose={handleCloseSnack} ></ErrorSnack>
    {(checked) ? 
                weather && 
                (
                  <SpotWeatherDataChartMeteo
                  values={weather.values}
                  params={weather.params}
                  warnings={weather.warnings}
                  colors={weather.colors}
                  legend={legend}
                />
                )
                : weather && 
                  (
                    <SpotWeatherDataChartMeteoModularized
                    values={weather.values}
                    params={weather.params}
                    warnings={weather.warnings}
                    colors={weather.colors}
                    legend={legend}
                  />
                  )
              }
    </> 
  )
}

export { SpotWeatherGraphicOnly }


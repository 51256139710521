import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { Tooltip, IconButton, Menu, MenuItem } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'

import useStyles from './styles'
import { useAccessContext } from '../../modules/AccessContext'

const UserMenu = ( { settings } ) => {
    const { t } = useTranslation()
    const accessContext = useAccessContext()
    const styles = useStyles()

    const [userMenuAnchor, setUserMenuAnchor] = useState(null);

    const openUserMenu = (event) => { setUserMenuAnchor(event.currentTarget) };
    const closeUserMenu = () => { setUserMenuAnchor(null) };
    const handleSettings = () => { 
        if (settings) {
            settings()
        }
        setUserMenuAnchor(null) 
    };

    return <>
        { accessContext.loggedIn && (
            <>
                <Tooltip title={ accessContext.payload.name }>
                    <IconButton
                        className={styles.toolBarButton}
                        color="inherit"
                        aria-controls="userMenu"
                        aria-haspopup="true"
                        onClick={openUserMenu}
                        size="large">
                        <AccountCircle className={styles.toolBarIcon}/>
                    </IconButton>
                </Tooltip>
                { accessContext.payload.access.includes('sc_all') && (
                    <>
                        <Menu id="userMenu" anchorEl={userMenuAnchor} keepMounted open={Boolean(userMenuAnchor)} onClose={closeUserMenu}>
                            <MenuItem onClick={handleSettings}>{t("user_settings")}</MenuItem>
                        </Menu>
                    </>
                )}
            </>
        )}
    </>;
}

export default UserMenu

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {Table,TableBody,TableCell,TablePagination,TableRow,Checkbox} from '@mui/material';
import {Helper} from '../modules/Helper';
import PostGraphileApi from '../modules/PostGraphileApi';
import TableStatus from '../components/TableStatus';
import EnhancedTableHead from '../components/EnhancedTableHead';
import AlertDialog from '../components/AlertDialog';
import NdrCustomerDtnForm from './NdrCustomerDtnForm';
import { saveAs } from "file-saver";

import NdrHelper from './NdrHelper';
import { base64StringToBlob } from 'blob-util';
import JSON5 from 'json5';

// infoCategory, infoTitle, infoDescription ueber '...plusInfos'-view mit join ndr_customer_dtn x ndr_customer_dtn_extension on filename=filename

const ROWS_PER_PAGE=10;

//const COMPACT_GUI=true

class NdrCustomerDtnTable extends React.Component {
 
  static STYLES= theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    table: {
      minWidth: 400,
      whiteSpace: 'nowrap',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  });
  constructor(props) {
    super(props);

    this.DTF=new Intl.DateTimeFormat('de',{ year:'2-digit',month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',timeZone:'UTC' });  // year:'numeric',second:'2-digit' ,timeZoneName:'short'                   

    this.STATEKEY='data'; // state key fuer array aller ndrCustomerDtn objekte, s.u.
    this.KEY='ndrCustomerDtnPlusInfos'; // fuer graphql request    
    this.GRAPHQL_PARAMETER='id,customerId,filename,encoding,data,datatype,date,protocolText,infoCategory,infoTitle,infoDescription';
    this.GRAPHQL_PARAMETER_LENGTH=this.GRAPHQL_PARAMETER.split(',').length+3;
    this.ROWS = [
      //{ id: 'id', numeric: true, disablePadding: false, label: 'ID' },
      //{ id: 'customerId', numeric: true, disablePadding: false, label: 'Kunde\u2011ID' },               //S1
      //{ id: 'customer.lastName', numeric: false, label: 'Kunde\u2011Nachname' },  //S2
      //{ id: 'customer.firstName', numeric: false, label: 'Kunde\u2011Vorname' },  //S2
      //{ id: 'infoCategory', numeric: false, label: 'Kategorie' },
      //{ id: 'infoTitle', numeric: false, label: 'Titel' },
      { id: 'infoDescription', numeric: false, label: 'Beschreibung' },
      { id: 'date', numeric: false, disablePadding: true, label: 'Erstellt am '+NdrHelper.printMESZ() },
      { id: 'filename', numeric: false, label: 'Dateiname' },
      //{ id: 'encoding', numeric: false, label: 'Encoding' },
      //{ id: 'datatype', numeric: false, label: 'Dateityp' },
      //{ id: 'data', numeric: false, label: 'Dateiinhalt' },
      //{ id: 'protocolText', numeric: false, label: 'ChangeLog\u2011Kommentar' },
    ];

    this.ndrCustomerDtnExtensionStateKey='ndrCustomerDtnExtensionData'; // = state key fuer array aller ndrCustomerDtnExtension objekte, s.u.
    this.ndrCustomerDtnExtensionsKey='ndrCustomerDtnExtensions';
    this.LOAD_SELECTIONLIST_NDRCUSTOMERDTNEXTENSIONS_GRAPHQL=`{ ndrCustomerDtnExtensions { nodes { id,filename }}}`;  //parent-liste fuer auswahl-liste
  
    this.state = {
      order: 'asc',
      orderBy: 'id',
      data: [], // array aller ndrCustomerDtn objekte

      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
    
      isOpenAlertDialog:false,
      isOpenErrorDialog:false,
      errorMsg:"",

      workobject:undefined,
      workerrors:undefined,

      editmode: 'insert',
      selected: [],

      tabvalue:0, // rc 2019-10-25: selektion normieren

      isLoaded:false,

      filterGlobalOld: '',
      
      // foreign table:
      ndrCustomerDtnExtensionData:[],  // array aller ndrCustomerDtnExtension objekte
      customerSingle:'',//{},
      
      componentNumber:0,
    };
    this.setSelectedState(this.state,this.props,this.createNewObject(-1));
    this.setSelectedErrorsState(this.state,false);

    // mixins from helper:
    this.updateProtocolLoginName=Helper.updateProtocolLoginName;
    this.handleTextfieldChange = Helper.handleTextfieldChangeW;
    this.handleCheckboxChange = Helper.handleCheckboxChangeW;
    this.handleAlertDialogDisagree = Helper.handleAlertDialogDisagree;
    this.handleAlertDialogClose = Helper.handleAlertDialogClose;
    this.handleRequestSort=Helper.handleRequestSort;
    this.handleSelectAllClick=Helper.handleSelectAllClick;
    this.handleChangePage=Helper.handleChangePage;
    this.handleChangeRowsPerPage=Helper.handleChangeRowsPerPage;
    this.handleTabChange=Helper.handleTabChange; // rc 2019-10-25: selektion normieren
    this.handleTextfieldChangeDate=Helper.handleTextfieldChangeDate
    // this.handleLinkCustomer=this.handleLinkCustomer.bind(this);
  };

  // mit foreign table:
  LOAD_GRAPHQL(GRAPHQL_PARAMETER,condition_keyvals=null) {
    //console.log('condition_keyvals='+JSON.stringify(condition_keyvals));
    const condition=(Helper.isNullOrUndefined(condition_keyvals))?'':'(condition:'+JSON5.stringify(condition_keyvals).replace(/'/g,'"')+')';
    const LOAD_GRAPHQL=`{ ndrCustomerDtnPlusInfos`+condition+` { nodes { `+GRAPHQL_PARAMETER+` ,customer { id,firstName,lastName }}}}`;
    return LOAD_GRAPHQL;
  }

  createNewObject(uuid) { // TODO: foreign key muss in DB existieren:
    return {
      id:uuid,
      customerId:0,
      customerLastName:'',
      customerFirstName:'',
      filename:'',
      encoding:'utf-8',
      data:'',
      datatype:'txt',
      date:'3000-01-01T00:00:00+00:00',
      protocolText:'',
      infoCategory:'',
      infoTitle:'',
      infoDescription:'',    
    }
  }

  setSelectedState(state,props,obj) {
/*      
    const s=`
    <?xml version="1.0" encoding="UTF-8"?>
<messwerte>
 <zeit dtg="2021-11-12 16:00:00" timezone="MEZ" />
 <ort name="Amrum" region="Schleswig-Holstein" id_stat="6510017">
  <tt unit='degree_celsius'>8</tt>
  <rrr unit='mm'>0</rrr>
 </ort>
 <ort name="Buesum (MG)" region="Schleswig-Holstein" id_stat="6510030">
  <ttw unit='degree_celsius'>6</ttw>
  <tt unit='degree_celsius'>8</tt>
  <rrr unit='mm'>0</rrr>
  <ff unit='knots'>15</ff>
  <dd unit=''>SO</dd>
  <sun unit='minutes'>0</sun>
 </ort>
 <ort name="Buesum" region="Schleswig-Holstein" id_stat="10130">
  <ttw unit='degree_celsius'>7</ttw>
  <tt unit='degree_celsius'>9</tt>
  <rrr unit='mm'>0</rrr>
  <ff unit='knots'>7</ff>
  <dd unit=''>SO</dd>
  <sun unit='minutes'>0</sun>
  <wetter unit=''>stark bewölkt</wetter>
  <symbol unit=''>320000</symbol>
 </ort>
 </messwerte>
 `;
 */
    

      state.workobject={
      ...obj,
      protocolText:'Login-Name:'+props.loginName+' '+obj.protocolText.replace(/Login[-]Name:[A-Za-z]+[ ]*/,''),
    };

  }
  
  setSelectedErrorsState(state,b) {
    state.workerrors={
      //customerId:b,
      date:b,
    }
  }

  filterRegex(regex,obj) {
    return regex.test(obj.id) ||
           regex.test(obj.customerId) ||                        //S1
           regex.test(obj.customer.lastName.toLowerCase()) ||  //S2   
           regex.test(obj.customer.firstName.toLowerCase()) ||  //S2   
           regex.test(obj.filename.toLowerCase()) || 
           regex.test(obj.encoding.toLowerCase()) || 
           regex.test(obj.data.toLowerCase()) || 
           regex.test(obj.datatype.toLowerCase()) || 
           regex.test(this.DTF.format(new Date(obj.date)))  ||                   
           regex.test(obj.infoCategory.toLowerCase())          ||
           regex.test(obj.infoTitle.toLowerCase())          ||
           regex.test(obj.infoDescription.toLowerCase())          ||
           regex.test(obj.protocolText.toLowerCase()); 
  }
  
  setGraphqlParameter(state) {
    return `
    customerId: ${state.workobject.customerId}      
    filename: "${state.workobject.filename}"
    encoding: "${state.workobject.encoding}"
    data: "${state.workobject.data.replace(/\n/g,'\\n').replace(/"/g,'\\"')}"
    datatype: "${state.workobject.datatype}"
    date: "${state.workobject.date}"     
    protocolText: "${state.workobject.protocolText.replace(/\n/g,'\\n')}"
    `;
  }
  
  validatePlusUnique() {
    this.loadGraphqlUniques(this.state.workobject.id,this.state.workobject.filename);
  }
  
  validate(filenameUnique) {
    const customerId=(this.state.workobject.customerId!==0) && (this.state.workobject.customerId!=='');  // foreign table
    //const filename=/^$|^[0-9]+$/.test(this.state.workobject.filename);
    const date=Date.parse(this.state.workobject.date)?true:false
    this.setState({
      workerrors:{ 
        customerId:!customerId,
        //filename:!(filename && filenameUnique),
        filename:!filenameUnique,
        date:!date,
      }
    });
    //return customerId && filename && filenameUnique;
    return customerId && filenameUnique && date;
  }

  customerSelectionString(lastName,firstName) { return lastName+", "+firstName }
  
  handleClick = (event, id, oldId,p_data) => {
    if (id === oldId && !oldId) { return; } // rc 2019-10-25: selektion normieren  (&& !oldId wegen 'copy')
    const newSelected = [id];
    //console.log(this.constructor.name+".handleClick(), checkbox-auswahl: selected-id="+id);//+" p_data="+JSON.stringify(p_data));
    const workobject = p_data.filter(x => x.id === id);
    const s={
      selected: newSelected,
      tabvalue:0, // rc 2019-10-25: selektion normieren
      editmode:'update',
      //customerSingle:{  // foreign table
      //  value:workobject[0].customerId,
      //  label:customerSelectionString(workobject[0].customer.lastName,workobject[0].customer.firstName)
      //},
      customerSingle: this.customerSelectionString(workobject[0].customer.lastName,workobject[0].customer.firstName)      
    };
    this.setSelectedState(s,this.props,workobject[0]);
    this.setSelectedErrorsState(s,false);
    this.setState(s);
  };

// UNSAFE_componentWillReceiveProps(nextProps) {}

  // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  // -> The recommended upgrade path for most use cases is to move data-fetching into componentDidMount
  componentDidMount() {
    //console.log(this.constructor.name+'.componentDidMount()');
    this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY,true); // rc 2019-10-25: selektion normieren
    this.loadGraphql(this.LOAD_SELECTIONLIST_NDRCUSTOMERDTNEXTENSIONS_GRAPHQL,this.ndrCustomerDtnExtensionStateKey,this.ndrCustomerDtnExtensionsKey);  // foreign table
  }

// -----------------------------------------------------------------------------------
// graphql: https://www.graphile.org/postgraphile/examples/#Mutations__Delete/https://www.graphile.org/postgraphile/examples/#Mutations__Update
// -----------------------------------------------------------------------------------
  
loadGraphql(graphqlQuery,stateKey,graphqlKey,isComponentDidMount=false) { // rc 2019-10-25: selektion normieren
  //console.log(this.constructor.name+" graphqlQuery="+graphqlQuery);
  PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(graphqlQuery, this.props.accessToken),this.constructor.name + ".loadGraphql() fehler_1=")
  .then(({data}) => {
    //console.log(data) 
    this.setState({[stateKey]:data[graphqlKey].nodes,isLoaded:true}); 
    return data[graphqlKey].nodes; 
  })   
  .then((data) => { // rc 2019-10-25: selektion normieren:
    if (isComponentDidMount && data.length>0) { this.handleClick(null,data[0].id,this.state.workobject.id,data); }
  })
  .catch((error)=> { console.log(this.constructor.name+".loadGraphql() fehler_2="+error.message); this.setState({isOpenErrorDialog:true,errorMsg:error.message}) });
}
  
loadGraphqlUniques(selectedId,selectedFilename) { 
  const condition='';//'(condition: {customerId:'+parentId+'})';
  const params='id,filename';
  const graphqlQuery=`{ ndrCustomerDtns`+condition+` { nodes { `+params+` }}}`;
  PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(graphqlQuery, this.props.accessToken),this.constructor.name + ".loadGraphqlUniques() fehler_1=")
  .then(({data}) => {
    //console.log("loadGraphqlUniques() data="+data['ndrCustomerDtns'].nodes[0].id);
    const isFilenameUnique=selectedFilename==='' || data['ndrCustomerDtns'].nodes.filter(n=>n.id!==selectedId && n.filename===selectedFilename).length===0;
    const valid=this.validate(isFilenameUnique);
    if (valid) { this.setState({clickButton:"ndrCustomerDtnSaveDialog",isOpenAlertDialog:true,errorMsg:""}); }
  })
  .catch((error)=> { console.log(this.constructor.name+".loadGraphqlUniques() fehler_2="+error.message); });
}

newObj() {
  // foreign table (customerID):
  const query=`
  mutation {
    createNdrCustomerDtn(input: {ndrCustomerDtn: {
    `+this.setGraphqlParameter(this.state)+`
    }}) 
    {
      ndrCustomerDtn { id }
    }
  }
  `;
  PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(query, this.props.accessToken),this.constructor.name + ".newObj() fehler_1=")
  .then(({data}) => { 
    this.setState({
      workobject:{...this.state.workobject,id:data.createNdrCustomerDtn.ndrCustomerDtn.id,},  // im formular neue id anzeigen
      selected:[data.createNdrCustomerDtn.ndrCustomerDtn.id],  // table-select-checkbox=on bei new
      editmode:'update',
    },()=>{
      this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY);
      this.loadGraphql(this.LOAD_SELECTIONLIST_NDRCUSTOMERDTNEXTENSIONS_GRAPHQL,this.ndrCustomerDtnExtensionStateKey,this.ndrCustomerDtnExtensionsKey);  // foreign table
    });
  })
  .catch(function(error) { console.log(this.constructor.name+".newObj() fehler_1="+error); });
}  

deleteObj() {
  const mymap=n=>`
    a${n}: deleteNdrCustomerDtn(input: {id: ${n}}) {
      clientMutationId
      deletedNdrCustomerDtnNodeId
      ndrCustomerDtn { id }
    }`;
  const query=`
  mutation {${this.state.selected.map(mymap)}
  }
  `;
  PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(query, this.props.accessToken),this.constructor.name + ".deleteObj() fehler_1=")
  .then(({data}) => { 
    const s={
      editmode:'',
    };
    this.setSelectedState(s,this.props,this.createNewObject(-1));  // maske vorsorglich zuruecksetzen
    this.setState(s,()=>{this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY,true);});  // rc 2019-10-25: selektion normieren
    this.loadGraphql(this.LOAD_SELECTIONLIST_NDRCUSTOMERDTNEXTENSIONS_GRAPHQL,this.ndrCustomerDtnExtensionStateKey,this.ndrCustomerDtnExtensionsKey);  // foreign table
    //this.loadGraphql(LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.customerIdFix),this.STATEKEY,this.KEY,true); 
    //console.log('delete response=',JSON.stringify({ data }));
  })
  .catch((error)=> { console.log(this.constructor.name+".deleteObj() fehler_2="+error.message); this.setState({isOpenErrorDialog:true,errorMsg:error.message}) });
  }

updateObj() {
    // foreign table (customerID):
    const query=`
    mutation {
      updateNdrCustomerDtn(
        input: {
          id: ${this.state.workobject.id}
          patch: {
          `+this.setGraphqlParameter(this.state)+`
          }
        }
      ) {
        ndrCustomerDtn { id }
      }
    }
    `;
    console.log(query)
    PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(query, this.props.accessToken),this.constructor.name + ".updateObj() fehler_1=")
    .then(({data}) => { 
      this.setState({editmode:'update',},()=>{this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY);});
      this.loadGraphql(this.LOAD_SELECTIONLIST_NDRCUSTOMERDTNEXTENSIONS_GRAPHQL,this.ndrCustomerDtnExtensionStateKey,this.ndrCustomerDtnExtensionsKey);  // foreign table
      //console.log('update response=',JSON.stringify({ data }));
    })
    .catch((error)=> { console.log(this.constructor.name+".updateObj() fehler_2="+error.message); this.setState({isOpenErrorDialog:true,errorMsg:error.message}) });
  }

// -----------------------------------------------------------------------------------
// event handler
// -----------------------------------------------------------------------------------

  handleSave() {
    if (this.state.editmode==='insert') {
      this.newObj();
    } else if (this.state.editmode==='update') {
      this.updateObj();
    } else {
      console.log("handleSave(): no action!!!")
    } 
  }

  handleNew(is_new=true) {
    // foreign table:
    const cid=!Helper.isNullOrUndefined(this.props.condition_keyvals)?this.props.condition_keyvals.customerId:this.state.workobject.customerId;  // customerId-textfeld im ndrCustomerDtns-formular richtig initialisieren
    const newObject=this.createNewObject(0);//UUID.v4());
    newObject.customerId=cid;
    const s={
      editmode: 'insert',
      selected:[],  // selected checkbox loeschen
      workobject:{...this.state.workobject,id:0,},

      // foreign table:
      customerSingle:cid===0
        ?'Kunde auswählen'
        :this.state.customerSingle
    };
    if (is_new) { this.setSelectedState(s,this.props,newObject); }
    this.setSelectedErrorsState(s,false);
    this.setState(s);
  }

  handleDelete() {
    this.updateProtocolLoginName(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY,this.props.environment.sc_api.url)
    .then(this.deleteObj());
  }

  handleLoad() {
    this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY);   
    this.loadGraphql(this.LOAD_SELECTIONLIST_NDRCUSTOMERDTNEXTENSIONS_GRAPHQL,this.ndrCustomerDtnExtensionStateKey,this.ndrCustomerDtnExtensionsKey);  // foreign table
  }
 
  handleDownload() {
    // https://yarnpkg.com/package/filesaver.js-npm
    // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    //alert("handleDownload()")
    //const blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
    if (this.state.workobject.datatype==='xml') {
      const blob = new Blob([this.state.workobject.data], {type: "text/plain;charset=utf-8"});
      //saveAs(blob, this.state.workobject.filename); 
      return new Promise(resolve => {
        saveAs(blob, this.state.workobject.filename)
        resolve(true)
       })
    } else if (this.state.workobject.datatype==='txt') {
      const blob = new Blob([this.state.workobject.data], {type: "text/plain;charset=utf-8"});
      saveAs(blob, this.state.workobject.filename); 
    } else if (this.state.workobject.datatype==='csv') {
      const blob = new Blob([this.state.workobject.data], {type: "text/plain;charset=utf-8"});
      saveAs(blob, this.state.workobject.filename); 
    } else if (this.state.workobject.datatype==='php') {
      const blob = new Blob([this.state.workobject.data], {type: "text/plain;charset=utf-8"});
      saveAs(blob, this.state.workobject.filename); 
    } else if (this.state.workobject.datatype==='ep1') {
      //const blob = new Blob([this.state.workobject.data], {type: "text/plain;charset=utf-8"});
      const blob = base64StringToBlob(this.state.workobject.data, "text/plain;charset=utf-8");
      saveAs(blob, this.state.workobject.filename); 
    } else { // zip
      //const blob = new Blob([atob(this.state.workobject.data)], {type: "application/octet-stream;base64"});
      const blob = base64StringToBlob(this.state.workobject.data, "application/octet-stream");
      saveAs(blob, this.state.workobject.filename);         
    }
    //const canvas = document.getElementById("my-canvas"),ctx = canvas.getContext("2d");
    // canvas.toBlob(function(blob) { // draw to canvas...
    //   saveAs(blob, "pretty image.png");
    // });
  }
    
//  handleLinkCustomer() {
//    console.log(this.constructor.name+".handleLinkCustomer()");
//    this.setState({componentNumber:1});
//  }
 
  handleOpenSaveDialog() {
    //if (!this.validate()) { return; }
    //this.setState({clickButton:"ndrCustomerDtnSaveDialog",isOpenAlertDialog:true,errorMsg:""});
    this.validatePlusUnique();
  }
  
  handleOpenDeleteDialog() {
    this.setState({clickButton:"ndrCustomerDtnDeleteDialog",isOpenAlertDialog:true,errorMsg:""});
  }

  handleSaveAgree() {
    this.handleSave(this.state);
    this.setState({isOpenAlertDialog:false});
  }

  handleDeleteAgree() {
    this.handleDelete(this.state);
    this.setState({isOpenAlertDialog:false});
  }

// -----------------------------------------------------------------------------------

  filterGlobal(data,filter) {
    console.log(data)
      const regex = (filter==='')?
        RegExp('.*'):
        RegExp('^'+Helper.quoteRegexFilter(filter).replace(/%/g, ".*").toLowerCase()+".*");//+'$');
      //console.log("regex test:  regex="+filter+" "+regex.test(n.filename));
      const filterdata=data.filter(n=> this.filterRegex(regex,n));
      return filterdata;
  }

  // https://www.w3schools.com/react/react_lifecycle.asp
  // The getDerivedStateFromProps method is called right before the render method
  // https://stackoverflow.com/questions/52886075/why-is-getderivedstatefromprops-is-a-static-method
  static getDerivedStateFromProps(props, state) {
    //console.log("NdrCustomerDtnTable.getDerivedStateFromProps()");
    let s={};
    if (props.filterGlobal!==state.filterGlobalOld) { s.page=0; }
    s.filterGlobalOld=props.filterGlobal;
    return s;
  }

  render() {
    //console.log(this.constructor.name+'.render()');
    const { data, order, orderBy, selected, rowsPerPage, page,isLoaded } = this.state;
    const {classes,filterGlobal}=this.props;
    const filterdata=this.filterGlobal(data,filterGlobal);

//    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filterdata.length - page * rowsPerPage);
    
    // foreign table:
    const component=
     (this.state.componentNumber===0) ?
      <div className={classes.root}>
        {/*this.props.customerIdFix={this.props.customerIdFix}*/}
        {/*<NdrCustomerDtnEnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              rows={this.ROWS}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick.bind(this)}
              onRequestSort={this.handleRequestSort.bind(this)}
              rowCount={data.length}
            />
      {(isLoaded && filterdata.length>0)?
            <TableBody>
              {Helper.stableSort(filterdata, Helper.getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = Helper.isSelected(this.state,n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id,this.state.workobject.id,this.state.data)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox"><Checkbox checked={isSelected} /></TableCell>
                      {/*<TableCell component="th" scope="row" align="right">{n.id}</TableCell>*/}
                      {/*<TableCell align="right">{n.customerId}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.customer.lastName}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.customer.firstName}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.infoCategory}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.infoTitle}</TableCell>*/}
                      <TableCell padding="normal">{n.infoDescription}</TableCell>
                      <TableCell padding="normal">{new Date(n.date.replace('+00:00','Z')).toLocaleString()}</TableCell> {/*  new Date('2021-07-24T20:37:26.007' + 'Z').toLocaleString()    this.DTF.format(new Date(n.date)) */}
                      <TableCell padding="normal">{n.filename}</TableCell>
                      {/*<TableCell padding="normal">{n.encoding}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.datatype}</TableCell>*/}
                      {/*<TableCell padding="normal">{(n.data.length>100)?n.data.substring(0,100)+"...":n.data}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.protocolText}</TableCell>*/}
                    </TableRow>
                  );
                })}
{/*
              {emptyRows > 0 && (
                <TableRow style={{ height: 30 * emptyRows }}>
                  <TableCell colSpan={this.GRAPHQL_PARAMETER_LENGTH} />
                </TableRow>
              )}
*/}              
            </TableBody>
            : (isLoaded && filterdata.length===0)
            ? <TableStatus status={"Leer"} /> 
            : <TableStatus status={"Lade..."} /> 
            }    
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[ROWS_PER_PAGE, 50, 100, 200]}
          component="div"
          count={filterdata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage.bind(this)}
          onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
        />
      <NdrCustomerDtnForm 
        workobject={this.state.workobject} 
        workerrors={this.state.workerrors}

        handleTextfieldChange={this.handleTextfieldChange.bind(this)} 
        handleCheckboxChange={this.handleCheckboxChange.bind(this)} 
        handleOpenSaveDialog={this.handleOpenSaveDialog.bind(this)} 
        handleNew={this.handleNew.bind(this)} 
        handleOpenDeleteDialog={this.handleOpenDeleteDialog.bind(this)} 
        handleLoad={this.handleLoad.bind(this)} 
        handleDownload={this.handleDownload.bind(this)} 
        handleTabChange={this.handleTabChange.bind(this)} 

        tabvalue={this.state.tabvalue}
        loginName={this.props.loginName} 
        //handleLinkCustomer={this.handleLinkCustomer} 
        handleTextfieldChangeDate={this.handleTextfieldChangeDate.bind(this)} 

        customerSingle={this.state.customerSingle}
          
        myrouterCustomers={this.props.myrouterCustomers}
        condition_keyvals={this.props.condition_keyvals}
        
        environment={this.props.environment}
        accessToken={this.props.accessToken}
          
        csvArray={NdrHelper.Cally(this.state.workobject)}

        ndrCustomerDtnExtensionData={this.state.ndrCustomerDtnExtensionData}
      />
      <AlertDialog 
        text              ={"NdrCustomerDtn "+(this.state.clickButton==="ndrCustomerDtnSaveDialog"?"speichern?":"(kaskadiert) löschen?")} 
        buttonDisagreeText={(this.state.clickButton==="ndrCustomerDtnSaveDialog"?"Nicht Speichern":"Nicht Löschen")} 
        buttonAgreeText   ={(this.state.clickButton==="ndrCustomerDtnSaveDialog"?"Speichern":"Löschen")} 
        isOpenAlertDialog={this.state.isOpenAlertDialog} 
        handleClose={this.handleAlertDialogClose.bind(this)} 
        handleAgree={this.state.clickButton==="ndrCustomerDtnSaveDialog"?this.handleSaveAgree.bind(this):this.handleDeleteAgree.bind(this)} 
        handleDisagree={this.handleAlertDialogDisagree.bind(this)}
      />
      <AlertDialog 
        text={this.state.errorMsg} 
        buttonDisagreeText={""} 
        buttonAgreeText={"OK"} 
        isOpenAlertDialog={this.state.isOpenErrorDialog} 
        handleClose={this.handleAlertDialogClose.bind(this)} 
        handleAgree={this.handleAlertDialogDisagree.bind(this)} 
        handleDisagree={this.handleAlertDialogDisagree.bind(this)}
      />
      </div> 
   :"" 
  ;    
   
   return component;
  }
}

NdrCustomerDtnTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(NdrCustomerDtnTable.STYLES)(NdrCustomerDtnTable);
import React from 'react'
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useStyles from './styles'

const ErrorSnack = ( { msg, snackState, onClose, vertical = 'top', horizontal = 'center', autoHideDuration = 6000 } ) => {
    const styles = useStyles()

    return (
        <>
            <Snackbar open={snackState.open} autoHideDuration={autoHideDuration} onClose={onClose} message={msg} 
                anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
                ContentProps={{ classes: { root: styles.errorSnack } }}
                action={<IconButton size="small" aria-label="close" color="inherit" onClick={onClose}><CloseIcon fontSize="small" /></IconButton>}
            />
        </>

    )
}

export default ErrorSnack
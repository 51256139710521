import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useTranslation } from "react-i18next"

//import { Typography, CardMedia, Grid } from '@mui/material';

import { useAccessContext } from '../../modules/AccessContext'
import PostGraphileApi from '../../modules/PostGraphileApi'

import {Grid, TextField, Button} from '@mui/material';
import Typography from '@mui/material/Typography';

import markerIconPng from "leaflet/dist/images/marker-icon-2x.png"
import {Icon} from 'leaflet'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
//import proj4 from 'proj4';
// import generatePDF from 'react-to-pdf';


const SingleSpotweather = ( { styles, playerInterval, update } ) => {
    const { t } = useTranslation()
    const accessContext = useAccessContext()

    const [synopsisText, setSynopsisText] = useState('');
    const [warning, setWarning] = useState('');
    const [comment, setComment] = useState('');
    const [synopsisImages, setSynopsisImages] = useState([]);
    const [msg, setMessage] = useState('');

    const msgPrefix = t("error_data_api_request")
    const [snack, setSnack] = useState({ open: false });
    const handleCloseSnack = () => {
        setSnack((state) => ({ ...state, open: false}))
    }
    const tablePDF = useRef()

const source = '+proj=longlat +datum=WGS84 +no_defs'; // WGS84
const target = '+proj=utm +zone=18 +datum=WGS84 +units=m +no_defs'; // UTM Zone 33N als Beispiel
const lonTest = 12.4924; // Längengrad (Longitude)
const latTest = 41.8902; // Breitengrad (Latitude)

function decimalToDMS(decimal, isLatitude) {
  const degrees = Math.floor(decimal);
  const minutesFloat = (decimal - degrees) * 60;
  const minutes = Math.floor(minutesFloat);
  const seconds = (minutesFloat - minutes) * 60;

  const direction = isLatitude
      ? (degrees >= 0 ? 'N' : 'S')
      : (degrees >= 0 ? 'E' : 'W');

  // return {
  //     degrees: Math.abs(degrees),
  //     minutes: Math.abs(minutes),
  //     seconds: Math.abs(seconds),
  //     direction: direction
  // };
  let grad = Math.abs(degrees) + '°' + Math.abs(minutes) + '\'' + Math.abs(seconds).toFixed(1) + '\"' + direction
    return grad
}
function dmsToDecimal(dms) {
  const regex = /(\d+)°(\d+)'(\d+(\.\d+)?)"([NSEW])/;
  const match = dms.match(regex);

  if (!match) {
      throw new Error("Ungültiges DMS-Format");
  }

  const degrees = parseInt(match[1]);
  const minutes = parseInt(match[2]);
  const seconds = parseFloat(match[3]);
  const direction = match[5];

  let decimal = degrees + minutes / 60 + seconds / 3600;

  if (direction === 'S' || direction === 'W') {
      decimal *= -1;
  }

  return decimal;
}


 const lonValue = useRef('')
 const latValue = useRef('')


 const lonValueGrad = useRef('')
 const latValueGrad = useRef('')
 const LocationFinderDummy = () => {
  const map = useMapEvents({
      click(e) {
        console.log(e.latlng);
        latValue.current.value = Math.round(100*e.latlng.lat)/100
        lonValue.current.value = Math.round(100*e.latlng.lng)/100
        latValueGrad.current.value = decimalToDMS(Math.round(100*e.latlng.lat)/100, true)
        lonValueGrad.current.value = decimalToDMS(Math.round(100*e.latlng.lng)/100, false)
        console.log(latValueGrad.current.value.degrees)
        //console.log(`Latitude: ${latitudeDMS.degrees}°${latitudeDMS.minutes}'${latitudeDMS.seconds.toFixed(1)}"${latitudeDMS.direction}`);
        console.log(decimalToDMS(Math.round(100*e.latlng.lat)/100, true))
        console.log(latValueGrad.current.value)
        console.log(latValue.current.value)
        newPosition.lat = latValue.current.value
        newPosition.lng = lonValue.current.value
        newPositionGrad.lat = latValueGrad.current.value
        newPositionGrad.lng = lonValueGrad.current.value
        //newPositionGrad.lat = 
        //newPositionGrad.lat = latValueGrad.degrees + '°' + latValueGrad.minutes + '\'' + latValueGrad.seconds + '\"' + latValueGrad.direction
        console.log(newPositionGrad)
        setPositionGrad(newPositionGrad)
        console.log(newPositionGrad)
        setPosition(newPosition)
        console.log(newPosition)

      },
  });
  return null;
};
  const center_One = {
    lat: "54°27'36.0\"N",
    lng: "10°21'36.0\"E",
  }

  const newPosition = {
    lat: 54.4,
    lng: 10.2,
  }
  const newPositionGrad ={
    lat: "54°27'36.0\"N",
    lng: "10°21'36.0\"E",
  }
  const handlelatlonChange = () => {
    console.log(lonValue.current.value)
    console.log(lonValueGrad.current.value)
    console.log(latValue.current.value)
    console.log(Math.round(100*latValue.current.value)/100)
    newPosition.lat = Math.round(100*latValue.current.value)/100
    newPosition.lng = Math.round(100*lonValue.current.value)/100
    
    //newPositionGrad.lat = decimalToDMS(Math.round(100*latValue.current.value)/100, true)
    //newPositionGrad.lng = decimalToDMS(Math.round(100*lonValue.current.value)/100, false)
    newPositionGrad.lat = latValueGrad.current.value
    newPositionGrad.lng = lonValueGrad.current.value
    let test = dmsToDecimal(newPositionGrad.lat)
    newPosition.lat = Math.round(100*dmsToDecimal(newPositionGrad.lat))/100
    newPosition.lng = Math.round(100*dmsToDecimal(newPositionGrad.lng))/100
    console.log(test)
    console.log(newPositionGrad)
    setPositionGrad(newPositionGrad)
    setPosition(newPosition)
}
const [singleSpotData, setsingleSpotData] = useState(null)
//  wwPdsSingleTest(request: {lonlat: [10.3, 54.5], hour_from: 0, hour_to: 9, minute_step: 60, req1: {model: "ICONDE", param1: ["FF", "m/s", "numeric(5,1)"]}, req2: {model: "ICONDE", param2: ["FFDIR","°","integer"]}, req3: {model: "ICONDE", param3: ["T","°C","numeric(5,1)"]}, req4: {model: "ICONDE", param4: ["FF50","m/s","numeric(5,1)"]}}
//wwPdsSingleTest(request: {lonlat:[10.3,54.5],hour_from:0,hour_to:9,minute_step:60,req1:{model:"ICONDE",param1:["FF","m/s","numeric(5,1)"]}}

const handlesinglerequest= () => {
  const METEO_URL ="http://grib-gis-01.local/meteodb-wod/graphql"
    const graphqlQuery = `
  query MyQuery {
    wwPdsSinglePortalRequest(request: {lonlat: [${lonValue.current.value},${latValue.current.value}], hour_from: 0, hour_to: 48, minute_step: 60, req1: {model: "ICON", param1: ["FF", "kn", "numeric(5,1)"]}, req2: {model: "ICON", param2: ["FFG","kn","numeric(5,1)"]}, req3: {model: "ICON", param3: ["FFDIR","°","integer"]}, req4: {model: "W_COPGLOBAL", param4: ["W-HSSW","m","numeric(5,1)"]}}
  )}`
 PostGraphileApi.fetchRequest(
  METEO_URL,
  PostGraphileApi.httpRequestData(graphqlQuery),
  ".loadGraphql() fehler_1="
)
.then(({ data }) => {
  // console.log('###eca',data.ecaGeojsons.nodes);
  // console.warn(JSON.stringify(data))
  console.log(data.wwPdsSinglePortalRequest.id1)
  setsingleSpotData(data.wwPdsSinglePortalRequest)
})

  //  console.log(PostGraphileApi.fetchRequest(
  //   METEO_URL,
  //   PostGraphileApi.httpRequestData(graphqlQuery),
  //   ".loadGraphql() fehler_1="
  // ))

}
let tmpvalues
let paramvalues = []
for (let i in singleSpotData) {
  paramvalues.push([i, singleSpotData[i]])

}
tmpvalues = paramvalues.slice(3).sort()
console.log(tmpvalues)
const [position, setPosition] = useState(newPosition)
const [positionGrad, setPositionGrad] = useState(newPositionGrad)
  function DraggableMarker() {

    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())
            latValue.current.value = Math.round(100*position.lat)/100
            lonValue.current.value = Math.round(100*position.lng)/100
            latValueGrad.current.value = decimalToDMS(Math.round(100*position.lat)/100, true)
            lonValueGrad.current.value = decimalToDMS(Math.round(100*position.lng)/100, false)
            console.log(latValue.current.value)
            console.log(lonValue.current.value)
          }
        },
      }),
      [],
    )

  
    return (
      <Marker
      icon={new Icon({iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png', iconSize: [25, 41], iconAnchor: [12, 41]})}
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
      </Marker>
    )
  }

    return (
    <div >

      <Grid container spacing={2}>
        <Grid item>
        <MapContainer center={[54.326914, 10.12249]} zoom={10} style={{height: "40vh", width: "40vw"}} >
        <LocationFinderDummy />
      {/* OPEN STREEN MAPS TILES */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <DraggableMarker />
      </MapContainer>

        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography  gutterBottom variant="subtitle1" component="div">
                Spotweather Konfiguration
              </Typography>
              <Typography variant="body2" gutterBottom >
                Location
              </Typography>
              <TextField
                variant="standard" 
                id="lon" label="Longitude"
                helperText=''
                defaultValue={"10°21'36.0\"E"}
                inputRef={lonValueGrad}
                onChange={handlelatlonChange}
              />
              <TextField
                variant="standard" 
                id="lat" label="Latitude"
                helperText=''
                defaultValue={"54\°27\'36.0\"N"}
                inputRef={latValueGrad}
                onChange={handlelatlonChange}
              />
              <TextField
                variant="standard" 
                id="lonOld" label="Longitude"
                type="hidden"
                helperText=''
                defaultValue={10.2}
                inputRef={lonValue}
                onChange={handlelatlonChange}
              />
              <TextField
                variant="standard" 
                id="latOld" label="Latitude"
                type="hidden"
                helperText=''
                defaultValue={54.4}
                inputRef={latValue}
                onChange={handlelatlonChange}
              />
              <Typography variant="body2">
                
              </Typography>
              <Button onClick={handlesinglerequest} >
                Submit
              </Button>
              {/* <Button onClick={() => generatePDF(tablePDF, {filename: 'page.pdf'})} >
                PDF
              </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
      <Table ref={tablePDF} sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Zeit (UTC)</TableCell>
            <TableCell align="right">Windgeschw. (kn)</TableCell>
            <TableCell align="right">Böen (kn)</TableCell>
            <TableCell align="right">Windrichtung (°)</TableCell>
            <TableCell align="right">Wellenhöhe (m)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tmpvalues.map((value) => (
            <TableRow
              key={value[0]}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {value[1].forecast_time.slice(8,10) + "." + value[1].forecast_time.slice(5,7) + "." + value[1].forecast_time.slice(0,4) + "   " + value[1].forecast_time.slice(11,16)}
              </TableCell>
              <TableCell align="right">{value[1].param1}</TableCell>
              <TableCell align="right">{value[1].param2}</TableCell>
              <TableCell align="right">{value[1].param3}</TableCell>
              <TableCell align="right">{value[1].param4}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    );
}



export { SingleSpotweather }
import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import {Button,Tooltip} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
//import Icon from '@mui/material/Icon';
//import DeleteIcon from '@mui/icons-material/Delete';
//import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';
//import DoneIcon from '@mui/icons-material/Done';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
//import { isNullOrUndefined } from 'util';
import {Helper} from '../modules/Helper';

// -----------------------------------------------------------------------

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});


function IconLabelButtons(props) {
  const { classes, myrouterCustomers, handleDownload} = props; // , handleLoad, handleOpenSaveDialog, handleNew, handleOpenDeleteDialog,
  return (
    <div>
{(!(props.isEmptyTable || props.isNullId))?
 (Helper.isNullOrUndefined(props.condition_keyvals)) ?
      <Tooltip title="Öffne zugeordneten Kunden">
        <Button variant="contained" color="primary" className={classes.button} onClick={myrouterCustomers}>
          <ArrowUpwardIcon className={classes.rightIcon}/>
          Kunde
        </Button>
      </Tooltip>  
: ""        
: "" }       
      {/*<Tooltip title="Lade ÖR-Customer-Dtns von DB">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleLoad}>
          <CloudDownloadIcon className={classes.rightIcon} />
          Laden
        </Button>
      </Tooltip>*/} 

<Tooltip title="Download Datei">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleDownload}>
          <AddIcon className={classes.rightIcon}/>
          Download
        </Button>
</Tooltip> 
{/*
{(!(props.isEmptyTable || props.isNullId))?
      <Tooltip title="Lösche ÖR-Customer-Dtn aus DB">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleOpenDeleteDialog}>
          <DeleteIcon className={classes.rightIcon} />
          Löschen
        </Button>
      </Tooltip> 
:''}  

      { (!Helper.isNullOrUndefined(props.condition_keyvals)) ?
      <Tooltip title="Neuer ÖR-Customer-Dtn">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleNew}>
          <AddIcon className={classes.rightIcon}/>
          Neu
        </Button>
      </Tooltip> 
      : "" 
      }       

{(!(props.isEmptyTable || props.isNullId || Helper.isNullOrUndefined(props.condition_keyvals)))?
      <Tooltip title="Kopiere ÖR-Customer-Dtn">
        <Button variant="contained" color="primary" className={classes.button} onClick={()=>{handleNew(false);}}>
          <AddIcon className={classes.rightIcon}/>
          Copy
        </Button>
      </Tooltip> 
:''}  
      
{(!props.isEmptyTable)?      
      <Tooltip title="Speichere ÖR-Customer-Dtn in DB">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleOpenSaveDialog}>
          <DoneIcon className={classes.rightIcon}/>
          Speichern
        </Button>
      </Tooltip>  
:''} 
*/} 
</div>

  );
}

IconLabelButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IconLabelButtons);
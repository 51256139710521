import React, { useState, createContext } from 'react'

const access = {
    loggedIn: false, 
    status: undefined, 
    token: undefined, 
    payload: undefined,
    configuration: undefined,
    environment: undefined,
    pages: []
}

const AccessContext = createContext(access)

export function AccessContextProvider(props) {
    const [context] = useState({ ...access, ...props.values})

    return (
        <AccessContext.Provider value={context}>
            {props.children}
        </AccessContext.Provider>
    );
}

export const useAccessContext = () => React.useContext(AccessContext)

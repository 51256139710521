import { createElement } from 'react';

import BookIcon from '@mui/icons-material/BookOutlined'
import BugReportIcon from '@mui/icons-material/BugReport'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistoryOutlined'
import ContactMailIcon from '@mui/icons-material/ContactMailOutlined'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import ListIcon from '@mui/icons-material/ListOutlined'
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined'
import MailIcon from '@mui/icons-material/Mail'
import MapIcon from '@mui/icons-material/Map'
import MyLocationIcon from '@mui/icons-material/MyLocationOutlined'
import PhoneIcon from '@mui/icons-material/PhoneOutlined'
import PublicIcon from '@mui/icons-material/Public'
import ReportIcon  from '@mui/icons-material/Report'
import RowingIcon  from '@mui/icons-material/Rowing'
import SendIcon from '@mui/icons-material/SendOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import TableChart from '@mui/icons-material/TableChart'
import Timeline from '@mui/icons-material/Timeline'
import VisibilityIcon  from '@mui/icons-material/Visibility'
import WarningIcon  from '@mui/icons-material/Warning'
import WbSunnyIcon from '@mui/icons-material/WbSunnyOutlined'
import WcIcon from '@mui/icons-material/WcOutlined'
import ZoomInIcon  from '@mui/icons-material/ZoomIn'

const icons = {
    book: BookIcon,
    bugReport: BugReportIcon,
    contactMail: ContactMailIcon,
    changeHistory: ChangeHistoryIcon,
    equalizer: EqualizerIcon,
    flightTakeoff: FlightTakeoffIcon,
    home: HomeIcon,
    info: InfoIcon,
    list: ListIcon,
    lockOpen: LockOpenIcon,
    mail: MailIcon,
    map: MapIcon,
    myLocation: MyLocationIcon,
    phone: PhoneIcon,
    public: PublicIcon,
    report: ReportIcon,
    rowing: RowingIcon,
    send: SendIcon,
    shoppingCart: ShoppingCartIcon,
    tableChart: TableChart,
    timeLime: Timeline,
    visibility: VisibilityIcon,
    warning: WarningIcon,
    wc: WcIcon,
    wbSunny: WbSunnyIcon,
    zoomIn: ZoomInIcon,
}

function getIconElement(name) {
    return createElement(icons[name])
}

export { icons, getIconElement }

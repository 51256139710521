import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { ClickAwayListener, Button, Popper, Paper, Collapse, ListItemIcon, ListItem, ListItemText, List } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import useStyles from './styles'

// TODO item.menu abfragen auch in tieferen Ebenen? zur zeit nur Ebene 1

function MenuBarItem ( { item, depth, homePage, currentItem, onSelect, onClose } ) {
    const { t } = useTranslation()
    const styles = useStyles()
//console.log(item)
    const [opened, setOpened] = useState([])    // opened items
    const handleCollapseClick = (clickedIndex) => {
        setOpened(opened => {
            if (opened.includes(clickedIndex)) {
              return opened.filter(itemIndex => itemIndex !== clickedIndex) // remove
            } else {
                return opened.concat(clickedIndex)    // add
            }
        })
    }

    function className(depth) {
      return depth === 0 ? styles.menuBar : (depth === 1 ? styles.menuBar1 : (depth === 2 ? styles.menuBar2 : styles.menuBar3))
    }

    // erste Menu Ebene
    // ab hier wird alles fuer die erste Menu Ebene gebraucht (aus material-ui doku zu menus, menu list composition)

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    }
  
    const prevOpen = useRef(open)
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);

    // Ende erste Menu Ebene
      
    const isSamePage = (currentItem, otherItem) => { return currentItem.pageNumber === otherItem.pageNumber && currentItem.loopIndex === otherItem.loopIndex }

    return (
        item.subItems && item.subItems.length >= 1 ?
            depth === 0 ?
                  <>
                      <Button
                          className = {styles.horizontalButton} 
                          ref={anchorRef}
                          aria-controls={open ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={handleToggle}
                      >
                          { t(item.text) }
                          { 
                              open ? // opened
                                  <ListItemIcon className={styles.expandButtonIcon} ><ExpandLess /></ListItemIcon>
                              : 
                                  <ListItemIcon className={styles.expandButtonIcon} ><ExpandMore /></ListItemIcon>
                          }
                      </Button>
                      <Popper open={open} anchorEl={anchorRef.current} disablePortal>
                          <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <List disablePadding dense id="menu-list-grow">
                                  { item.subItems.map(subItem => 
                                      <MenuBarItem 
                                        onClose={onClose ? onClose : handleClose}
                                        item={subItem} depth={depth+1} key={subItem.index} currentItem={currentItem} onSelect={onSelect} />
                                  )}
                                </List>
                              </ClickAwayListener>
                          </Paper>
                      </Popper>
                  </>
                :
                  <>
                    <ListItem button onClick={() => handleCollapseClick(item.index)}>
                        <ListItemText className={styles.menuBarExpand} classes={{primary: className(depth)}} primary={t(item.text, { textArg: item.textArg })} />
                        { item.subItems && (
                            opened.includes(item.index) ? // opened
                                <ListItemIcon className={styles.menuBarExpand} ><ExpandLess /></ListItemIcon>
                            : 
                                <ListItemIcon className={styles.menuBarExpand} ><ExpandMore /></ListItemIcon>
                        )}
                    </ListItem>
                    <Collapse in={opened.includes(item.index)} timeout="auto" unmountOnExit>
                        <List disablePadding dense>
                            { item.subItems.map(subItem =>
                                <MenuBarItem 
                                  onClose={onClose ? onClose : handleClose}
                                  item={subItem} depth={depth + 1} key={subItem.index} homePage={homePage} currentItem={currentItem} onSelect={onSelect}/>
                            )}
                        </List>
                    </Collapse>
                  </>
            :
                depth === 0 ?
                    item.subItems ? // item.subItems[0] statt item
                            <Button disabled={isSamePage(currentItem, item.subItems[0])} className={styles.horizontalButton} 
                                onClick={() => onSelect(item.subItems[0]) }>
                                    {homePage.pageNumber === item.subItems[0].pageNumber ? 'Home' : t(item.subItems[0].title ? item.subItems[0].title : item.subItems[0].text, { textArg: item.subItems[0].textArg })}</Button>
                        :
                            <Button disabled={isSamePage(currentItem, item)} className={styles.horizontalButton} onClick={() => onSelect(item) }>
                                {homePage.pageNumber === item.pageNumber ? 'Home' : t(item.text)}</Button>
                :
                    item.subItems ? // item.subItems[0] statt item
                            <ListItem button selected={isSamePage(currentItem, item.subItems[0])} onClick={() => { onSelect(item.subItems[0]); onClose() } } >
                                <ListItemText classes={{primary: className(depth)}} 
                                primary={t(item.subItems[0].title ? item.subItems[0].title : item.subItems[0].text, { textArg: item.subItems[0].textArg }) } />
                            </ListItem>
                        :
                            <ListItem button selected={isSamePage(currentItem, item)} onClick={() => { onSelect(item); onClose() } } >
                                <ListItemText classes={{
                                    primary: className(depth)}} primary={t(item.text, { textArg: item.textArg })} />
                            </ListItem>
    )
}
// const precipitation_test = () => {
//     window.open("https://portal.wetterwelt.de/radar", '_blank').focus();
//   }

const MenuBar = ( { items=[], homePage, currentItem, onSelect, logout, reload, precipitation, test } ) => {
    const { t } = useTranslation()
    const styles = useStyles()

    return (
        <nav className={styles.navigation}>
            { items.map(item => item.menu &&
                <MenuBarItem item={item} depth={0} key={item.index} homePage={homePage} currentItem={currentItem} onSelect={onSelect} />
            )}
            { logout &&
                <Button className={styles.horizontalButtonRight} onClick={logout}>{t("menubar_logout")}</Button>
            }
            { reload &&
                <Button className={styles.horizontalButtonRight} onClick={reload}>{t("menubar_reload")}</Button>
            }
            { test &&
                <Button className={styles.horizontalButtonLeft} onClick={precipitation}>{t("precipitation_radar")}</Button>
            }
        </nav>
    )
}

export default MenuBar

import React from 'react'
//import {render} from 'react-dom'
import Application from './applications/main/Application'
import "./applications/main/i18n"


import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Application tab="home" />);

/*
render(
  <Application />,
  document.getElementById('root')
);
*/


/*
// Before
import { render } from 'react-dom';
const container = document.getElementById('app');
render(<App tab="home" />, container);

// After
import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
*/
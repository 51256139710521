import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getFormattedValueFromVictoryLineDatum, getWWTextFromVictoryLineDatum, isWWParam } from "../../modules/layout"
import { useAccessContext } from '../../modules/AccessContext'

const CustomFlyout = ({
  x,
  y,
  datum,
  dx,
  dy,
  params,
  show,
  xAxisOffset,
  chartWidth,
  chartHeight,
}) => {
  const { t, i18n } = useTranslation()
  const accessContext = useAccessContext()
  const theme = useTheme()
  let marginLeft = 10
  let nameColLength = 120
  let totalWidth = marginLeft + nameColLength + 60
  let lineHeight = 20
  let span = 20
  let fontSize = 10 // theme.typography.fontSizeSmall
  let fontFamily = "Helvetica"
  let dateHeight = lineHeight
  let paramsHeight = span + dateHeight + lineHeight * params.length
  let totalHeight = paramsHeight + span

  let rx = 4.5
  let stroke = theme.palette.text.primary
  let rectBackgroundColor = "#fcfcfcbd"

  let rectOffset = 30
  //    let rectX = x - rectOffset - totalWidth
  //    if (rectX < xAxisOffset) {
  //      rectX = x + rectOffset
  //    }
  let rectX = x + rectOffset
  if (rectX + totalWidth > chartWidth - xAxisOffset) {
    rectX = x - rectOffset - totalWidth
  }

  let strokeLine = "#a0a0a0a0"
  let strokeDashArray = "3"
  let lineTop = 50 // TODO wie kommen die 50 zustande?
  for (let i = 0; i < params.length; i++) {
    if (params[i][0] === "P-WEATHER") { 
      params[i][2] = "-"
    }
  }

  return (
    <>
      
        <g>
          <line
            x1={x}
            x2={x}
            y1={chartHeight - lineTop}
            y2={lineTop}
            stroke={strokeLine}
            strokeDasharray={strokeDashArray}
          />
          <rect
            x={rectX}
            y={0}
            dx={dx}
            dy={dy}
            rx={rx}
            width={totalWidth}
            height={totalHeight}
            fill={rectBackgroundColor}
            stroke={stroke}
          />
          {params.map((p, pIndex) => (
            <>
              <text
                x={rectX + marginLeft}
                y={dateHeight + span + lineHeight * (pIndex + 1)}
                fontSize={fontSize}
                fill={stroke}
                fontFamily={fontFamily}
              >
                {t("name_param_" + p[0]) + ":"}
              </text>
              <text
                x={rectX + marginLeft + nameColLength}
                y={dateHeight + span + lineHeight * (pIndex + 1)}
                fontSize={fontSize}
                fill={stroke}
                fontFamily={fontFamily}
              >
                {isWWParam(p[0])
                  ? getWWTextFromVictoryLineDatum(
                      datum,
                      p[0],
                      p[2],
                      pIndex,
                      t,
                      i18n.language
                    )
                  : getFormattedValueFromVictoryLineDatum(
                      datum,
                      p[0],
                      p[2],
                      pIndex,
                      t
                    )}
              </text>
            </>
          ))}
                                    {(accessContext.payload.access.includes("local_time")) ?
                   <text
                   x={rectX + marginLeft}
                   y={dateHeight}
                   fontSize={fontSize}
                   fill={stroke}
                   fontFamily={fontFamily}
                 >
                   {datum.timeFormatted + " " + t("timezone_lt") + ":"}
                 </text>
                  :
                  <text
                  x={rectX + marginLeft}
                  y={dateHeight}
                  fontSize={fontSize}
                  fill={stroke}
                  fontFamily={fontFamily}
                >
                  {datum.timeFormatted + " " + t("timezone_utc") + ":"}
                </text>}
        </g>
    
    </>
  )
}

export { CustomFlyout }

//import { csv } from "d3";
import { XMLParser } from "fast-xml-parser"; // ,XMLValidator

  const OPTIONS = {
    ignoreAttributes: false,
    attributeNamePrefix:"", //"@_",
    allowBooleanAttributes:true,
    alwaysCreateTextNode: true
    // und viele optionen mehr!!!
  };
 
  function printMESZ() {return (new Date().getTimezoneOffset()===-60?'(MEZ)':'(MESZ)'); }

  const TRANSLATE_UNITS_OBS_1={knots:' kn',degree_celsius:'°C',minutes:' min',mm:' mm'}
  const TRANSLATE_HEADER__OBS_1={dtg:"Zeit "+printMESZ(),name:"Ort",tt:"Temp",ttw:"Temp gefühlt",rrr:"NSL",dd:"Windrichtung",ff:"Windstärke",sun:"Sonnensch.-Dauer",wetter:"Wetter"} // ,symbol:"Symbol",region:"Gebiet",timezone:"Zeitzone"
  const FILES_XML_OBS_1=['obs_ndeu_schleswig-holstein.xml','obs_ndeu_niedersachsen.xml','obs_ndeu_mecklenburg-vorpommern.xml','obs_ndeu_hamburg-umgebung.xml','obs_int_international.xml','obs_eu_europa.xml','obs_deu_deutschland.xml']
  const ROOT_XML_OBS_1="messwerte"
  const HEAD_ZEIT_ATTR_XML_OBS_1=["dtg"] // ,"timezone"
  const HEAD_ORT_ATTR_XML_OBS_1=["name"] // ,"region"
  const HEAD_PARAM_XML_OBS_1=["tt","ttw","rrr","dd","ff","sun","wetter"] // ,"symbol"
  
  const TRANSLATE_UNITS_OBS_2={percent:'%',hPa:' hPa'}
  const TRANSLATE_HEADER__OBS_2={dtg:"Zeit "+printMESZ(),name:"Ort",ppp:"Luftdruck",tendenz:"Tendenz",rh:"Feuchte"} // ,region:"Gebiet",timezone:"Zeitzone"
  const FILES_XML_OBS_2=['obs_ppp_ndeu_nord-deutschland.xml']
  const ROOT_XML_OBS_2="messwerte_ppp"
  const HEAD_ZEIT_ATTR_XML_OBS_2=["dtg"] // ,"timezone"
  const HEAD_ORT_ATTR_XML_OBS_2=["name"] // ,"region"
  const HEAD_PARAM_XML_OBS_2=["ppp","tendenz","rh"]
  
  const TRANSLATE_UNITS_OBS_3={degree_celsius:'°C',mm:' mm'}
  const TRANSLATE_HEADER__OBS_3={dtg:"Zeit "+printMESZ(),name:"Ort",tn:"Tiefstw.",tx:"Höchstw.",rrr24:"NSL (24h)"} // ,id_stat:"id_stat",region:"Gebiet",timezone:"Zeitzone"
  const FILES_XML_OBS_3=['obs_last24h_ndeu.xml']
  const ROOT_XML_OBS_3="rueckblick_24h"
  const HEAD_ZEIT_ATTR_XML_OBS_3=["dtg"] // ,"timezone"
  const HEAD_ORT_ATTR_XML_OBS_3=["name"] // ,"id_stat","region"
  const HEAD_PARAM_XML_OBS_3=["tn","tx","rrr24"]
  
  const TRANSLATE_UNITS_FCST_1={hour:' h',degree_celsius:'°C',percent:'%'}
  const TRANSLATE_HEADER__FCST_1={name:"Ort",tn:"Tiefstw.",tx:"Höchstw.",prrr:"NSL-W.",sun:"Sonnensch.-Dauer",word:"Wetter"} // dtg:"Datum", ,alias:"Tag",id_stat:"id_stat",symbol:"Symbol",region:"Gebiet"
  const FILES_XML_FCST_1=['eu_cities_fcst.xml','int_cities_fcst.xml']
  const ROOT_XML_FCST_1="vorhersage"
  const HEAD_ZEIT_ATTR_XML_FCST_1=[] // "dtg","alias"
  const HEAD_ORT_ATTR_XML_FCST_1=["name"] // ,"region"
  const HEAD_PARAM_XML_FCST_1=["tn","tx","prrr","sun","word"] // ,"symbol"
  
  const TRANSLATE_UNITS_FCST_1_1={hour:' h',degree_celsius:'°C',percent:'%'}
  const TRANSLATE_HEADER__FCST_1_1={name:"Ort",region:"Gebiet",tn:"Tiefstw.",tx:"Höchstw.",prrr:"NSL-W.",sun:"Sonnensch.-Dauer",word:"Wetter"} // dtg:"Datum", ,alias:"Tag",id_stat:"id_stat",symbol:"Symbol"
  const FILES_XML_FCST_1_1=['de_cities_fcst.xml']
  const ROOT_XML_FCST_1_1="vorhersage"
  const HEAD_ZEIT_ATTR_XML_FCST_1_1=[] // "dtg","alias"
  const HEAD_ORT_ATTR_XML_FCST_1_1=["name","region"]
  const HEAD_PARAM_XML_FCST_1_1=["tn","tx","prrr","sun","word"] // ,"symbol"
  
  const TRANSLATE_UNITS_FCST_2={hour:' h',degree_celsius:'°C',percent:'%'}
  const TRANSLATE_HEADER__FCST_2={name:"Ort",dtg:"Datum",tn:"Tiefstw.",tx:"Höchstw.",prrr:"NSL-W.",sun:"Sonnensch.-Dauer",word:"Wetter"} // ,symbol:"Symbol",region:"Gebiet",alias:"Wochentag"
  const FILES_XML_FCST_2=['hh_umg_cities_fcst_3t.xml']
  const ROOT_XML_FCST_2="vorhersage_3t"
  const HEAD_ORT_ATTR_XML_FCST_2=["name"] // ,"region"
  const HEAD_ZEIT_ATTR_XML_FCST_2=["dtg"] // "alias", ACHTUNG: alias mit FALSCHEN WOCHENTAGEN!!!
  const HEAD_PARAM_XML_FCST_2=["tn","tx","prrr","sun","word"] // ,"symbol"
  
  const TRANSLATE_UNITS_FCST_3={knots:' kn',degree_celsius:'°C',percent:'%',windrose:''}
  const TRANSLATE_HEADER__FCST_3={name:"Ort",value:"Stunde",tt:"Temp",prrr:"NSL-W.",dd:"Windrichtung",ff:"Windstärke",word:"Wetter"} // alias:"Tag",dtg:"Datum", ,symbol:"Symbol",region:"Gebiet",timezone:"Zeitzone"
  const FILES_XML_FCST_3=['hh_umg_cities_fcst_6h.xml']
  const ROOT_XML_FCST_3="detail_vorhersage_morgen_hh_umgebung"
  const HEAD_ZEIT_ATTR_XML_FCST_3=[] // "dtg","alias"
  const HEAD_ORT_ATTR_XML_FCST_3=["name"] // ,"region"
  const HEAD_HOUR_ATTR_XML_FCST_3=["value"] // "timezone",
  const HEAD_PARAM_XML_FCST_3=["tt","prrr","dd","ff","word"] // ,"symbol"

  const FILES_STATIC_WIND=['windskala.csv']
  const FILES_STATIC_BEGRIFF=['begriffserklaerung.csv']

  const FILES_PHP_WASSERTEMP=['nord_ostsee.php']
  const filelist=FILES_XML_OBS_1
const NdrHelper={
  
  printMESZ: printMESZ,

  translate(key,units) {
    //console.log("key="+JSON.stringify(key))
    if (key in units) { 
      return units[key] 
    }  
    return key
  },

  Cally (workobject) {
    // const xmlDataStr = `<root a="nice" checked><a>wow</a><a>wow again<c> unlimited </c></a><b>wow phir se</b></root>`;   
    const parser = new XMLParser(OPTIONS,true);
    let json = null;
    //const res=XMLValidator.validate(xmlDataStr,options) 
    try {    
      json = parser.parse(workobject.data);
    if (FILES_XML_OBS_1.includes(workobject.filename)) {
      //console.log(json);//.messwerte.ort[1]["name"])
      const data=this.json2CsvMesswerteZeitOrtArrayXml(json[ROOT_XML_OBS_1],HEAD_ZEIT_ATTR_XML_OBS_1,HEAD_ORT_ATTR_XML_OBS_1,HEAD_PARAM_XML_OBS_1,TRANSLATE_UNITS_OBS_1,workobject.filename)  
      return [[...HEAD_ORT_ATTR_XML_OBS_1,...HEAD_ZEIT_ATTR_XML_OBS_1,...HEAD_PARAM_XML_OBS_1],data,TRANSLATE_HEADER__OBS_1]
    } else if (FILES_XML_OBS_2.includes(workobject.filename)) {
      //console.log(json);//.messwerte.ort[1]["name"])
      const data=this.json2CsvMesswerteZeitOrtArrayXml(json[ROOT_XML_OBS_2],HEAD_ZEIT_ATTR_XML_OBS_2,HEAD_ORT_ATTR_XML_OBS_2,HEAD_PARAM_XML_OBS_2,TRANSLATE_UNITS_OBS_2,workobject.filename)  
      return [[...HEAD_ORT_ATTR_XML_OBS_2,...HEAD_ZEIT_ATTR_XML_OBS_2,...HEAD_PARAM_XML_OBS_2],data,TRANSLATE_HEADER__OBS_2]
    }  if (FILES_XML_OBS_3.includes(workobject.filename)) {
      //console.log(json);//.messwerte.ort[1]["name"])
      const data=this.json2CsvMesswerteZeitOrtArrayXml(json[ROOT_XML_OBS_3],HEAD_ZEIT_ATTR_XML_OBS_3,HEAD_ORT_ATTR_XML_OBS_3,HEAD_PARAM_XML_OBS_3,TRANSLATE_UNITS_OBS_3,workobject.filename)  
      return [[...HEAD_ORT_ATTR_XML_OBS_3,...HEAD_ZEIT_ATTR_XML_OBS_3,...HEAD_PARAM_XML_OBS_3],data,TRANSLATE_HEADER__OBS_3]
    } else if (FILES_XML_FCST_1.includes(workobject.filename)) {
      const data=this.json2CsvVerhersageKeyZeitOrtArrayXml(json[ROOT_XML_FCST_1],HEAD_ZEIT_ATTR_XML_FCST_1,HEAD_ORT_ATTR_XML_FCST_1,HEAD_PARAM_XML_FCST_1,TRANSLATE_UNITS_FCST_1)  
      return [[...HEAD_ORT_ATTR_XML_FCST_1,...HEAD_ZEIT_ATTR_XML_FCST_1,...HEAD_PARAM_XML_FCST_1],data,TRANSLATE_HEADER__FCST_1]
    } else if (FILES_XML_FCST_1_1.includes(workobject.filename)) {
      const data=this.json2CsvVerhersageKeyZeitOrtArrayXml(json[ROOT_XML_FCST_1_1],HEAD_ZEIT_ATTR_XML_FCST_1_1,HEAD_ORT_ATTR_XML_FCST_1_1,HEAD_PARAM_XML_FCST_1_1,TRANSLATE_UNITS_FCST_1_1)  
      return [[...HEAD_ORT_ATTR_XML_FCST_1_1,...HEAD_ZEIT_ATTR_XML_FCST_1_1,...HEAD_PARAM_XML_FCST_1_1],data,TRANSLATE_HEADER__FCST_1_1]
    } else if (FILES_XML_FCST_2.includes(workobject.filename)) {
      const data=this.json2CsvVerhersageOrtArrayZeitArrayArrayXml(json[ROOT_XML_FCST_2],HEAD_ORT_ATTR_XML_FCST_2,HEAD_ZEIT_ATTR_XML_FCST_2,HEAD_PARAM_XML_FCST_2,TRANSLATE_UNITS_FCST_2)  
      return [[...HEAD_ORT_ATTR_XML_FCST_2,...HEAD_ZEIT_ATTR_XML_FCST_2,...HEAD_PARAM_XML_FCST_2],data,TRANSLATE_HEADER__FCST_2]
    } else if (FILES_XML_FCST_3.includes(workobject.filename)) {
      const data=this.json2CsvVerhersageOrtArrayHourArrayArrayXml2(json[ROOT_XML_FCST_3],HEAD_ZEIT_ATTR_XML_FCST_3,HEAD_ORT_ATTR_XML_FCST_3,HEAD_HOUR_ATTR_XML_FCST_3,HEAD_PARAM_XML_FCST_3,TRANSLATE_UNITS_FCST_3)  
      return [[...HEAD_ORT_ATTR_XML_FCST_3,...HEAD_ZEIT_ATTR_XML_FCST_3,...HEAD_HOUR_ATTR_XML_FCST_3,...HEAD_PARAM_XML_FCST_3],data,TRANSLATE_HEADER__FCST_3]
    }
      else if (FILES_STATIC_WIND.includes(workobject.filename)) {
      const data=this.json2CsvStaticwind(workobject)  
      return [[...HEAD_ORT_ATTR_XML_OBS_1,...HEAD_ZEIT_ATTR_XML_OBS_1,...HEAD_PARAM_XML_OBS_1],data,TRANSLATE_HEADER__OBS_1]
    } 
    else if (FILES_STATIC_BEGRIFF.includes(workobject.filename)) {
      const data=this.json2CsvStaticbegriffe(workobject)  
      return [[...HEAD_ORT_ATTR_XML_OBS_1,...HEAD_ZEIT_ATTR_XML_OBS_1,...HEAD_PARAM_XML_OBS_1],data,TRANSLATE_HEADER__OBS_1]
    }
    else if (FILES_PHP_WASSERTEMP.includes(workobject.filename)) {
      const data=this.json2CsvPhpWasserTemperaturen(workobject)  
      return [[...HEAD_ORT_ATTR_XML_OBS_1,...HEAD_ZEIT_ATTR_XML_OBS_1,...HEAD_PARAM_XML_OBS_1],data,TRANSLATE_HEADER__OBS_1]
    }
      else {
      const data=this.json2CsvMesswerteZeitOrtArrayXml(json[ROOT_XML_OBS_1],HEAD_ZEIT_ATTR_XML_OBS_1,HEAD_ORT_ATTR_XML_OBS_1,HEAD_PARAM_XML_OBS_1,TRANSLATE_UNITS_OBS_1,workobject.filename)  
      return [[...HEAD_ORT_ATTR_XML_OBS_1,...HEAD_ZEIT_ATTR_XML_OBS_1,...HEAD_PARAM_XML_OBS_1],data,TRANSLATE_HEADER__OBS_1]
    } 
  } catch(err) {
    console.log("xml parser catchy")
    return null  // TODO alert dialog?
  }
  },
  
  json2CsvMesswerteZeitOrtArrayXml(json,headTime,headAttr,headParam,translate_units_obs,usedfile) {
    //console.log(jsonOrte);
    const jsonOrte=json.ort
    const jsonZeit=json.zeit
    const csvData=[]
    for (let ortIndex=0; ortIndex<jsonOrte.length; ortIndex++) {
      const jsonOrt=jsonOrte[ortIndex]
      const obj={}
      for (let timeIndex=0; timeIndex<headTime.length; timeIndex++) { 
        obj[headTime[timeIndex]]=jsonZeit[headTime[timeIndex]]
      }
      for (let attrIndex=0; attrIndex<headAttr.length; attrIndex++) { 
        obj[headAttr[attrIndex]]=jsonOrt[headAttr[attrIndex]]
      }
      for (let paramIndex=0; paramIndex<headParam.length; paramIndex++) { 
        try {
          obj[headParam[paramIndex]]=
            (headParam[paramIndex] in jsonOrt)?
              jsonOrt[headParam[paramIndex]]["#text"]+
              this.translate(('unit' in jsonOrt[headParam[paramIndex]])?jsonOrt[headParam[paramIndex]]["unit"]:'',translate_units_obs)
            :"---"
        } catch(err) {
          console.log("errryy")  
        }
      }
      csvData.push(obj)
    }
    if (filelist.includes(usedfile)) {
      console.log(usedfile)
      let temperaturen = []
      for (let i=0; i<csvData.length;i++) {
        if (csvData[i].tt !== "---") {
        temperaturen.push(csvData[i].tt.match(/[-]?\d+/)[0])
        }
      }
      let minTemperatur = Math.min.apply(Math, temperaturen).toString()
      let maxTemperatur = Math.max.apply(Math, temperaturen).toString()
      let spcialTemperaturen = ['minmaxTemperaturen',minTemperatur,maxTemperatur]
      csvData.push(spcialTemperaturen)
    }
    return csvData
  }, 
  
  json2CsvVerhersageKeyZeitOrtArrayXml(json,headTime,headAttr,headParam,translate_units_fcst) {
    //console.log(jsonOrte);
    const jsonZeit=json.zeit
    const jsonOrte=json.zeit.ort
    const csvData=[]
    //console.log(json)
    //for (let zeitIndex=0; zeitIndex<1; zeitIndex++) { 
    for (let ortIndex=0; ortIndex<jsonOrte.length; ortIndex++) {
      const jsonOrt=jsonOrte[ortIndex]
      const obj={}
      for (let timeIndex=0; timeIndex<headTime.length; timeIndex++) { 
        obj[headTime[timeIndex]]=jsonZeit[headTime[timeIndex]]
      }  
      for (let attrIndex=0; attrIndex<headAttr.length; attrIndex++) { 
        obj[headAttr[attrIndex]]=jsonOrt[headAttr[attrIndex]]
      }
      for (let paramIndex=0; paramIndex<headParam.length; paramIndex++) { 
        try {
          obj[headParam[paramIndex]]=
            (headParam[paramIndex] in jsonOrt)?
              jsonOrt[headParam[paramIndex]]["#text"]+
              this.translate(('unit' in jsonOrt[headParam[paramIndex]])?jsonOrt[headParam[paramIndex]]["unit"]:'',translate_units_fcst)
            :"---"
        } catch(err) {
          console.log("errryy")  
        }
      }
      csvData.push(obj)
    }
    //}
    //console.log(csvData)
    return csvData
  }, 
  
  json2CsvVerhersageOrtArrayZeitArrayArrayXml(json,headOrt,headAttr,headParam,translate_units_fcst) {
    //console.log(jsonOrte);
    //const jsonZeit=json.ort
    //const jsonZeit=json.zeit
    const jsonOrte=json.ort
    const csvData=[]
    csvData.push("Hamburg drei Tage")
    //console.log(json)
    for (let ortIndex=0; ortIndex<jsonOrte.length; ortIndex++) {
      const jsonOrt=jsonOrte[ortIndex]
      for (let zeitIndex=0; zeitIndex<jsonOrt.zeit.length; zeitIndex++) {
        const jsonZeit=jsonOrt.zeit[zeitIndex] 
        const obj={}
        for (let index=0; index<headOrt.length; index++) { 
          obj[headOrt[index]]=jsonOrt[headOrt[index]]
        }  
        for (let attrIndex=0; attrIndex<headAttr.length; attrIndex++) { 
          obj[headAttr[attrIndex]]=jsonZeit[headAttr[attrIndex]]
        }
        for (let paramIndex=0; paramIndex<headParam.length; paramIndex++) { 
          try {
            obj[headParam[paramIndex]]=
              (headParam[paramIndex] in jsonZeit)?
                jsonZeit[headParam[paramIndex]]["#text"]+
                this.translate(('unit' in jsonZeit[headParam[paramIndex]])?jsonZeit[headParam[paramIndex]]["unit"]:'',translate_units_fcst)
              :"---"
          } catch(err) {
            console.log("errryy")  
        }
      }
      csvData.push(obj)
      }
    }
    const csvData3 = ["Hamburg drei Tage",[],[],[]]
    for (let i =1; i<csvData.length; i= i+3) {
      csvData3[1].push(csvData[i])
      csvData3[2].push(csvData[i+1])
      csvData3[3].push(csvData[i+2])
    }
    
    let tmpZeit = []
    tmpZeit.push(csvData[1].dtg.slice(8,10) + '.' + csvData[1].dtg.slice(5,7) + '.' + csvData[1].dtg.slice(0,4))
    tmpZeit.push(csvData[2].dtg.slice(8,10) + '.' + csvData[2].dtg.slice(5,7) + '.' + csvData[2].dtg.slice(0,4))
    tmpZeit.push(csvData[3].dtg.slice(8,10) + '.' + csvData[3].dtg.slice(5,7) + '.' + csvData[3].dtg.slice(0,4))
    csvData3.push(tmpZeit)
    //console.log(csvData)
    return csvData3
  }, 
  
  json2CsvVerhersageOrtArrayHourArrayArrayXml2(json,headZeit,headOrt,headHour,headParam,translate_units_fcst) {
    //console.log(json.zeit.ort);
    const jsonZeit=json.zeit
    const jsonOrtArray=json.zeit.ort
    //console.log("jsonOrtArray="+JSON.stringify(jsonOrtArray));
    const csvData=[]
    csvData.push("Hamburg morgen")
    for (let ortIndex=0; ortIndex<jsonOrtArray.length; ortIndex++) {
      const jsonOrt=jsonOrtArray[ortIndex]
      //console.log("jsonOrt="+JSON.stringify(jsonOrt));
      for (let hourIndex=0; hourIndex<jsonOrt.hour.length; hourIndex++) {
        const jsonHour=jsonOrt.hour[hourIndex] 
        const obj={}
        for (let zeitAttrIndex=0; zeitAttrIndex<headZeit.length; zeitAttrIndex++) { 
          obj[headZeit[zeitAttrIndex]]=jsonZeit[headZeit[zeitAttrIndex]]
        }
        for (let ortAttrIndex=0; ortAttrIndex<headOrt.length; ortAttrIndex++) { 
          obj[headOrt[ortAttrIndex]]=jsonOrt[headOrt[ortAttrIndex]]
        }  
        for (let hourAttrIndex=0; hourAttrIndex<headHour.length; hourAttrIndex++) { 
          obj[headHour[hourAttrIndex]]=jsonHour[headHour[hourAttrIndex]]
        }
        for (let paramIndex=0; paramIndex<headParam.length; paramIndex++) { 
          try {
            obj[headParam[paramIndex]]=
              (headParam[paramIndex] in jsonHour)?
                jsonHour[headParam[paramIndex]]["#text"]+
                this.translate(('unit' in jsonHour[headParam[paramIndex]])?jsonHour[headParam[paramIndex]]["unit"]:'',translate_units_fcst)
              :"---"
          } catch(err) {
            console.log("errryy")  
        }
      }
      csvData.push(obj)
      }
    }
    const csvData3 = ["Hamburg morgen",[],[],[]]
    for (let i =1; i<csvData.length; i= i+3) {
      csvData3[1].push(csvData[i])
      csvData3[2].push(csvData[i+1])
      csvData3[3].push(csvData[i+2])
    }
    let tmpZeit = jsonZeit.dtg.slice(8,10) + '.' + jsonZeit.dtg.slice(5,7) + '.' + jsonZeit.dtg.slice(0,4)
    csvData3.push(tmpZeit)
    return csvData3
  },
  json2CsvStaticwind(workobject) {
    const csvData=[]
    const tmp = workobject.data
    const rows = tmp.split('\n')
    const column = []

    csvData.push("windskala")
    for (let i=0;i<rows.length;i++) {
      column.push(rows[i].split(';'))
    }
    csvData.push(column)
    //console.log(csvData)
    return csvData
  },
  json2CsvStaticbegriffe(workobject) {
    const csvData=[]
    const tmp = workobject.data
    const rows = tmp.split('\n')
    const column = []
    csvData.push("begriffserklaerung")
    for (let i=0;i<rows.length;i++) {
      column.push(rows[i].split(';'))
    }
    csvData.push(column)
    console.log(csvData)
    return csvData
  },

  json2CsvPhpWasserTemperaturen(workobject) {
    const csvData=[]
    const tmp = workobject.data
    let phptext = tmp.split('\n')
    let regTemperatur = /\d+ Grad/
    let regDate = /\d+\.\d+\.\d+/
    let regFullDate = /\d+\.\d+\.\d+ \d+:\d+ [A-Z][A-Z][A-Z][A-Z]*/
    let regOrtAnfang = /<td>/
    let regOrtEnde = /<\/td>/
    csvData.push("Wassertemperaturen")
    let temperaturen = []
    let orte = []
    let tmptext
    let date = phptext[0].match(regDate)
    let fullDate = phptext[0].match(regFullDate)
    csvData.push(fullDate) 
    tmptext = phptext[0].slice(phptext[0].search("Ostsee: "),phptext[0].length-4)
    csvData.push(tmptext)
    tmptext = phptext[1].slice(phptext[1].search("Nordsee: "),phptext[1].length-4)
    csvData.push(tmptext)
    for (let i=2; i<phptext.length-3;i++) {
      temperaturen.push(phptext[i].match(regTemperatur))
    }
    for (let i=2; i<phptext.length-3;i++) {
      orte.push(phptext[i].slice(phptext[i].search(regOrtAnfang)+4,phptext[i].search(regOrtEnde)))
    }
    orte[0] = "Niedersachsen"
    orte[6] = "Schleswig-Holstein"
    orte[16] = "Mecklenburg/Vorpommern"
    orte.splice(5,1)
    orte.splice(14,1)
    temperaturen.splice(5,1)
    temperaturen.splice(14,1)
    let tableheader = ["Ort","Temperaturen","Datum"]
    csvData.push(tableheader)
    csvData.push(orte)
    csvData.push(temperaturen)
    csvData.push(date)
    return csvData
  } 
  }

export default NdrHelper  
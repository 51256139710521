import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material"
  import { useTranslation } from "react-i18next"
  import {
    getColumnHeader,
    getFormattedValue,
    isWWParam,
  } from "../../modules/layout"
  import { WeatherIconComponent } from "../SpotWeatherModule/WeatherIconComponent"
  import useStyles from "./styles"
  import React from 'react';
  import { useAccessContext } from '../../modules/AccessContext'
  const SpotWeatherDataTableMeteoTeaser = ({
    values = [],
    params = [],
    warnings = [],
    colors = [],
    checked = [],
    onCheckChange = () => {},
  }) => {
    const myStyles = useStyles()
    const { t } = useTranslation()
    const accessContext = useAccessContext()
       // TODO configure parameter
    const paramsFilter = ['W-HSSW', 'FF']
    let laenge = params.length
    /*
 for (let i=laenge-1; i > -1;i--) {
  if (paramsFilter.includes(params[i][0])){
   params[i][3] = i
  } else {
    params.splice(i,1)  
  }  
 }
 
 let zahl = params.length
 if (zahl > 2) {
   params.splice(2)
 
 }
 */
//Beim Aktualisieren wird diese Komponente Zwei Mal gerendert. Dabei sind kurzfristig fehlerhafte
//Werte in der Teaser Tabelle zu sehen. Das wird mit dem Deep Clone von params umgangen.
 let paramsCut = JSON.parse(JSON.stringify(params))
 for (let i=laenge-1; i > -1;i--) {
   if (paramsFilter.includes(params[i][0])){
    paramsCut[i][3] = i
   } else {
     paramsCut.splice(i,1)  
   }  
  }
  
  let zahl = paramsCut.length
  if (zahl > 2) {
    paramsCut.splice(2)
  }
  values.length = 5
  if (accessContext.payload.access.includes("local_time")) {
    for (let i=0;i<values.length;i++) {
      values[i].timeFormatted = values[i].timeAsDate.toLocaleDateString() + ", " + values[i].timeAsDate.toLocaleTimeString().slice(0,5)
    }
    }
    return (
      
      <Table stickyHeader>
        <TableHead>
          <TableRow key={"-1"} className={myStyles.tableRow}>
            <TableCell
              key={"-1"}
              className={myStyles.smallTableHeaderCell}
              align='center'
            >
              <div className={myStyles.headerCellTeaser}>
              {(accessContext.payload.access.includes("local_time")) ?
                  t("table_header_time_lt")
                  :
                  t("table_header_time_utc")}
              </div>
            </TableCell>
            {paramsCut.map((p, index) => (     
              
              <TableCell
                key={paramsCut[index][3]}
                className={myStyles.smallTableHeaderCell}
                align='center'
              >
                <div className={myStyles.headerCellTeaser}>
                  {getColumnHeader(p[0], p[2], t)}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((row, rowIndex) => (            
            <TableRow key={rowIndex} hover className={myStyles.tableRow} >
              <TableCell
                key={"-1"}
                className={myStyles.smallTableCell}
                align='center'
              >
                {row.timeFormatted}
              </TableCell>
              {paramsCut.map((p, pIndex) => (
                
                <TableCell
                  key={paramsCut[pIndex][3]}
                  className={myStyles.tableCellBodyStyled}
                  align='center'
                  innerValue={getFormattedValue(row, p, paramsCut[pIndex][3], t)}
                >
                  {isWWParam(p[0]) === false &&
                    getFormattedValue(row, p, paramsCut[pIndex][3], t)}
                  {isWWParam(p[0]) === true && (
                    <WeatherIconComponent row={row} pIndex={paramsCut[pIndex][3]} />
                  )}
                </TableCell>
              ))}
            </TableRow>
          )
          )}
        </TableBody>
      </Table>
    )

  }

  export { SpotWeatherDataTableMeteoTeaser }
  
import React from 'react';
//import clsx from 'clsx';
import {Grid, TextField, Tabs,Tab,AppBar} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
//import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MaterialSelect from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles'; 
//import Select from 'react-select';
//import { useTheme } from '@mui/material/styles';  //S2
//import { emphasize } from '@mui/material/styles/colorManipulator';
import {Helper} from '../modules/Helper';
import {TabContainer} from '../components/MyTabContainer';
import AgrarTextButtons from './AgrarTextButtons';
//import {components} from '../MySelect';
//import ProtocolTable from '../changelog/ProtocolTable';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
// pick a date util library
//import MomentUtils from '@date-io/moment';
//import DateFnsUtils from '@date-io/date-fns';
//import LuxonUtils from '@date-io/luxon';
//import frLocale from "date-fns/locale/fr";
//import ruLocale from "date-fns/locale/ru";
//import enLocale from "date-fns/locale/en-US";
//import deLocale from "date-fns/locale/de";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// -----------------------------------------------------------------------

const tabDatas=0;
const tabProtocols=1;
//const tabCommentary=1;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
//    height: 250,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
//    width: 200,
  },
}));


export default function AgrarTextForm(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={props.tabvalue} onChange={props.handleTabChange}>
          <Tab label="AgrarText-Daten" />
          {/* <Tab label="ChangeLog" /> */}
        </Tabs>
      </AppBar>
   { (props.tabvalue===tabDatas) ?
      <TabContainer>
      {/*<Typography variant="h6" gutterBottom>
        Object bearbeiten/neu
         </Typography>*/}
      <Grid container spacing={1}>

<Grid item xs={12} md={12}>
    <Accordion>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
      >
        <Typography>Erweitert:</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Grid container spacing={1}>
        
        <Grid item xs={12} md={3}>
          <TextField variant="standard" disabled={true} id="id" label="AgrarText-ID" value={props.workobject.id} fullWidth/>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField variant="standard" disabled={Helper.isEmptyTable(props.workobject.idJahreszeit)} id="idJahreszeit" label="idJahreszeit" value={props.workobject.idJahreszeit} fullWidth placeholder="" helperText="" error={props.workerrors.idJahreszeit} onChange={props.handleTextfieldChange('idJahreszeit')}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField variant="standard" disabled={Helper.isEmptyTable(props.workobject.idGebiet)} id="idGebiet" label="idGebiet" value={props.workobject.idGebiet} fullWidth placeholder="" helperText="" error={props.workerrors.idGebiet} onChange={props.handleTextfieldChange('idGebiet')}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField variant="standard" disabled={Helper.isEmptyTable(props.workobject.idZustand)} id="idZustand" label="idZustand" value={props.workobject.idZustand} fullWidth placeholder="" helperText="" error={props.workerrors.idZustand} onChange={props.handleTextfieldChange('idZustand')}/>
        </Grid>

        <Grid item xs={12} md={4}>
          {/* <TextField id="jahreszeit" label="jahreszeit" value={props.workobject.jahreszeit} fullWidth placeholder="" helperText="" onChange={props.handleTextfieldChange('jahreszeit')}/> */}
          <FormControl variant="standard" disabled={Helper.isEmptyTable(props.workobject.id)} className={classes.formControl} fullWidth>
            <InputLabel id="jahreszeitInputLabel">Jahreszeit</InputLabel>
            <MaterialSelect
              labelid="jahreszeitSelectLabelid"
              id="jahreszeitSelectId"
              value={props.workobject.jahreszeit}
              onChange={props.handleTextfieldChange('jahreszeit')}
            >
            <MenuItem value={'Frühling'}>Frühling</MenuItem>
            <MenuItem value={'Sommer'}>Sommer</MenuItem>
            <MenuItem value={'Herbst'}>Herbst</MenuItem>
            <MenuItem value={'Winter'}>Winter</MenuItem>
            <MenuItem value={'Allgemein'}>Allgemein</MenuItem>
            </MaterialSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          {/* <TextField id="gebiet" label="gebiet" value={props.workobject.gebiet} fullWidth placeholder="" helperText="" onChange={props.handleTextfieldChange('gebiet')}/> */}
          <FormControl variant="standard" disabled={Helper.isEmptyTable(props.workobject.id)} className={classes.formControl} fullWidth>
            <InputLabel id="gebietInputLabel">Gebiet</InputLabel>
            <MaterialSelect
              labelid="gebietSelectLabelid"
              id="gebietSelectId"
              value={props.workobject.gebiet}
              onChange={props.handleTextfieldChange('gebiet')}
            >
            <MenuItem value={'Deutschland'}>Deutschland</MenuItem>
            <MenuItem value={'Regionen'}>Regionen</MenuItem>
            </MaterialSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          {/* <TextField id="zustand" label="zustand" value={props.workobject.zustand} fullWidth placeholder="" helperText="" onChange={props.handleTextfieldChange('zustand')}/> */}
          <FormControl variant="standard" disabled={Helper.isEmptyTable(props.workobject.id)} className={classes.formControl} fullWidth>
            <InputLabel id="zustandInputLabel">Zustand</InputLabel>
            <MaterialSelect
              labelid="zustandSelectLabelid"
              id="zustandSelectId"
              value={props.workobject.zustand}
              onChange={props.handleTextfieldChange('zustand')}
            >
            <MenuItem value={'Schönwetter/angenehm'}>Schönwetter/angenehm</MenuItem>
            <MenuItem value={'extrem mild/sehr heiß'}>extrem mild/sehr heiß</MenuItem>
            <MenuItem value={'Nähe zum Tiefausläufer'}>Nähe zum Tiefausläufer</MenuItem>
            <MenuItem value={'Front- und Tiefkernbereich'}>Front- und Tiefkernbereich</MenuItem>
            <MenuItem value={'Warmsektor'}>Warmsektor</MenuItem>
            <MenuItem value={'Rückseitenwetter'}>Rückseitenwetter</MenuItem>
            <MenuItem value={'Nasskalt/Nebel'}>Nasskalt/Nebel</MenuItem>
            <MenuItem value={'Strenge Winterlage'}>Strenge Winterlage</MenuItem>
            <MenuItem value={''}>Leer</MenuItem>
            </MaterialSelect>
          </FormControl>
        </Grid>

<Grid item xs={12} md={6}>
<LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker disabled={Helper.isEmptyTable(props.workobject.id)}
          id="date"
          label="Datum"
          value={Helper.utc2LocalStr(props.workobject.date)}
          onChange={(newValue)=>{props.handleTextfieldChangeDate('date', newValue)}}
          onError={console.log}
          showToolbar={true}
          renderInput={(params) => <TextField {...params} helperText="UTC Zeit"/>}
          inputFormat={"dd.MM.yyyy HH:mm:ss"}
          mask={"__.__.____ __:__:__"}
          ampm={false}
          maxDate={new Date('3000-01-01')}
          minDate={new Date('1900-01-01')}
          fullWidth
        />
    </LocalizationProvider>      
</Grid>
        <Grid item xs={12} md={6}>
          <TextField variant="standard" disabled={Helper.isEmptyTable(props.workobject.gesperrt)} id="gesperrt" label="gesperrt" value={props.workobject.gesperrt} fullWidth placeholder="" helperText="" error={props.workerrors.gesperrt} onChange={props.handleTextfieldChange('gesperrt')}/>
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            variant="standard" 
            multiline maxRows="12"
            disabled={Helper.isEmptyTable(props.workobject.id)} id="text" label="Text" value={props.workobject.text} 
            fullWidth placeholder='' helperText='Eingabe nur einzeilig!'
            error={props.workerrors.text} onChange={props.handleTextfieldChange('text')}
            InputProps={ { style: {fontSize: 12, fontFamily: "Monospace"} } }
        />
        </Grid>

        {/* <Grid item xs={12} md={12}>
          <TextField variant="standard" disabled={Helper.isEmptyTable(props.workobject.id)}
          id="protocolText"
          label="ChangeLog Kommentar"
          multiline
          fullWidth placeholder='' helperText='Eingabe mehrzeilig möglich'
          maxRows="12"
          value={props.workobject.protocolText}
          onChange={props.handleTextfieldChange('protocolText')}
          className={classes.textField}
          margin="normal"
          />
        </Grid> */}

        <AgrarTextButtons 
        isEmptyTable={Helper.isEmptyTable(props.workobject.id)}
        isNullId={props.workobject.id===0}
        handleOpenSaveDialog={props.handleOpenSaveDialog} 
        handleNew={props.handleNew} 
        handleOpenDeleteDialog={props.handleOpenDeleteDialog}
        handleLoad={props.handleLoad}
        condition_keyvals={props.condition_keyvals}
      />

      </Grid>
      </AccordionDetails>
    </Accordion>

</Grid>
        
      </Grid>
      </TabContainer>
  : (props.tabvalue===tabProtocols) ? 
  <TabContainer>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        {/*props.workobject.id={props.workobject.id}*/}
        {/* <ProtocolTable accessToken={props.accessToken} environment={props.environment} filterGlobal="" tableIdFix={props.workobject.id} tableNameFix={"agrar_text"} loginName={props.loginName}></ProtocolTable> */}
      </Grid>
    </Grid>
  </TabContainer>
:""}

    </div>
   );
  }
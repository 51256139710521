import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';


function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 1 }}>
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };

export {TabContainer}; 

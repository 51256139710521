import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return ( {
        dashboardCardMedia: {
            margin: theme.spacing(theme.mixins.dashboardCardMedia.marginTop, theme.mixins.dashboardCardMedia.marginLeft, theme.mixins.dashboardCardMedia.marginBottom),
            height: theme.mixins.dashboardCardMedia.height,
            width: theme.mixins.dashboardCardMedia.width
        },

        dashboardCardHomeDate: {
            marginTop: "10px"
        },

        dashboardRightColumn: {
            margin: theme.spacing(theme.mixins.dashboardCardMedia.marginTop, theme.mixins.dashboardCardMedia.marginLeft, theme.mixins.dashboardCardMedia.marginBottom),
        },

        dashboardRightColumnSpacer: {
            margin: theme.spacing(theme.mixins.dashboardCardMedia.marginTop, theme.mixins.dashboardCardMedia.marginLeft),
        }
    })
})

export default useStyles
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { scaleUtc } from "d3-scale"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  createContainer,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from "victory"
import {
  defaultColorOfParam,
  defaultDashArrayOfParam,
  defaultInterpolationVictory,
  isLineParam,
  isWWParam,
  lineParamTransformation,
  xAxisTimeFormat,
} from "../../modules/layout"
import { CustomFlyout } from "../SpotWeatherModule/CustomFlyout"
import useStyles from "./styles"
import { WeatherIconComponent } from "../SpotWeatherModule/WeatherIconComponent"
import { useAccessContext } from '../../modules/AccessContext'
import { index } from "d3"
const SpotWeatherDataChartMeteoModularized = ({
  values = [],
  params = [],
  warnings = [],
  colors = [],
  legend,
}) => {
  const myStyles = useStyles()
console.log(params)
  const { t } = useTranslation()
  const accessContext = useAccessContext()

  const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi")

   for (let i = 0; i < params.length; i++) {
   if (params[i].includes("P-WEATHER")) {
    for (let j = 0; j < params.length; j++) {
      if (params[j].includes("WW")) {
        params.splice(j,1)
      }
    }
   } 

   }

     // let cloudsIndex
     if (params[params.length-1][0] === "NCL") {
      // cloudsIndex = params.length
       params.pop()
     }
  let lineValue = (d, pIndex, pShort) => {
    let rawValue = d["param" + (pIndex + 1)]
    let parsed = parseFloat(rawValue)

    if (isNaN(parsed)) {
      return null
    } else {
      return lineParamTransformation(pShort, parsed)  
  }
  }
  // function handleChange(event) {
  //   const { name, value } = event.target
  //   setLimits({ ...limits, [name]: { ...limits[name], limit: value } })
  //   console.log(limits)
  // }
  let lineValueTest = (d, pIndex, pShort) => {
    //console.log(d)
    let rawValue = d["param" + (pIndex + 1)]
    let parsed = parseFloat(rawValue)

    if (isNaN(parsed)) {
      return null
    } else {
      return lineParamTransformation(pShort, parsed)  
  }
  }

  let xAxisFormat = (timeValue) => xAxisTimeFormat(timeValue, t)

  const chartWidth = 500
  const chartHeight = 250
  const axis0Left = 50
  const relativeIconHeight = 0.98
  const domainHeightFactor = 1.25

  const layoutChart2 = { top: 0, bottom: 40, height: 20, legendOffset: 90 }

  const [wwParamIndex, setWWParamIndex] = useState(undefined)
  const [axis, setAxis] = useState([])
  const [waveAxis, setWaveAxis] = useState([])
  const [windAxis, setWindAxis] = useState([])
  const [waveParams, setWaveParams] = useState([])
  const [waveValues, setWaveValues] = useState([])
  const [windParams, setWindParams] = useState([])
  const [windValues, setWindValues] = useState([])
  // const [legend, setLegend] = useState([])

  const [zoom, setZoom] = useState({})
  let handleZoomVoronoi = function (domain) {
    setZoom({ zoomDomain: domain })
  }
  let handleZoomBrush = function (domain) {
    setZoom({
      zoomDomain: {
        x: domain.x,
        y: [domain.y[0], domain.y[1] * domainHeightFactor],
      },
    })
  }
  let initialChecked = {}
  params.forEach((p) => {
    initialChecked = { ...initialChecked, [p[0]]: true }
  })
  const [checked, setChecked] = useState(initialChecked)

  const handleCheckChange = (name) => {
    setChecked({ ...checked, [name]: !checked[name] })
  }

  const [firstCheckedLineParam, setFirstCheckedLineParam] = useState(undefined)

if (accessContext.payload.access.includes("local_time")) {
  for (let i=0;i<values.length;i++) {
    values[i].timeFormatted = values[i].timeAsDate.toLocaleDateString() + ", " + values[i].timeAsDate.toLocaleTimeString().slice(0,5)
  }
  }
  let testwarnings = true

  useEffect(() => {
    // set dependant axis, wwParam
    let axisData = []
    let axisDataWave = []
    let axisDataWind = []
    let firstCheckedLineParam = undefined
    let wwParamIndex = undefined
    let tmpWaveParams = []
    let tmpWindParams = []
    params.forEach((p, pIndex) => {
      if (isLineParam(p[0]) && checked[p[0]]) {
        let unit = p[0] === "VV" && p[2] === "m" ? "km" : p[2]
        let transformation = p.transformation
        
        //console.log(params)
        //console.log(values)
        //          let existingAxis = axisData.filter(ax => ax.label === unit && ax.transformation === transformation)
        let existingAxis = axisData.filter(
          (ax) => ax.transformation === transformation
        )
        if (existingAxis.length === 0) {
          axisData.push({
            label: unit,
            transformation: transformation,
            orientation: axisData.length === 0 ? "left" : "right",
            offsetX:
              axisData.length === 0 ? axis0Left : 10 + axisData.length * 40,
          })
        }
        let existingAxisWave = axisDataWave.filter(
            (ax) => ax.transformation === transformation
          )
          if (existingAxisWave.length === 0) {
            axisDataWave.push({
              label: unit,
              transformation: transformation,
              orientation: axisDataWave.length === 0 ? "left" : "right",
              offsetX:
                axisDataWave.length === 0 ? axis0Left : 10 + axisDataWave.length * 40,
            })
          }
          let existingAxisWind = axisDataWind.filter(
            (ax) => ax.transformation === transformation
          )
          if (existingAxisWind.length === 0) {
            axisDataWind.push({
              label: unit,
              transformation: transformation,
              orientation: axisDataWind.length === 0 ? "left" : "right",
              offsetX:
                axisDataWind.length === 0 ? axis0Left : 10 + axisDataWind.length * 40,
            })
          }
        firstCheckedLineParam = firstCheckedLineParam
          ? firstCheckedLineParam
          : p[0]
      }
      if (isWWParam(p[0])) {
        wwParamIndex = pIndex
      }

      if (p[0] === "W-HSSW" || p[0] === "W-WSHMAX") {
        let tmpWaveParam
        // console.log(pIndex)
        // console.log(p)

        tmpWaveParam = p
        tmpWaveParam[3] = pIndex +1
        tmpWaveParams.push(tmpWaveParam)
        console.log(tmpWaveParams)
        
        console.log(waveParams)

        

      }

      if (p[0] === "FF" || p[0] === "FFG") {
        let tmpWindParam
        // console.log(pIndex)
        // console.log(p)

        tmpWindParam = p
        tmpWindParam[3] = pIndex +1
        tmpWindParams.push(tmpWindParam)
        console.log(tmpWindParams) 

      }
      

    })
    setWaveParams(tmpWaveParams)
    setWindParams(tmpWindParams)
    console.log(tmpWaveParams)
    console.log(params)

    console.log(waveParams)
    let tmpWaveValues = []
    let tmpWindValues = []
    let tmptext = "param" + tmpWaveParams[0][3]
    if (testwarnings) {
      let wavewarning = [ "Warning", "Warnstufe Wellenhöhe", "m", "3"]
      let windwarning = [ "WarningWind", "Warnstufe Windg.", "kn", "3"]
      tmpWaveParams.push(wavewarning)
      tmpWindParams.push(windwarning)
      testwarnings = false
    }
    values.forEach((v, vIndex) => {
        //values ist object mit param1 : [wert],.., timaasdate: Date
        let tmpobjc = {}
        let tmpWindobjc = {}

        tmpWindobjc.param1 = v.param1
        tmpWindobjc.param2 = v.param2
        tmpWindobjc.param3 = ["40"]
        tmpWindobjc.timeAsDate = v.timeAsDate
        tmpWindobjc.timeFormatted = v.timeFormatted
        tmpobjc.param1 = v[tmptext]
        tmpobjc.param2 = v.param6
        tmpobjc.param3 = ["2"]
        tmpobjc.timeAsDate = v.timeAsDate
        tmpobjc.timeFormatted = v.timeFormatted
        tmpWaveValues.push(tmpobjc)
        tmpWindValues.push(tmpWindobjc)


        //waveValues[vIndex].push(v.param4)
    })
    setWaveValues(tmpWaveValues)
    setWindValues(tmpWindValues)


    // console.log(waveValues)
    // console.log(values)
    //console.log(axisData)
    setAxis(axisData)
    setWaveAxis(axisDataWave)
    setWindAxis(axisDataWind)
    setFirstCheckedLineParam(firstCheckedLineParam)
    setWWParamIndex(wwParamIndex)
  }, [params, checked])
console.log(windParams)
console.log(windValues)
console.log(values)
console.log(params)
  return (
    <div>
    <div className={myStyles.chartAndLegend}>
    <div className={myStyles.chartContainer}>
      <VictoryChart
        className={myStyles.charts}
        width={chartWidth}
        height={chartHeight}
        scale={{ x: scaleUtc() }}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryZoomVoronoiContainer
            voronoiDimension='x'
            zoomDimension='x'
            zoomDomain={zoom.zoomDomain}
            onZoomDomainChange={handleZoomVoronoi}
            className={myStyles.charts}
          />
        }
      >
        {waveParams.map(
          (p, pIndex) =>
            checked[p[0]] &&
            isLineParam(p[0]) && (
              <VictoryLine
                key={p[0]}
                interpolation={defaultInterpolationVictory}
                style={{
                  data: {
                    stroke: defaultColorOfParam(p[0]),
                    strokeWidth: 1,
                    strokeDasharray: defaultDashArrayOfParam(p[0]),
                  },
                }}
                data={waveValues}
                name={p[0]}
                x='timeAsDate'
                y={(d) => lineValueTest(d, pIndex, p[0])}
                labels={() => " "}
                labelComponent={
                  <VictoryTooltip
                    flyoutComponent={
                      <CustomFlyout
                        params={waveParams}
                        show={firstCheckedLineParam === p[0]}
                        chartWidth={chartWidth}
                        chartHeight={chartHeight}
                        xAxisOffset={axis0Left}
                      />
                    }
                  />
                }
              />
            )
        )}
        {waveAxis.map((axis, axisIndex) => (
          <VictoryAxis
            key={axisIndex}
            dependentAxis
            noLabel={axis.label}
            orientation={axis.orientation}
            offsetX={axis.offsetX}
            tickFormat={(t) => t}
            style={{
              axisLabel: { fontSize: 9, padding: 20 },
              tickLabels: { fontSize: 8, padding: 5 },
            }}
          />
        ))}

        <VictoryAxis
          tickFormat={xAxisFormat}
          style={{ tickLabels: { fontSize: 10 } }}
        />
      </VictoryChart>

      <VictoryChart
        className={myStyles.charts}
        padding={{
          top: layoutChart2.top,
          left: 50,
          right: 50,
          bottom: layoutChart2.bottom,
        }}
        width={600}
        height={layoutChart2.bottom + layoutChart2.height + layoutChart2.top}
        scale={{ x: scaleUtc() }}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryBrushContainer
            brushDimension='x'
            brushDomain={zoom.zoomDomain}
            onBrushDomainChange={handleZoomBrush}
            className={myStyles.charts}
          />
        }
      >
        <VictoryAxis
          offsetY={layoutChart2.bottom}
          tickFormat={xAxisFormat}
          style={{ tickLabels: { fontSize: 10 } }}
        />
        {waveParams.map(
          (p, pIndex) =>
            checked[p[0]] &&
            isLineParam(p[0]) && (
              <VictoryLine
                key={p[0]}
                interpolation={defaultInterpolationVictory}
                style={{
                  data: {
                    stroke: defaultColorOfParam(p[0]),
                    strokeWidth: 1,
                  },
                }}
                data={waveValues}
                name={p[0]}
                x='timeAsDate'
                y={(d) => lineValueTest(d, pIndex, p[0])}
              />
            )
        )}
      </VictoryChart>
    </div>
    <FormGroup>
      {waveParams.map(
        (p, pIndex) =>
          isLineParam(p[0]) && (
            <>
              <FormControlLabel
                key={p[0]}
                label={p[1]}
                control={
                  <Checkbox
                    checked={checked[p[0]]}
                    onChange={() => handleCheckChange(p[0])}
                  />
                }   
              
              />
              {/* <input
                        className={myStyles.limitInput}
                        type='number'
                        step={1}
                        name={p[0]}
                        // onChange={handleChange}
                        value={1}
                      /> */}
                <svg key={pIndex} viewBox='0 0 400 20' width='400px' height='20px'>
                  <path
                    d='M30,1 L400,1'
                    stroke={defaultColorOfParam(p[0])}
                    strokeWidth='4'
                    strokeDasharray={defaultDashArrayOfParam(p[0])}
                  />
                </svg>
            </>
          )
      )}
      {/* <p className={myStyles.legendHint}>{t("legend_symbol_hint")}</p> */}
    </FormGroup>
  </div>




  <div className={myStyles.chartAndLegend}>
    <div className={myStyles.chartContainer}>
      <VictoryChart
        className={myStyles.charts}
        width={chartWidth}
        height={chartHeight}
        scale={{ x: scaleUtc() }}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryZoomVoronoiContainer
            voronoiDimension='x'
            zoomDimension='x'
            zoomDomain={zoom.zoomDomain}
            onZoomDomainChange={handleZoomVoronoi}
            className={myStyles.charts}
          />
        }
      >
        {windParams.map(
          (p, pIndex) =>
            checked[p[0]] &&
            isLineParam(p[0]) && (
              <VictoryLine
                key={p[0]}
                interpolation={defaultInterpolationVictory}
                style={{
                  data: {
                    stroke: defaultColorOfParam(p[0]),
                    strokeWidth: 1,
                    strokeDasharray: defaultDashArrayOfParam(p[0]),
                  },
                }}
                data={windValues}
                name={p[0]}
                x='timeAsDate'
                y={(d) => lineValueTest(d, pIndex, p[0])}
                labels={() => " "}
                labelComponent={
                  <VictoryTooltip
                    flyoutComponent={
                      <CustomFlyout
                        params={windParams}
                        show={firstCheckedLineParam === p[0]}
                        chartWidth={chartWidth}
                        chartHeight={chartHeight}
                        xAxisOffset={axis0Left}
                      />
                    }
                  />
                }
              />
            )
        )}
        {windAxis.map((axis, axisIndex) => (
          <VictoryAxis
            key={axisIndex}
            dependentAxis
            noLabel={axis.label}
            orientation={axis.orientation}
            offsetX={axis.offsetX}
            tickFormat={(t) => t}
            style={{
              axisLabel: { fontSize: 9, padding: 20 },
              tickLabels: { fontSize: 8, padding: 5 },
            }}
          />
        ))}

        <VictoryAxis
          tickFormat={xAxisFormat}
          style={{ tickLabels: { fontSize: 10 } }}
        />
      </VictoryChart>

      <VictoryChart
        className={myStyles.charts}
        padding={{
          top: layoutChart2.top,
          left: 50,
          right: 50,
          bottom: layoutChart2.bottom,
        }}
        width={600}
        height={layoutChart2.bottom + layoutChart2.height + layoutChart2.top}
        scale={{ x: scaleUtc() }}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryBrushContainer
            brushDimension='x'
            brushDomain={zoom.zoomDomain}
            onBrushDomainChange={handleZoomBrush}
            className={myStyles.charts}
          />
        }
      >
        <VictoryAxis
          offsetY={layoutChart2.bottom}
          tickFormat={xAxisFormat}
          style={{ tickLabels: { fontSize: 10 } }}
        />
        {windParams.map(
          (p, pIndex) =>
            checked[p[0]] &&
            isLineParam(p[0]) && (
              <VictoryLine
                key={p[0]}
                interpolation={defaultInterpolationVictory}
                style={{
                  data: {
                    stroke: defaultColorOfParam(p[0]),
                    strokeWidth: 1,
                  },
                }}
                data={windValues}
                name={p[0]}
                x='timeAsDate'
                y={(d) => lineValueTest(d, pIndex, p[0])}
              />
            )
        )}
      </VictoryChart>
    </div>
    <FormGroup>
      {windParams.map(
        (p, pIndex) =>
          isLineParam(p[0]) && (
            <>
              <FormControlLabel
                key={p[0]}
                label={p[1]}
                control={
                  <Checkbox
                    checked={checked[p[0]]}
                    onChange={() => handleCheckChange(p[0])}
                  />
                }
              />
                <svg key={pIndex} viewBox='0 0 400 20' width='400px' height='20px'>
                  <path
                    d='M30,1 L400,1'
                    stroke={defaultColorOfParam(p[0])}
                    strokeWidth='4'
                    strokeDasharray={defaultDashArrayOfParam(p[0])}
                  />
                </svg>
            </>
          )
      )}
      {/* <p className={myStyles.legendHint}>{t("legend_symbol_hint")}</p> */}
    </FormGroup>
  </div>






    <div className={myStyles.chartAndLegend}>
      <div className={myStyles.chartContainer}>
        <VictoryChart
          className={myStyles.charts}
          width={chartWidth}
          height={chartHeight}
          scale={{ x: scaleUtc() }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryZoomVoronoiContainer
              voronoiDimension='x'
              zoomDimension='x'
              zoomDomain={zoom.zoomDomain}
              onZoomDomainChange={handleZoomVoronoi}
              className={myStyles.charts}
            />
          }
        >
          {params.map(
            (p, pIndex) =>
              checked[p[0]] &&
              isLineParam(p[0]) && (
                <VictoryLine
                  key={p[0]}
                  interpolation={defaultInterpolationVictory}
                  style={{
                    data: {
                      stroke: defaultColorOfParam(p[0]),
                      strokeWidth: 1,
                      strokeDasharray: defaultDashArrayOfParam(p[0]),
                    },
                  }}
                  data={values}
                  name={p[0]}
                  x='timeAsDate'
                  y={(d) => lineValue(d, pIndex, p[0])}
                  labels={() => " "}
                  labelComponent={
                    <VictoryTooltip
                      flyoutComponent={
                        <CustomFlyout
                          params={params}
                          show={firstCheckedLineParam === p[0]}
                          chartWidth={chartWidth}
                          chartHeight={chartHeight}
                          xAxisOffset={axis0Left}
                        />
                      }
                    />
                  }
                />
              )
          )}
          {axis.map((axis, axisIndex) => (
            <VictoryAxis
              key={axisIndex}
              dependentAxis
              noLabel={axis.label}
              orientation={axis.orientation}
              offsetX={axis.offsetX}
              tickFormat={(t) => t}
              style={{
                axisLabel: { fontSize: 9, padding: 20 },
                tickLabels: { fontSize: 8, padding: 5 },
              }}
            />
          ))}

          <VictoryAxis
            tickFormat={xAxisFormat}
            style={{ tickLabels: { fontSize: 10 } }}
          />
        </VictoryChart>

        <VictoryChart
          className={myStyles.charts}
          padding={{
            top: layoutChart2.top,
            left: 50,
            right: 50,
            bottom: layoutChart2.bottom,
          }}
          width={600}
          height={layoutChart2.bottom + layoutChart2.height + layoutChart2.top}
          scale={{ x: scaleUtc() }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryBrushContainer
              brushDimension='x'
              brushDomain={zoom.zoomDomain}
              onBrushDomainChange={handleZoomBrush}
              className={myStyles.charts}
            />
          }
        >
          <VictoryAxis
            offsetY={layoutChart2.bottom}
            tickFormat={xAxisFormat}
            style={{ tickLabels: { fontSize: 10 } }}
          />
          {params.map(
            (p, pIndex) =>
              checked[p[0]] &&
              isLineParam(p[0]) && (
                <VictoryLine
                  key={p[0]}
                  interpolation={defaultInterpolationVictory}
                  style={{
                    data: {
                      stroke: defaultColorOfParam(p[0]),
                      strokeWidth: 1,
                    },
                  }}
                  data={values}
                  name={p[0]}
                  x='timeAsDate'
                  y={(d) => lineValue(d, pIndex, p[0])}
                />
              )
          )}
        </VictoryChart>
      </div>
      <FormGroup>
        {params.map(
          (p, pIndex) =>
            isLineParam(p[0]) && (
              <>
                <FormControlLabel
                  key={p[0]}
                  label={p[1]}
                  control={
                    <Checkbox
                      checked={checked[p[0]]}
                      onChange={() => handleCheckChange(p[0])}
                    />
                  }
                />
                  <svg key={pIndex} viewBox='0 0 400 20' width='400px' height='20px'>
                    <path
                      d='M30,1 L400,1'
                      stroke={defaultColorOfParam(p[0])}
                      strokeWidth='4'
                      strokeDasharray={defaultDashArrayOfParam(p[0])}
                    />
                  </svg>
              </>
            )
        )}
        {/* <p className={myStyles.legendHint}>{t("legend_symbol_hint")}</p> */}
      </FormGroup>
    </div>
    </div>
  )
}

export default SpotWeatherDataChartMeteoModularized
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return ( {
        dashboardCardMedia: {
            margin: theme.spacing(theme.mixins.dashboardCardMedia.marginTop, theme.mixins.dashboardCardMedia.marginLeft, theme.mixins.dashboardCardMedia.marginBottom),
            height: theme.mixins.dashboardCardMedia.height,
            width: theme.mixins.dashboardCardMedia.width
        }
    })
})

export default useStyles
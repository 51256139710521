import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return ( {
        horizontalButton: {
            padding: theme.spacing(0, 2),
            float: "left",
            color: "inherit"
        },
        horizontalButtonRight: {
            padding: theme.spacing(0, 2),
            float: "right",
            color: "inherit"
        },
        horizontalButtonLeft: {
            padding: theme.spacing(0, 2),
            float: "left",
            color: "inherit"
        },
        expandButtonIcon: {
            color: "inherit",
            minWidth: 24
        },
        navigation: {
            padding: theme.spacing(2, 2, 1),
            zIndex: 999
        },
        menuBar1: {
            fontSize: 14,
            margin: theme.spacing(0, 0, 0, 0)
        },
        menuBar2: {
            fontSize: 14,
            margin: theme.spacing(0, 0, 0, 4)
        },
        menuBar3: {
            fontSize: 14,
            margin: theme.spacing(0, 0, 0, 8)
        },
        menuBarExpand: {
            color: theme.palette.primary.light
        },
    })
})

export default useStyles
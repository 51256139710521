import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles'; 
import { teletextBilder, teletextText, TeletextButton } from './NdrTeletext';
import { DialogContent } from '@mui/material';
const wetterlageBase64 = "data:image/png;base64," + teletextBilder.wetterlage;
const kurzfristTexteBase64 = "data:image/png;base64," + teletextBilder.kurzfristTexte;
const fuenfTageAussichtenBase64 = "data:image/png;base64," + teletextBilder.fuenfTageAussichten;
const kuestenwetterBase64 = "data:image/png;base64," + teletextBilder.kuestenwetter;
const biowetterUndPollenflugBase64 = "data:image/png;base64," + teletextBilder.biowetterUndPollenflug;
const agrarUndGartenwetterBase64 = "data:image/png;base64," + teletextBilder.agrarUndGartenwetter;
const aktuelleMesswerteBase64 = "data:image/png;base64," + teletextBilder.aktuelleMesswerte;
const aktuelleWerte_einsBase64 = "data:image/png;base64," + teletextBilder.aktuelleWerte_eins;
const aktuelleWerte_zweiBase64 = "data:image/png;base64," + teletextBilder.aktuelleWerte_zwei;
const hitlistenBase64 = "data:image/png;base64," + teletextBilder.hitlisten;
const temperaturkartenBase64 = "data:image/png;base64," + teletextBilder.temperaturkarten;
const radarbilderBase64 = "data:image/png;base64," + teletextBilder.radarbilder;
const wasserstandsvorhersageDesBSHBase64 = "data:image/png;base64," + teletextBilder.wasserstandsvorhersageDesBSH;
const seewetterBase64 = "data:image/png;base64," + teletextBilder.seewetter;
const pegelstaendeBase64 = "data:image/png;base64," + teletextBilder.pegelstaende;
const badeseenImSommerhalbjahrBase64 = "data:image/png;base64," + teletextBilder.badeseenImSommerhalbjahr;
const wintersportHarzImWinterhalbjahrBase64 = "data:image/png;base64," + teletextBilder.wintersportHarzImWinterhalbjahr;

const useStyles = makeStyles(theme => ({
    teletextParagraph: {
        width: '1000px'
      },
      dialogbutton: {
        paddingLeft: '10px',
        paddingBottom: '15px'
      }
    }));

function SimpleDialog(props) {
    const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  
  const handleClose = () => {
    onClose(selectedValue);
  };
  return (
    <Dialog fullWidth={true} maxWidth={'lg'} onClose={handleClose} open={open}>
      <DialogTitle>Beispiel Teletext</DialogTitle>
      <DialogContent>
      <h3>100: Wetterlage/Anreißer</h3>
        <p className={classes.teletextParagraph} >{teletextText.wetterlage}</p>
        <img src={wetterlageBase64} alt="Teletext Beispielbild" />
        <h3>651 - 655: Kurzfrist-Texte</h3>
        <p className={classes.teletextParagraph} >{teletextText.kurzfristTexte}</p>
        <img src={kurzfristTexteBase64} alt="Teletext Beispielbild" />
        <h3>656 - 657: 5-Tage-Aussichten</h3>
        <p className={classes.teletextParagraph} >{teletextText.fuenfTageAussichten}</p>
        <img src={fuenfTageAussichtenBase64} alt="Teletext Beispielbild" />
        <h3>658: Küstenwetter</h3>
        <p className={classes.teletextParagraph} >{teletextText.kuestenwetter}</p>
        <img src={kuestenwetterBase64} alt="Teletext Beispielbild" />
        <h3>659: Biowetter und Pollenflug</h3>
        <p className={classes.teletextParagraph} >{teletextText.biowetterUndPollenflug}</p>
        <img src={biowetterUndPollenflugBase64} alt="Teletext Beispielbild" />
        <h3>660: Agrar- und Gartenwetter</h3>
        <p className={classes.teletextParagraph} >{teletextText.agrarUndGartenwetter}</p>
        <img src={agrarUndGartenwetterBase64} alt="Teletext Beispielbild" />
        <h3>661: Aktuelle Messwerte</h3>
        <p className={classes.teletextParagraph} >{teletextText.aktuelleMesswerte}</p>
        <img src={aktuelleMesswerteBase64} alt="Teletext Beispielbild" />
        <h3>662, 663: Aktuelle Wetterwerte</h3>
        <p className={classes.teletextParagraph} >{teletextText.aktuelleWerte}</p>
        <img src={aktuelleWerte_einsBase64} alt="Teletext Beispielbild" />
        <img src={aktuelleWerte_zweiBase64} alt="Teletext Beispielbild" />
        <h3>664: Hitlisten</h3>
        <p className={classes.teletextParagraph} >{teletextText.hitlisten}</p>
        <img src={hitlistenBase64} alt="Teletext Beispielbild" />
        <h3>665: Temperaturkarten</h3>
        <p className={classes.teletextParagraph} >{teletextText.temperaturkarten}</p>
        <img src={temperaturkartenBase64} alt="Teletext Beispielbild" />
        <h3>666: Radarbilder</h3>
        <p className={classes.teletextParagraph} >{teletextText.radarbilder}</p>
        <img src={radarbilderBase64} alt="Teletext Beispielbild" />
        <h3>669: Wasserstandsvorhersage des BSH</h3>
        <p className={classes.teletextParagraph} >{teletextText.wasserstandsvorhersageDesBSH}</p>
        <img src={wasserstandsvorhersageDesBSHBase64} alt="Teletext Beispielbild" />
        <h3>670: Seewetter</h3>
        <p className={classes.teletextParagraph} >{teletextText.seewetter}</p>
        <img src={seewetterBase64} alt="Teletext Beispielbild" />
        <h3>671: Pegelstände</h3>
        <p className={classes.teletextParagraph} >{teletextText.pegelstaende}</p>
        <img src={pegelstaendeBase64} alt="Teletext Beispielbild" />
        <h3>672: Badeseen (im Sommerhalbjahr)</h3>
        <p className={classes.teletextParagraph} >{teletextText.badeseenImSommerhalbjahr}</p>
        <img src={badeseenImSommerhalbjahrBase64} alt="Teletext Beispielbild" />
        <h3>672: Wintersport Harz (im Winterhalbjahr)</h3>
        <p className={classes.teletextParagraph} >{teletextText.wintersportHarzImWinterhalbjahr}</p>
        <img src={wintersportHarzImWinterhalbjahrBase64} alt="Teletext Beispielbild" />
        </DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function TeletextDialog() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.dialogbutton}>
      <Button variant="contained" onClick={handleClickOpen}>
        Beispiel Teletext Vorschau
      </Button>
      <SimpleDialog
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}


import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  return {
    chartContainer: {
      maxWidth: 800,
    },
    chartAndLegend: {
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
    },
    charts: {
        height: "auto !important",
    },
    legendHint: {
        fontFamily: "inherit"
    }
  }
})

export default useStyles

import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next"
import { Typography, Card, CardContent, CardMedia, CardActions, Grid, IconButton  } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos, PlayArrow, Stop } from '@mui/icons-material'

const HorizontalImageBar = ( { images = [], cardStyles, playerInterval = 3000 } ) => {
    const { t } = useTranslation()
    const [play, setPlay] = useState(false);                // player status (on / off)
    const [activeIndex, setActiveIndex] = useState(0);      // activeIndex image index
    const playIntervalId = useRef(undefined);               // ref wegen einfacher wiederverwendung

    function nextImage(forward) {
        setActiveIndex(currentValue => { 
            return images.length === 0 ? 0 : (forward ? (currentValue + 1) % images.length : (currentValue - 1 + images.length) % images.length)
        })
    }
    function playerOnOff() {
        setPlay(currentValue => !currentValue )
    }

    useEffect(() => {       // start / stop player
        function nextIndex(idx) {
            return images.length === 0 ? 0 : (idx + 1) % images.length
        }

        if (play) {
            setActiveIndex(currentValue => nextIndex(currentValue))
            playIntervalId.current = setInterval(() => {
                setActiveIndex(currentValue => nextIndex(currentValue))
            }, playerInterval)
        }

        return () => {
            clearInterval(playIntervalId.current)
        }
    }, [play, playerInterval, images]);  

    return (
        <Grid container justifyContent="center">
            <Grid item>
                { images.length > 0 && (
                <Card className={cardStyles}>
                    <CardMedia
                        component="img"
                        image={images[activeIndex].src}
                    />
                    <CardContent>
                        <Typography variant="subtitle1" align="center">
                            {t(images[activeIndex].title)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <IconButton disabled={play} onClick={() => nextImage(false)} size="large">
                                    <ArrowBackIos />
                                </IconButton>
                                <IconButton onClick={playerOnOff} size="large">
                                    { !play && (
                                        <PlayArrow />
                                    )}
                                    { play && (
                                        <Stop />
                                    )}
                                </IconButton>
                                <IconButton disabled={play} onClick={() => nextImage(true)} size="large">
                                    <ArrowForwardIos />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            )}
            </Grid>
        </Grid>
    );
}

export default HorizontalImageBar
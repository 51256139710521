const DEFAULT_COLOR_FF = "#57b757"
const DEFAULT_COLOR_FFG = "#f3622d"
const DEFAULT_COLOR_TT = "#41a9c9"
/*
    // chart colours mp
    CHART_COLOR_1: #f3622d; // rot
    CHART_COLOR_2: #fba71b; // orange gelb
    CHART_COLOR_3: #57b757; // grün
    CHART_COLOR_4: #41a9c9; // hellblau
    CHART_COLOR_5: #4258c9; // mittl. blau
    CHART_COLOR_6: #9a42c8; // lila
    CHART_COLOR_7: #c84164; // dkl. rot
    CHART_COLOR_8: #888888; // grau

    // mp 2
    0000ff // blau
    786496 // blau lila
    55B9B9 // hellbalu / türkis
    // mp 1
    467346 // grau grün(?)
    236946 // dkl. grün(?)
    C8C8C8 // hell grau
*/
const DEFAULT_COLOR_PPPP = "black"
const DEFAULT_COLOR_WSSH = "#4258c9"
const DEFAULT_COLOR_WHEIGHTMAX = "magenta"
const DEFAULT_COLOR_WPERIOD = "#4258c9"
const DEFAULT_COLOR_CUR = "#888888"
const DEFAULT_COLOR_VV = "#fba71b"
const DEFAULT_COLOR_UNKNOWN = "grey"
const DEFAULT_COLOR_RRR1 = "#0000CD"
const LAYOUT_TYPE_LINE = 'line'
const LAYOUT_TYPE_UNKNOWN = 'unknown'

const DATA_TYPE_TEXT = 'text'
const UNIT_TYPE_NONE = '-'

const getLinearShift = (shift) => ( value => value + shift)
const getScale = (factor) => ( value => value * factor)

const ALL_KNOWN_METEO_PARAMS = [ 
  { name: "T",              color: DEFAULT_COLOR_TT,      dashArray: undefined, show: false, layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "T0",             color: DEFAULT_COLOR_TT,      dashArray: undefined, show: false, layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "WW",             color: DEFAULT_COLOR_UNKNOWN, dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_UNKNOWN, valueTransformation: undefined }, 
  { name: "PPPP",           color: DEFAULT_COLOR_PPPP,    dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: getLinearShift(-1000) },   // TODO unit berücksichtigen

  { name: "FF",             color: DEFAULT_COLOR_FF,      dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "FFG",            color: DEFAULT_COLOR_FFG,     dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "FFDIR",          color: DEFAULT_COLOR_UNKNOWN, dashArray: undefined, show: false, layoutType: LAYOUT_TYPE_UNKNOWN, valueTransformation: undefined }, 

  { name: "FF_LOG_50_M",    color: DEFAULT_COLOR_FF,      dashArray: "5,5",     show: false, layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "FFG_LOG_50_M",   color: DEFAULT_COLOR_FFG,     dashArray: "5,5",     show: false, layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "FFDIR_LOG_50_M", color: DEFAULT_COLOR_FF,      dashArray: undefined, show: false, layoutType: LAYOUT_TYPE_UNKNOWN, valueTransformation: undefined }, 

  { name: "CUR",            color: DEFAULT_COLOR_CUR,     dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, // getScale(5) }, // TODO unit berücksichtigen
  { name: "CURDIR",         color: DEFAULT_COLOR_UNKNOWN, dashArray: undefined, show: false, layoutType: LAYOUT_TYPE_UNKNOWN, valueTransformation: undefined }, 
  { name: "RRR1",           color: DEFAULT_COLOR_RRR1, dashArray: undefined, show: true, layoutType: LAYOUT_TYPE_LINE, valueTransformation: undefined }, 
  { name: "VV",             color: DEFAULT_COLOR_VV,      dashArray: undefined, show: false, layoutType: LAYOUT_TYPE_LINE,    valueTransformation: getScale(1 / 1000) },   // TODO unit berücksichtigen

  { name: "W-HSSW",         color: DEFAULT_COLOR_WSSH,    dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, // getScale(5) },   // TODO unit berücksichtigen }, 
  { name: "W-WSHMAX",       color: DEFAULT_COLOR_WHEIGHTMAX, dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, // getScale(5) },   // TODO unit berücksichtigen }, 
  { name: "W-DMEAN",        color: DEFAULT_COLOR_UNKNOWN, dashArray: undefined, show: false, layoutType: LAYOUT_TYPE_UNKNOWN, valueTransformation: undefined }, 
  { name: "W-PSEA",         color: DEFAULT_COLOR_WPERIOD, dashArray: "5,5",     show: false, layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "W-PMEAN",        color: DEFAULT_COLOR_WPERIOD, dashArray: "5,5",     show: false, layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined },
  
  { name: "Warning",         color: DEFAULT_COLOR_WSSH,    dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 
  { name: "WarningWind",     color: DEFAULT_COLOR_FF,      dashArray: undefined, show: true,  layoutType: LAYOUT_TYPE_LINE,    valueTransformation: undefined }, 

]

const DEFAULT_DATA_API_COLORS = {
  // nur für Parameter aus Datenschnittstelle
  ffms: DEFAULT_COLOR_FF,
  ffgms: DEFAULT_COLOR_FFG,
  tt: DEFAULT_COLOR_TT,
}

let defaultColorOfParam = (p) => {
  let meteoParam = ALL_KNOWN_METEO_PARAMS.filter(params => params.name === p)
  if (meteoParam.length === 1) {
    return meteoParam[0].color
  }

  return DEFAULT_DATA_API_COLORS[p] ? DEFAULT_DATA_API_COLORS[p] : DEFAULT_COLOR_UNKNOWN
}

let defaultDashArrayOfParam = (p) => {
  let meteoParam = ALL_KNOWN_METEO_PARAMS.filter(params => params.name === p)
  if (meteoParam.length === 1) {
    return meteoParam[0].dashArray
  }

  return undefined
}

let defaultShow = (p) => {
  let meteoParam = ALL_KNOWN_METEO_PARAMS.filter(params => params.name === p)
  if (meteoParam.length === 1) {
    return meteoParam[0].show
  }

  return false
}

let isWWParam = (p) => {
  return p === "WW"
}

let isLineParam = (p) => {
  let meteoParam = ALL_KNOWN_METEO_PARAMS.filter(params => params.name === p)
  if (meteoParam.length === 1) {
    return meteoParam[0].layoutType === LAYOUT_TYPE_LINE
  }

  return false
}

let lineParamTransformation = (p, validValue) => {
  let meteoParam = ALL_KNOWN_METEO_PARAMS.filter(params => params.name === p)
  if (meteoParam.length === 1) {
    if (meteoParam[0].valueTransformation) {
      return meteoParam[0].valueTransformation(validValue)
    }
  }

  return validValue
}

const getColumnHeader = (p, unit, t) => {
  if (p === 'VV') {
    return t("table_header_param_" + p) + " (" + t("param_unit_km") + ")"
  } else {
    return (unit === DATA_TYPE_TEXT || unit === UNIT_TYPE_NONE)  ? t("table_header_param_" + p) : 
          t("table_header_param_" + p) + " (" + t("param_unit_" + unit) + ")"
  }
}

const formatValue= (value, pKey, pUnit, t) => {
  if (pKey === 'VV' && value.length > 0) {
    let inMtr = pUnit === 'km' ? value[0] * 0.001 : value[0] * 1  // * 1 ensures converting strings to number
    if (inMtr < 1000) {
      let formattedInMtr = inMtr.toFixed(0) + " " + t("param_unit_m")
      return [formattedInMtr]
    } else {
      let inKm = inMtr / 1000
      return [inKm.toFixed(0)]
    }
  } else {
    return value
  }
}
const getFormattedValue = (row, p, pIndex, t) => {  
  let value = row["param" + (pIndex + 1)]
  return formatValue(value, p[0], p[2], t)
}

const getFormattedValueFromVictoryLineDatum = (datum, pKey, pUnit, pIndex, t) => {
  let valueArray = datum["param" + (pIndex + 1)]
  if (valueArray && valueArray.length > 0) {
    let formatted = "" + formatValue(valueArray, pKey, pUnit, t) // TODO VV unit berücksichtigen, s. oben, bis her nur 'm' als ursprungl. Einheit getestet
    if (pUnit !== DATA_TYPE_TEXT && pUnit !== UNIT_TYPE_NONE) {
      if (pKey === 'VV') {
        if (formatted.includes(" " + t("param_unit_m")) === false) {
          formatted += " " + t("param_unit_km")
        }
      } else {
        formatted += " " + t("param_unit_" + pUnit)
      }
    }
    return formatted
  } else {
    return ""
  }
}

const wwToSymbol = 
[ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
  4, 4, 4, 0, 0, 0, 6, 14, 0, 0, 
  6, 6, 10, 12, 6, 8, 9, 9, 5, 14, 
  4, 4, 4, 4, 4, 4, 0, 0, 0, 0, 
  4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 
  // drizzle
  6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 
  // rain
  6, 7, 7, 7, 11, 11, 6, 7, 
  // rain and snow
  12, 12, 
  // snow
  10, 10, 10, 13, 13, 13, 10, 10, 10, 10, 
  // rain showers
  8, 8, 8, 
  // rain and snow showers
  12, 12, 
  // snow showers
  10, 13, 10, 13, 16, 
  // thunderstorm
  16, 14, 14, 16, 16, 14, 16, 16, 14, 16]


const getWWSymbol = (wwCode, wwClouds) => {
  let wwCodeTmp
  if (wwCode >= 0 && wwCode < 100 ) {
    if (wwToSymbol[wwCode] !== 0) {
      wwCodeTmp = wwToSymbol[wwCode]
    } else if (wwToSymbol[wwCode] === 0 && wwClouds <= 15) {
        wwCodeTmp = 0
    } else if (wwToSymbol[wwCode] === 0 && wwClouds <= 50) {
          wwCodeTmp = 1
    } else if (wwToSymbol[wwCode] === 0 && wwClouds <= 80) {
              wwCodeTmp = 2
    } else if (wwToSymbol[wwCode] === 0 && wwClouds <= 100) {
                  wwCodeTmp = 3
      }

    return wwCodeTmp
  } else {
    return undefined
  }
}

const LANG_DE = 'de'
const LANG_EN = 'en'
//TODO
let wwToText = { 'de': [], 'en': []}

 wwToText[LANG_DE][0]="wolkenlos"
 wwToText[LANG_DE][1]=""
 wwToText[LANG_DE][2]=""
 wwToText[LANG_DE][3]=""
 wwToText[LANG_DE][4]=""
 wwToText[LANG_DE][5]=""
 wwToText[LANG_DE][6]=""
 wwToText[LANG_DE][7]=""
 wwToText[LANG_DE][8]=""
 wwToText[LANG_DE][9]=""
 wwToText[LANG_DE][10]="diesig"
 wwToText[LANG_DE][11]="Nebelschwaden"
 wwToText[LANG_DE][12]="flacher Nebel"
 wwToText[LANG_DE][13]=""
 wwToText[LANG_DE][14]=""
 wwToText[LANG_DE][15]=""
 wwToText[LANG_DE][16]=""
 wwToText[LANG_DE][17]="Gewitter"
 wwToText[LANG_DE][18]=""
 wwToText[LANG_DE][19]=""
 wwToText[LANG_DE][20]="Nieseln"
 wwToText[LANG_DE][21]="Regen möglich"
 wwToText[LANG_DE][22]="Schnee möglich"
 wwToText[LANG_DE][23]="Schneeregen mögl."
 wwToText[LANG_DE][24]="gefr. Sprühregen"
 wwToText[LANG_DE][25]="Schauer möglich"
 wwToText[LANG_DE][26]="Schneeschauer mögl."
 wwToText[LANG_DE][27]="Graupelschauer mögl."
 wwToText[LANG_DE][28]="nach Nebel"
 wwToText[LANG_DE][29]="nach Gewitter"
 wwToText[LANG_DE][30]="Dunst"
 wwToText[LANG_DE][31]="Dunst"
 wwToText[LANG_DE][32]="Dunst"
 wwToText[LANG_DE][33]="Dunst"
 wwToText[LANG_DE][34]="Dunst"
 wwToText[LANG_DE][35]="Dunst"
 wwToText[LANG_DE][36]=""
 wwToText[LANG_DE][37]=""
 wwToText[LANG_DE][38]=""
 wwToText[LANG_DE][39]=""
 wwToText[LANG_DE][40]="Nebel möglich"
 wwToText[LANG_DE][41]="Nebel möglich"
 wwToText[LANG_DE][42]="Nebel möglich"
 wwToText[LANG_DE][43]="Nebel"
 wwToText[LANG_DE][44]="Nebel"
 wwToText[LANG_DE][45]="Nebel"
 wwToText[LANG_DE][46]="Nebel"
 wwToText[LANG_DE][47]="Nebel"
 wwToText[LANG_DE][48]="gefr. Nebel"
 wwToText[LANG_DE][49]="gefr. Nebel"
 wwToText[LANG_DE][50]="geringer Sprühregen"
 wwToText[LANG_DE][51]="leichter Sprühregen"
 wwToText[LANG_DE][52]="Sprühregen"
 wwToText[LANG_DE][53]="Sprühregen"
 wwToText[LANG_DE][54]="Sprühregen"
 wwToText[LANG_DE][55]="Sprühregen"
 wwToText[LANG_DE][56]="gefr. Sprühregen"
 wwToText[LANG_DE][57]="gefr. Sprühregen"
 wwToText[LANG_DE][58]="Sprühregen"
 wwToText[LANG_DE][59]="Sprühregen"
 wwToText[LANG_DE][60]="geringer Regen"
 wwToText[LANG_DE][61]="leichter Regen"
 wwToText[LANG_DE][62]="Regen"
 wwToText[LANG_DE][63]="Regen"
 wwToText[LANG_DE][64]="Regen"
 wwToText[LANG_DE][65]="starker Regen"
 wwToText[LANG_DE][66]="gefr. Regen"
 wwToText[LANG_DE][67]="gefr. Regen"
 wwToText[LANG_DE][68]="Schneeregen"
 wwToText[LANG_DE][69]="Schneeregen"
 wwToText[LANG_DE][70]="geringer Schneefall"
 wwToText[LANG_DE][71]="leichter Schneefall"
 wwToText[LANG_DE][72]="Schneefall"
 wwToText[LANG_DE][73]="Schneefall"
 wwToText[LANG_DE][74]="Schneefall"
 wwToText[LANG_DE][75]="starker Schneefall"
 wwToText[LANG_DE][76]="geringer Schneefall"
 wwToText[LANG_DE][77]="geringer Schneefall"
 wwToText[LANG_DE][78]=""
 wwToText[LANG_DE][79]="geringer Schneefall"
 wwToText[LANG_DE][80]="Regenschauer"
 wwToText[LANG_DE][81]="Regenschauer"
 wwToText[LANG_DE][82]="starke Regenschauer"
 wwToText[LANG_DE][83]="Schneeregenschauer"
 wwToText[LANG_DE][84]="Schneeregenschauer"
 wwToText[LANG_DE][85]="Schneeschauer"
 wwToText[LANG_DE][86]="Schneeschauer"
 wwToText[LANG_DE][87]="Graupelschauer"
 wwToText[LANG_DE][88]="Graupelschauer"
 wwToText[LANG_DE][89]="Hagelschauer"
 wwToText[LANG_DE][90]="Hagelschauer"
 wwToText[LANG_DE][91]="Gewitter"
 wwToText[LANG_DE][92]="Gewitter"
 wwToText[LANG_DE][93]="Gewitter"
 wwToText[LANG_DE][94]="Gewitter"
 wwToText[LANG_DE][95]="Gewitter"
 wwToText[LANG_DE][96]="Gewitter"
 wwToText[LANG_DE][97]="schweres Gewitter"
 wwToText[LANG_DE][98]="schweres Gewitter"
 wwToText[LANG_DE][99]="schweres Hagelgewitter"

 wwToText[LANG_EN][0]="clear skies"
 wwToText[LANG_EN][1]=""
 wwToText[LANG_EN][2]=""
 wwToText[LANG_EN][3]=""
 wwToText[LANG_EN][4]=""
 wwToText[LANG_EN][5]=""
 wwToText[LANG_EN][6]=""
 wwToText[LANG_EN][7]=""
 wwToText[LANG_EN][8]=""
 wwToText[LANG_EN][9]=""
 wwToText[LANG_EN][10]="mist"
 wwToText[LANG_EN][11]="patches of fog"
 wwToText[LANG_EN][12]="shallow fog"
 wwToText[LANG_EN][13]=""
 wwToText[LANG_EN][14]=""
 wwToText[LANG_EN][15]=""
 wwToText[LANG_EN][16]=""
 wwToText[LANG_EN][17]="thunderstorm"
 wwToText[LANG_EN][18]=""
 wwToText[LANG_EN][19]=""
 wwToText[LANG_EN][20]="drizzle"
 wwToText[LANG_EN][21]="rain possible"
 wwToText[LANG_EN][22]="snow possible"
 wwToText[LANG_EN][23]="rain/snow poss."
 wwToText[LANG_EN][24]="freezing rain poss."
 wwToText[LANG_EN][25]="rain shower poss."
 wwToText[LANG_EN][26]="snow shower poss."
 wwToText[LANG_EN][27]="hail shower poss."
 wwToText[LANG_EN][28]="fog possible"
 wwToText[LANG_EN][29]="thunderstorm"
 wwToText[LANG_EN][30]="haze"
 wwToText[LANG_EN][31]="haze"
 wwToText[LANG_EN][32]="haze"
 wwToText[LANG_EN][33]="haze"
 wwToText[LANG_EN][34]="haze"
 wwToText[LANG_EN][35]="haze"
 wwToText[LANG_EN][36]=""
 wwToText[LANG_EN][37]=""
 wwToText[LANG_EN][38]=""
 wwToText[LANG_EN][39]=""
 wwToText[LANG_EN][40]="fog poss."
 wwToText[LANG_EN][41]="fog poss."
 wwToText[LANG_EN][42]="fog poss."
 wwToText[LANG_EN][43]="fog"
 wwToText[LANG_EN][44]="fog"
 wwToText[LANG_EN][45]="fog"
 wwToText[LANG_EN][46]="fog"
 wwToText[LANG_EN][47]="fog"
 wwToText[LANG_EN][48]="freezing fog"
 wwToText[LANG_EN][49]="freezing fog"
 wwToText[LANG_EN][50]="light drizzle"
 wwToText[LANG_EN][51]="light drizzle"
 wwToText[LANG_EN][52]="drizzle"
 wwToText[LANG_EN][53]="drizzle"
 wwToText[LANG_EN][54]="drizzle"
 wwToText[LANG_EN][55]="drizzle"
 wwToText[LANG_EN][56]="freezing drizzle"
 wwToText[LANG_EN][57]="freezing drizzle"
 wwToText[LANG_EN][58]="drizzle"
 wwToText[LANG_EN][59]="drizzle"
 wwToText[LANG_EN][60]="light rain"
 wwToText[LANG_EN][61]="light rain"
 wwToText[LANG_EN][62]="rain"
 wwToText[LANG_EN][63]="rain"
 wwToText[LANG_EN][64]="rain"
 wwToText[LANG_EN][65]="heavy rain"
 wwToText[LANG_EN][66]="freezing rain"
 wwToText[LANG_EN][67]="freezing rain"
 wwToText[LANG_EN][68]="rain/snow"
 wwToText[LANG_EN][69]="rain/snow"
 wwToText[LANG_EN][70]="light snow"
 wwToText[LANG_EN][71]="light snow"
 wwToText[LANG_EN][72]="snow"
 wwToText[LANG_EN][73]="snow"
 wwToText[LANG_EN][74]="snow"
 wwToText[LANG_EN][75]="heavy snow"
 wwToText[LANG_EN][76]="light snow"
 wwToText[LANG_EN][77]="light snow"
 wwToText[LANG_EN][78]=""
 wwToText[LANG_EN][79]="light snow"
 wwToText[LANG_EN][80]="rain shower"
 wwToText[LANG_EN][81]="rain shower"
 wwToText[LANG_EN][82]="heavy rain showers"
 wwToText[LANG_EN][83]="rain/snow shower"
 wwToText[LANG_EN][84]="rain/snow shower"
 wwToText[LANG_EN][85]="snow shower"
 wwToText[LANG_EN][86]="snow shower"
 wwToText[LANG_EN][87]="sleet shower"
 wwToText[LANG_EN][88]="sleet shower"
 wwToText[LANG_EN][89]="hail shower"
 wwToText[LANG_EN][90]="hail shower"
 wwToText[LANG_EN][91]="thunderstorm"
 wwToText[LANG_EN][92]="thunderstorm"
 wwToText[LANG_EN][93]="thunderstorm"
 wwToText[LANG_EN][94]="thunderstorm"
 wwToText[LANG_EN][95]="thunderstorm"
 wwToText[LANG_EN][96]="thunderstorm"
 wwToText[LANG_EN][97]="heavy thunderstorm"
 wwToText[LANG_EN][98]="heavy thunderstorm"
 wwToText[LANG_EN][99]="heavy thunderstorm with hail" 

function getWWTextFromVictoryLineDatum(datum, pKey, pUnit, pIndex, t, lang) { 
  let valueArray = datum["param" + (pIndex + 1)]
  if (valueArray && valueArray.length > 0) {
    let ww = parseInt(valueArray[0])
    let wwCode = (ww < 20 && ww !== 10 && ww !== 11 && ww !== 12 && ww !== 17) ? 0 : ww

    if ( wwCode >= 0 && wwCode <= 99 ) {
      let txt = wwToText[lang][wwCode]
      if (txt === "") console.log("no value for: " + wwCode)  
      return txt
    } else {
      console.log("no valid code: " + wwCode)
      return ""
    }
  }
  console.log("no ww value ")

  return ""
}

const cartesianInterpolationsVictory = [
  "basis",
  "bundle",
  "cardinal",
  "catmullRom",
  "linear",
  "monotoneX",
  "monotoneY",
  "natural",
  "step",
  "stepAfter",
  "stepBefore"
]

const defaultInterpolationVictory = "monotoneX" // "linear" // "natural"

let getWarnLevelSimple = (value, pIndex, warnings, colors) => {
  let warnLevel = undefined

  try {
    // TODO Refactoring, s. getTableCellStyleClasses
          let warnExpressions = []
          let warnColors = []
          if (warnings[pIndex].length > 0 && warnings[pIndex][0] !== null) {
            // TODO unterschiedliche Formate! {"[>=12,>=8]"} {<=6.5}
            let warnExpressionString = warnings[pIndex][0][0] === '[' ? warnings[pIndex][0].substring(1,warnings[pIndex][0].length -1) : warnings[pIndex][0] 
            warnExpressions = warnExpressionString.split(',')
          }
          if (colors[pIndex].length > 0 && colors[pIndex][0] !== null) {
            // TODO unterschiedliche Formate?
            let colorsString = colors[pIndex][0][0] === '[' ? colors[pIndex][0].substring(1,colors[pIndex][0].length -1) : colors[pIndex][0]
            warnColors = colorsString.split(',')
          }
        
          let length = Math.min(warnExpressions.length, warnColors.length)
          for (let level = 0 ; level < length && warnLevel === undefined; level++) {
            let expression = value + warnExpressions[level]
            if (expression !== warnExpressions[level]) {
              // TODO eval funktionert so nicht mit Parametern vom Type Text
              if (eval(expression)) {
                warnLevel = level
              }
            }
          }

          return warnLevel
  } catch(e) {
    // TODO später rausnehmen
    console.log(e)
    return undefined
  }
}

let getTableCellStyleClasses = (styles, row, p, pIndex, dataType, warnings, colors) => { 
  let levelClass = undefined

  try {
    // Spot Weather
    if (pIndex !== undefined) {
      // TODO: Parameter mit Type text behandeln
      if (dataType !== DATA_TYPE_TEXT) {
        let value = row["param" + (pIndex + 1)]
        if (value) {
          let warnExpressions = []
          let warnColors = []
          if (warnings[pIndex].length > 0 && warnings[pIndex][0] !== null) {
            // TODO unterschiedliche Formate! {"[>=12,>=8]"} {<=6.5}
            let warnExpressionString = warnings[pIndex][0][0] === '[' ? warnings[pIndex][0].substring(1,warnings[pIndex][0].length -1) : warnings[pIndex][0] 
            warnExpressions = warnExpressionString.split(',')
          }
          if (colors[pIndex].length > 0 && colors[pIndex][0] !== null) {
            // TODO unterschiedliche Formate?
            let colorsString = colors[pIndex][0][0] === '[' ? colors[pIndex][0].substring(1,colors[pIndex][0].length -1) : colors[pIndex][0]
            warnColors = colorsString.split(',')
          }
        
          let length = Math.min(warnExpressions.length, warnColors.length)
          for (let level = 0 ; level < length && levelClass === undefined; level++) {
            let expression = value + warnExpressions[level]
            if (expression !== warnExpressions[level]) {
              // TODO eval funktionert so nicht mit Parametern vom Type Text
              if (eval(expression)) {
                levelClass = styles["tableCellLevel" + warnColors[level]]
              }
            }
          }
        }
      }
    }

    // Data Api
    if (levelClass === undefined) {
      // TODO data api: echte limits anwenden

      if (p === 'dd' || p === 'ddDegree') {
        if (row['dd'] === 'N') {
          levelClass = styles.tableCellLevel2
        } else if (row['dd'] === 'NW' || row['dd'] === 'NE') {
          levelClass = styles.tableCellLevel1
        }
      }

      if (p === 'ffms' || p === 'ffgms') {
        if (row[p] > 8) {
          levelClass = styles.tableCellLevel2
        } else if (row[p] > 5) {
          levelClass = styles.tableCellLevel1
        }
      }

      if (p === 'tt') {
        if (row[p] > 18) {
          levelClass = styles.tableCellLevel2
        } else if (row[p] > 12) {
          levelClass = styles.tableCellLevel1
        }
      }
    }

    if (levelClass) {
      return [styles.smallTableCell, levelClass].join(' ')
    } else {
      return styles.smallTableCell
    }

  } catch(e) {
    // TODO später rausnehmen
    console.log(e)
    return styles.smallTableCell
  }
}

let xAxisTimeFormat = (timeValue, t) => {
  if (timeValue.getUTCHours() === 0 && timeValue.getUTCMinutes() === 0)
    return t("format_xAxisNewDay", {aDate: timeValue})

  return t("format_xAxisTime", {aDate: timeValue})
}


export { getTableCellStyleClasses, defaultColorOfParam, defaultDashArrayOfParam, defaultShow, cartesianInterpolationsVictory, defaultInterpolationVictory, 
  isLineParam, lineParamTransformation,
  isWWParam,
  getWWTextFromVictoryLineDatum,
  getWWSymbol,
  getColumnHeader,
  getFormattedValue,
  getFormattedValueFromVictoryLineDatum,
  getWarnLevelSimple,

  xAxisTimeFormat
}

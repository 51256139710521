import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  TextField,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material"

import useStyles from "./styles"

const LoginForm = ({ login, error = { msg: "" } }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [credentials, setCredentials] = useState({ name: "", password: "" })

  const handleSubmit = (event) => {
    event.preventDefault()
    if (login) {
      login(credentials)
    }
  }

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      console.log("enter")
      handleSubmit(event)
    }
  }

  const setFocusOnPassword = (event) => {
    if (event.key === "Enter") {
      document.getElementById("password").focus()
    }
  }

  return (
    <Grid container justifyContent='center'>
      <Card className={styles.loginForm}>
        <CardContent>
          <Grid container direction='row'>
            <Grid item xs={3}>
              <img
                src='images/logo.png'
                alt='WetterWelt'
                width='125'
                height='auto'
                className={styles.loginLogo}
              />
            </Grid>
            <Grid item xs={9}>
              <form onSubmit={handleSubmit} aria-label='login'>
                <Grid container alignItems='center' direction='column'>
                  <Grid item xs={12}>
                    <Grid item xs={9}>
                      <Typography
                        variant='h3'
                        align='center'
                        className={styles.loginTitle}
                      >
                        {t("login_title")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='subtitle1'
                      align='center'
                      className={styles.loginSubtitle}
                    >
                      {error.msg ? error.msg : t("login_text")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      className={styles.loginField}
                      required
                      id='userName'
                      label={t("login_label_name")}
                      value={credentials.name}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          name: e.target.value,
                        })
                      }
                      variant='outlined'
                      onKeyDown={(event) => setFocusOnPassword(event)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={styles.loginField}
                      required
                      id='password'
                      label={t("login_label_password")}
                      type='password'
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          password: e.target.value,
                        })
                      }
                      variant='outlined'
                      onKeyDown={(event) => submitOnEnter(event)}
                    />
                  </Grid>
                  <Grid container justifyContent='flex-end'>
                    <Grid item >
                      <Button
                        variant='outlined'
                        type='submit'
                        // onClick={handleSubmit}
                        className={styles.loginButton}
                      >
                        {t("login_action_login")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default LoginForm

import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { Drawer, IconButton, Divider, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useTheme } from '@mui/material/styles'

import useStyles from './styles'
import { getIconElement } from '../../modules/icons'

const SideBar = ( { onClose, open, items=[], currentItem, onSelect } ) => {
    const { t } = useTranslation()
    const styles = useStyles()
    const theme = useTheme()

    const [collapsed, setCollapsed] = useState([])    // collapsed items
    const handleCollapseClick = (clickedIndex) => {
        setCollapsed(collapsed => {
            if (collapsed.includes(clickedIndex)) {
                return collapsed.filter(itemIndex => itemIndex !== clickedIndex) // remove
            } else {
                return collapsed.concat(clickedIndex)    // add
            }
        })
    };

    function className(depth) {
        return depth === 0 ? styles.sideBar : (depth === 1 ? styles.sideBar1 : (depth === 2 ? styles.sideBar2 : styles.sideBar3))
    }

    const isSamePage = (currentItem, otherItem) => { return currentItem.pageNumber === otherItem.pageNumber && currentItem.loopIndex === otherItem.loopIndex }

    function SideBarListItem( {item, depth} ) {
        return (
            <>
                <ListItem button selected={isSamePage(currentItem, item)}
                    onClick={() => item.subItems ? handleCollapseClick(item.index) : onSelect(item)}>
                    <ListItemText className={item.subItems ? styles.sideBarExpand : undefined} classes={{primary: className(depth)}} primary={t(item.text, { textArg: item.textArg })} />
                    {   item.icon && !item.subItems && (
                        <ListItemIcon className={styles.sideBarIcon} >{getIconElement(item.icon)}</ListItemIcon>
                    )}
                    {   item.subItems && (
                        !collapsed.includes(item.index) ? // opened
                            <ListItemIcon className={styles.sideBarExpand} ><ExpandLess /></ListItemIcon>
                        : 
                            <ListItemIcon className={styles.sideBarExpand} ><ExpandMore /></ListItemIcon>
                    )}
                </ListItem>
                {   item.subItems ? (
                        <Collapse in={!collapsed.includes(item.index)} timeout="auto" unmountOnExit>
                            <List disablePadding dense>
                                { item.subItems.map(subItem => 
                                    <SideBarListItem item={subItem} depth={depth + 1} key={subItem.index}/>
                                )}
                            </List>
                        </Collapse>
                ) : null}
            </>
        )
    }
    
    return (
        <Drawer
            className={styles.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: styles.drawerPaper,
            }}
        >
            <div className={styles.drawerHeader}>
                <IconButton onClick={onClose} size="large">
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <List disablePadding dense>
                { items.map(item => 
                    <SideBarListItem item={item} depth={0} key={item.index}/>
                )}
            </List>
        </Drawer>
    );
}

export default SideBar

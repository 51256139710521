import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return ( {
        sideBar: {
            fontSize: theme.typography.fontSize,
            margin: theme.spacing(0, 0)
        },
        sideBar1: {
            fontSize: theme.typography.fontSize,
            margin: theme.spacing(0, 0, 0, 2)
        },
        sideBar2: {
            fontSize: theme.typography.fontSize,
            margin: theme.spacing(0, 0, 0, 4)
        },
        sideBar3: {
            fontSize: theme.typography.fontSize,
            margin: theme.spacing(0, 0, 0, 6)
        },
        sideBarIcon: {
            color: theme.palette.primary.dark
        },
        sideBarExpand: {
            color: theme.palette.primary.light
        },
        drawer: {
            width: theme.mixins.drawer.width,
            flexShrink: 0,
        },
        drawerPaper: {
            margin: theme.spacing(1, 0),
            width: theme.mixins.drawer.width,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        }
    })
})

export default useStyles
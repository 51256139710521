import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  getColumnHeader,
  getFormattedValue,
  isWWParam,
} from "../../modules/layout"
import { WeatherIconComponent } from "../SpotWeatherModule/WeatherIconComponent"
import useStyles from "./styles"
import styled from "styled-components"

import { useAccessContext } from '../../modules/AccessContext'

const SpotWeatherDataTableMeteo = ({
  values = [],
  params = [],
  warnings = [],
  colors = [],
  checked = [],
  onCheckChange = () => {},
}) => {
  const myStyles = useStyles()
  const { t } = useTranslation()
  const accessContext = useAccessContext()
//   console.log(params)
// params[i][0] == Wetterkürzel
// warnings[i][0] == ["startwwert", "Intervall grün gelb rot", "schrittweite" ]



  for (let i = 0; i < warnings.legnth; i++) {
    
   //setLimits({ ...limits, [params[0][0]]: { limit: "10", prelimit: 3, step: 1 }})   
  }


let testobj = {limit: "10", prelimit: 4, step: 1}
let testobj1 = {limit: "10", prelimit: 4, step: 1}

let testarray = [testobj, testobj1]
  let [limits, setLimits] = useState({

    //FF: { limit: "5.9", prelimit: 5.9, step: 1 },
 
    //FFDIR: "",
    // PPPP: "",
    // T: "",
    // RH: "",
    // NCL: "",
    // CUR: "",
    // CURDIRTO: "",
     "W-HSSW": { limit: "", prelimit: 0.7, step: 0.1 },
    // "W-DMEAN": "",
    // "W-PMEAN": "",
    // FFG_LOG_50_M: "",
    //FFG: { limit: "", prelimit: 5.9, step: 1 },
    // FF_LOG_50_M: "",
    // FFDIR_LOG_50_M: "",
    // VV: "",
    //"W-WSHMAX": { limit: "", prelimit: 0.7, step: 0.1 },
  })
//    setLimits({ ...limits, [name]: { ...limits[name], limit: value } })
// for (let i=0; i< warnings.length; i++) {
//   let abc = "abcdef"
//   limits = {...limits, abc: {limit: "10", prelimit: 2, step: 1}}
// }
useEffect(() => {
  //Runs on the first render
  //And any time any dependency value changes
  // let value = 
  // ({ ...limits, [name]: { ...limits[name], limit: value } })
  //console.log(accessContext.payload.spotweatherRequests[0].spotweatherParameters.nodes[3].param2)
  console.log(params[2])
  setLimits({ ...limits, FF: {limit: "", prelimit: 2, step: 1}})
  //setLimits( { ...limits, FFDIR: {limit: "10", prelimit: 2, step: 1}})
}, [accessContext]);

 //limits = {...limits, testing: {limit: "10", prelimit: 2, step: 1}}
  console.log(warnings)
  console.log(limits)


  let cloudsIndex
  if (params[params.length-1][0] === "NCL") {
    cloudsIndex = params.length
    params.pop()
  }
  
  if (accessContext.payload.access.includes("RRRone_ri")) {
    for (let i=48; i < values.length; i++) {
      values[i].param5 = [""]
    }
    for (let j=0; j < 48; j++) {
      values[j].param6 = [""]
    }
    for (let k=47; k < 56; k++) {
      values[k].param6 = [""]
      k++
    }
  }
  if (accessContext.payload.access.includes("woa")) {
  for (let i=0;i<values.length;i++) {
    values[i].timeFormatted = values[i].timeAsDate.toLocaleDateString() + ", " + values[i].timeAsDate.toLocaleTimeString().slice(0,5)
  }
  }
//   console.log(values[0])
// console.log(values[0].timeAsDate)
// console.log(values[0].timeAsDate.toISOString().split('T')[0])
// console.log(values[0].timeAsDate.toLocaleDateString())
// console.log(values[0].timeAsDate.toLocaleTimeString().slice(0,5))
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow key={"-1"} className={myStyles.tableRow}>
          <TableCell
            key={"-1"}
            className={myStyles.smallTableHeaderCell}
            align='center'
          >
            <div className={myStyles.headerCell}>
              {(accessContext.payload.access.includes("local_time")) ?
                  t("table_header_time_lt")
                  :
                  t("table_header_time_utc")}
              
              {/* <div className={myStyles.checkBoxWrapper} /> */}
              <div className={myStyles.inputWrapper} />
            </div>
          </TableCell>
          {params.map((p, index) => (
            <TableCell
              key={index}
              className={myStyles.smallTableHeaderCell}
              align='center'
            >
              <div className={myStyles.headerCell}>
                {getColumnHeader(p[0], p[2], t)}
                {/* <div className={myStyles.checkBoxWrapper}>
                  {(isLineParam(p[0]) || isWWParam(p[0])) && (
                    <Checkbox
                      checked={checked[p[0]]}
                      onChange={onCheckChange}
                      name={p[0]}
                      color='primary'
                    />
                  )}
                </div> */}
                <div className={myStyles.inputWrapper}>
                  {p[0] in limits && (
                    <>
                      <span>&gt; </span>
                      <Tooltip
                        title='Grenzwert für Warnrot'
                        placement='top'
                        arrow
                      >
                        <input
                          className={myStyles.limitInput}
                          type='number'
                          step={limits[p[0]].step}
                          name={p[0]}
                          onChange={handleChange}
                          value={limits[p[0]].limit}
                        />
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map((row, rowIndex) => (
          <TableRow key={rowIndex} hover className={myStyles.tableRow}>
            <TableCell
              key={"-1"}
              className={myStyles.smallTableCell}
              align='center'
            >
              {row.timeFormatted}
            </TableCell>
            {params.map((p, pIndex) => (
              <TableCellBodyStyled
                key={pIndex}
                // className={getTableCellStyleClasses(
                //   myStyles,
                //   row,
                //   p[0],
                //   pIndex,
                //   p[2],
                //   warnings,
                //   colors
                // )}
                align='center'
                innerValue={getFormattedValue(row, p, pIndex, t)}
                limit={limits[p[0]]?.limit}
                prelimit={limits[p[0]]?.prelimit}
              >
                {isWWParam(p[0]) === false &&
                  getFormattedValue(row, p, pIndex, t)}
                {isWWParam(p[0]) === true && (
                  <WeatherIconComponent row={row} pIndex={pIndex} cloudsIndex={cloudsIndex}/>
                )}
              </TableCellBodyStyled>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
  function handleChange(event) {
    const { name, value } = event.target
    setLimits({ ...limits, [name]: { ...limits[name], limit: value } })
    console.log(limits)
  }
}

const TableCellBodyStyled = styled(TableCell)`
  background-color: ${(props) => {
    if (props.limit === "") {
      return "inherit"
    } else if (Number(props.innerValue) > Number(props.limit)) {
      return "#bb1e10"
    } else if (
      Number(props.innerValue) <=
      Number(props.limit) - props.prelimit
    ) {
      return "#008351"
    } else if (
      Number(props.innerValue) >
      Number(props.limit) - props.prelimit
    ) {
      return "#EFB700"
    } else {
      return "inherit"
    }
  }};
  color: ${(props) => {
    if (props.limit === "") {
      return "theme.palette.background.paper"
    } else if (Number(props.innerValue) > Number(props.limit)) {
      return "white"
    } else if (
      Number(props.innerValue) <=
      Number(props.limit) - props.prelimit
    ) {
      return "white"
    } else if (
      Number(props.innerValue) >
      Number(props.limit) - props.prelimit
    ) {
      return "theme.palette.background.paper"
    } else {
      return "theme.palette.background.paper"
    }
  }};
`

export { SpotWeatherDataTableMeteo }

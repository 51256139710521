// enum ersatz:
const PageNumbers = {
    Customers:1,
    Contacts:2,
    Products:3,
    Purchases:4,
    RequestInfoSps:6,
    Protocols:7,
    DispatchLogs:8,
    Authusers:9,

    TestDatavizMain3:97,
    AstroParent:99,

    Home:100,

    TaxonomyFamilies:200,
    TaxonomyGenera:201,
    O4PaleoMaps:210,
    PaleoMaps:211,
    AstroSuns:220,
    Spacecrafts:221,

    WetterweltPorts:300,
    GeoDbProtocols:310,
    ForecastModelParameters:399,

    SpotweatherRequests:400,
    SpotweatherLocations:401,
    SpotweatherParameters:402,
    Spotweathers:403,
    MeteoDbProtocols:410,

    Synopsis: 500,
    SpotWeather: 501,
    SpotWeatherD3: 502,
    SpotWeatherVictory: 503,
    SpotWeatherVega: 504,
    SpotWeatherNivo: 505,
    SpotWeatherRecharts: 506,
    RiskAssessment: 507,
    SpotWeatherMeteo: 508,
    SpotWeatherMap: 509,
    SpotWeatherAnni1: 510,
    SpotWeatherAnni2: 511,
    SpotWeatherTableOnly: 512,
    SpotWeatherGraphicOnly: 513,
    SingleSpotweather: 514,

    WeatherMap: 515,

    MeteoModelImportPaths:600,
    
    AgrarTexts:610,
    AgrarTextsFrühling:611,
    AgrarTextsSommer:612,
    AgrarTextsHerbst:613,
    AgrarTextsWinter:614,
    
    BioTexts:650,

    BioTextsFrühlingSchoenwetter_angenehm:6510,
    BioTextsFrühlingExtrem_mild_sehr_heiss:6511,
    BioTextsFrühlingNaehe_zum_Tiefauslaeufer:6512,
    BioTextsFrühlingFront_und_Tiefkernbereich:6513,
    BioTextsFrühlingWarmsektor:6514,
    BioTextsFrühlingRueckseitenwetter:6515,
    BioTextsFrühlingNasskalt_Nebel:6516,
    BioTextsFrühlingStrenge_Winterlage:6517,
    BioTextsFrühlingEgal:6518,

    BioTextsSommerSchoenwetter_angenehm:6520,
    BioTextsSommerExtrem_mild_sehr_heiss:6521,
    BioTextsSommerNaehe_zum_Tiefauslaeufer:6522,
    BioTextsSommerFront_und_Tiefkernbereich:6523,
    BioTextsSommerWarmsektor:6524,
    BioTextsSommerRueckseitenwetter:6525,
    BioTextsSommerNasskalt_Nebel:6526,
    BioTextsSommerStrenge_Winterlage:6527,
    BioTextsSommerEgal:6528,

    BioTextsHerbstSchoenwetter_angenehm:6530,
    BioTextsHerbstExtrem_mild_sehr_heiss:6531,
    BioTextsHerbstNaehe_zum_Tiefauslaeufer:6532,
    BioTextsHerbstFront_und_Tiefkernbereich:6533,
    BioTextsHerbstWarmsektor:6534,
    BioTextsHerbstRueckseitenwetter:6535,
    BioTextsHerbstNasskalt_Nebel:6536,
    BioTextsHerbstStrenge_Winterlage:6537,
    BioTextsHerbstEgal:6538,

    BioTextsWinterSchoenwetter_angenehm:6540,
    BioTextsWinterExtrem_mild_sehr_heiss:6541,
    BioTextsWinterNaehe_zum_Tiefauslaeufer:6542,
    BioTextsWinterFront_und_Tiefkernbereich:6543,
    BioTextsWinterWarmsektor:6544,
    BioTextsWinterRueckseitenwetter:6545,
    BioTextsWinterNasskalt_Nebel:6546,
    BioTextsWinterStrenge_Winterlage:6547,
    BioTextsWinterEgal:6548,

    BioTextsAllgemeinSchoenwetter_angenehm:6550,
    BioTextsAllgemeinExtrem_mild_sehr_heiss:6551,
    BioTextsAllgemeinNaehe_zum_Tiefauslaeufer:6552,
    BioTextsAllgemeinFront_und_Tiefkernbereich:6553,
    BioTextsAllgemeinWarmsektor:6554,
    BioTextsAllgemeinRueckseitenwetter:6555,
    BioTextsAllgemeinNasskalt_Nebel:6556,
    BioTextsAllgemeinStrenge_Winterlage:6557,
    BioTextsAllgemeinEgal:6558,

    BioTextsSommer:652,
    BioTextsHerbst:653,
    BioTextsWinter:654,
    BioTextsAllgemein:655,

    NdrCustomerDtns:700,
    NdrCustomerDtnsMesswerteNorddeutschland:701,
    NdrCustomerDtnsMesswerteD_EU_WELT:702,
    NdrCustomerDtnsVorhersagen:703,
    NdrCustomerDtnsTexteNorddeutschland:704,
    NdrCustomerDtnsTextDeutschlandNacht:706,
    NdrCustomerDtnsTextWetterspezial:707,
    NdrCustomerDtnsTextNiedersachsenRegional:708,
    NdrCustomerDtnsTextAgrarBioPollen:709,
    NdrCustomerDtnsTextWasser:710,
    NdrCustomerDtnsTextWintersport:711,
    NdrCustomerDtnsTextNdrTeletext:712,
    NdrCustomerDtnsTextNdrOnline:713,
    NdrCustomerDtnsTextInfo:714,
    NdrCustomerDtnsTextUnwetterwarnung:715,
    NdrCustomerDtnsTexteNorddeutsche_bundeslaender:716,
    NdrCustomerDtnsBegriffserklaerung:717,
    NdrCustomerDtnsWindlegende:718,
    NdrCustomerDtnsKontakt:719,

    Impressum: 800,
    AGB: 801,
    Contact: 802,

    Vessels:900,
    Voyages:901,
    Routes:902,
    Waypoints:903,

}

export {
    PageNumbers
}    
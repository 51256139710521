import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
    return ( {
        loginForm: {
            color: theme.palette.primary.dark,
            margin: theme.spacing(15, 10, 2, 5),
        },
        loginLogo: {
            margin: theme.spacing(2, 0, 3, 3),
        },
        loginTitle: {
            margin: theme.spacing(3, 0, 3, 0),
        },
        loginSubtitle: {
            margin: theme.spacing(3, 10, 2),
        },
        loginField: {
            margin: theme.spacing(1, 0, 0),
        },
        loginButton: {
            margin: theme.spacing(6, 0, 0),
        }
    })
})

export default useStyles
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {Table,TableBody,TableCell,TablePagination,TableRow,Checkbox} from '@mui/material';
//import { isNullOrUndefined } from 'util';
import {Helper} from '../modules/Helper';
import PostGraphileApi from '../modules/PostGraphileApi';
import TableStatus from '../components/TableStatus';
import EnhancedTableHead from '../components/EnhancedTableHead';
import AlertDialog from '../components/AlertDialog';
import AgrarTextForm from './AgrarTextForm';
import JSON5 from 'json5';

const REGEX_INTEGER = /^[0-9]+$/;
//const REGEX_ACCESSKEYS = /^\[(["].+["][,])*(["].+["])\]$/;
const ROWS_PER_PAGE=200;

// const COMPACT_GUI=true

class AgrarTextTable extends React.Component {
 
  static STYLES= theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    table: {
      minWidth: 800,
      // whiteSpace: 'nowrap',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  });

  constructor(props) {
    super(props);

    this.DTF=new Intl.DateTimeFormat('de',{ year:'2-digit',month:'2-digit',day:'2-digit',hour:'2-digit',minute:'2-digit',timeZone:'UTC' });  // year:'numeric',second:'2-digit' ,timeZoneName:'short'                   

    this.STATEKEY='data';
    this.KEY='agrarTexts';     
    this.GRAPHQL_PARAMETER='id,idJahreszeit,idGebiet,idZustand,jahreszeit,gebiet,zustand,text,date,gesperrt,protocolText'; 
    this.GRAPHQL_PARAMETER_LENGTH=this.GRAPHQL_PARAMETER.split(',').length+3;
    this.ROWS = [
      //{ id: 'id', numeric: true, disablePadding: false, label: 'ID' },
      { id: 'jahreszeit', numeric: false, label: 'Jahreszeit' },
      // { id: 'gebiet', numeric: false, label: 'Gebiet' },
      // { id: 'zustand', numeric: false, label: 'Zustand' },
      { id: 'text', numeric: false, label: 'Text' },
      // { id: 'date', numeric: true, disablePadding: true, label: 'Datum UTC' },
      // { id: 'idJahreszeit', numeric: true, disablePadding: false, label: 'ID Jahreszeit' },
      //{ id: 'idGebiet', numeric: true, disablePadding: false, label: 'ID Gebiet' },
      // { id: 'idZustand', numeric: true, disablePadding: false, label: 'ID Zustand' },
      // { id: 'gesperrt', numeric: true, disablePadding: false, label: 'Gesperrt' },    
      // { id: 'protocolText', numeric: false, label: 'ChangeLog\u2011Kommentar' },
    ];

    this.state = {
      order: 'asc',
      orderBy: 'id',
      data: [], 

      page: 0,
      rowsPerPage: ROWS_PER_PAGE,
    
      isOpenAlertDialog:false,
      isOpenErrorDialog:false,
      errorMsg:"",

      workobject:undefined,
      workerrors:undefined,

      editmode: 'insert',
      selected: [],

      tabvalue:0, // rc 2019-10-25: selektion normieren

      isLoaded:false,

      filterGlobalOld: '',
     
      componentNumber:0,
    };
    this.setSelectedState(this.state,this.props,this.createNewObject(-1));
    this.setSelectedErrorsState(this.state,false);

    // mixins from helper:
    this.updateProtocolLoginName=Helper.updateProtocolLoginName;
    this.handleTextfieldChange = Helper.handleTextfieldChangeW;
    this.handleCheckboxChange = Helper.handleCheckboxChangeW;
    this.handleAlertDialogDisagree = Helper.handleAlertDialogDisagree;
    this.handleAlertDialogClose = Helper.handleAlertDialogClose;
    this.handleRequestSort=Helper.handleRequestSort;
    this.handleSelectAllClick=Helper.handleSelectAllClick;
    this.handleChangePage=Helper.handleChangePage;
    this.handleChangeRowsPerPage=Helper.handleChangeRowsPerPage;
    this.handleTabChange=Helper.handleTabChange; // rc 2019-10-25: selektion normieren
    this.handleTextfieldChangeDate=Helper.handleTextfieldChangeDate
 };

  // mit foreign table:
  LOAD_GRAPHQL(GRAPHQL_PARAMETER,condition_keyvals=null) {
    //console.log('condition_keyvals='+JSON.stringify(condition_keyvals));
    const condition=(Helper.isNullOrUndefined(condition_keyvals))?'':'(condition:'+JSON5.stringify(condition_keyvals).replace(/'/g,'"')+')';
    const LOAD_GRAPHQL=`{ agrarTexts`+condition+` { nodes { `+GRAPHQL_PARAMETER+`}}}`;
    return LOAD_GRAPHQL;
  }

  createNewObject(uuid) { 
    return {
      id:uuid,
      idJahreszeit:0,
      idGebiet:0,
      idZustand:0,
      jahreszeit:'Frühling',
      gebiet:'Deutschland',
      zustand:'',
      text:'',
      date:'3000-01-01T00:00:00+00:00',
      gesperrt:"0",
      protocolText:'',
    }
  }

  setSelectedState(state,props,obj) {
    state.workobject={
      ...obj,
      protocolText:'Login-Name:'+props.loginName+' '+obj.protocolText.replace(/Login[-]Name:[A-Za-z]+[ ]*/,''),
    };
  }
  
  setSelectedErrorsState(state,b) {
    state.workerrors={
      idJahreszeit:b,
      idGebiet:b,
      idZustand:b,
      gesperrt:b,
      date:b,
    }
  }

  filterRegex(regex,obj) {
    return regex.test(obj.id) ||
           regex.test(obj.idJahreszeit) ||
           regex.test(obj.idGebiet) ||
           regex.test(obj.idZustand) ||
           regex.test(obj.jahreszeit.toLowerCase()) || 
           regex.test(obj.gebiet.toLowerCase()) || 
           regex.test(obj.zustand.toLowerCase()) || 
           regex.test(obj.text.toLowerCase()) || 
           regex.test(this.DTF.format(new Date(obj.date)))  ||                   
           regex.test(obj.gesperrt) ||
           regex.test(obj.protocolText.toLowerCase()); 
  }
  
  // gesperrt: BIGINT wird von postgraphile als STRING behandelt -> Sortierung in GUI dann alphanumerisch!
  setGraphqlParameter(state) {
    return `
    idJahreszeit: ${state.workobject.idJahreszeit}      
    idGebiet: ${state.workobject.idGebiet}      
    idZustand: ${state.workobject.idZustand}      
    jahreszeit: "${state.workobject.jahreszeit}"
    gebiet: "${state.workobject.gebiet}"
    zustand: "${state.workobject.zustand}"
    text: "${state.workobject.text}"
    date: "${state.workobject.date}"     
    gesperrt: "${state.workobject.gesperrt}"       
    protocolText: "${state.workobject.protocolText.replace(/\n/g,'\\n')}"
    `;
  }
  
  // TODO: this.state.workobject.text nur einzeilig ohne line break, auch in BioTextTable.js
  validate() {
    const idJahreszeit=REGEX_INTEGER.test(this.state.workobject.idJahreszeit);
    const idGebiet=REGEX_INTEGER.test(this.state.workobject.idGebiet);
    const idZustand=REGEX_INTEGER.test(this.state.workobject.idZustand);
    const gesperrt=REGEX_INTEGER.test(this.state.workobject.gesperrt);
    const date=Date.parse(this.state.workobject.date)?true:false
    this.setState({
      workerrors:{ 
      idJahreszeit:!(idJahreszeit),
      idGebiet:!idGebiet,
      idZustand:!idZustand,
      gesperrt:!gesperrt,
      date:!date,
      }
    });
    return idJahreszeit && idGebiet && idZustand && gesperrt && date 
  }
/*
  list2String(s) {
    //const s=data[graphqlKey].nodes;
    for (let i=0; i<s.length; i++) {
      s[i].parameterName=JSON.stringify(s[i].parameterName);//.replace(/\[/g,"").replace(/\]/g,"").replace(/"/g,"");
    } 
  }
*/
  handleClick = (event, id, oldId,p_data) => {
    if (id === oldId && !oldId) { return; } // rc 2019-10-25: selektion normieren  (&& !oldId wegen 'copy')
    const newSelected = [id];
    //console.log(this.constructor.name+".handleClick(), checkbox-auswahl: selected-id="+id);//+" p_data="+JSON.stringify(p_data));
    const workobject = p_data.filter(x => x.id === id);
    const s={
      selected: newSelected,
      tabvalue:0, // rc 2019-10-25: selektion normieren
      editmode:'update',
    };
    this.setSelectedState(s,this.props,workobject[0]);
    this.setSelectedErrorsState(s,false);
    this.setState(s);
  };

// UNSAFE_componentWillReceiveProps(nextProps) {}

  // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  // -> The recommended upgrade path for most use cases is to move data-fetching into componentDidMount
  componentDidMount() {
    //console.log(this.constructor.name+'.componentDidMount()');
    this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY,true); // rc 2019-10-25: selektion normieren
  }

// -----------------------------------------------------------------------------------
// graphql: https://www.graphile.org/postgraphile/examples/#Mutations__Delete/https://www.graphile.org/postgraphile/examples/#Mutations__Update
// -----------------------------------------------------------------------------------
  
loadGraphql(graphqlQuery,stateKey,graphqlKey,isComponentDidMount=false) { // rc 2019-10-25: selektion normieren
  console.log(this.constructor.name+" graphqlQuery="+graphqlQuery);
  PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(graphqlQuery, this.props.accessToken),this.constructor.name + ".loadGraphql() fehler_1=")
  .then(({data}) => { 
    //this.list2String(data[graphqlKey].nodes);
    this.setState({[stateKey]:data[graphqlKey].nodes,isLoaded:true}); 
    return data[graphqlKey].nodes; 
  })   
  .then((data) => { // rc 2019-10-25: selektion normieren:
    if (isComponentDidMount && data.length>0) { this.handleClick(null,data[0].id,this.state.workobject.id,data); }
  })
  .catch((error)=> { console.log(this.constructor.name+".loadGraphql() fehler_2="+error.message); this.setState({isOpenErrorDialog:true,errorMsg:error.message}) });
}

newObj() {
  const query=`
  mutation {
    createAgrarText(input: {agrarText: {
    `+this.setGraphqlParameter(this.state)+`
    }}) 
    {
      agrarText { id }
    }
  }
  `;
  console.log(query)
  PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(query, this.props.accessToken),this.constructor.name + ".newObj() fehler_1=")
  .then(({data}) => { 
    this.setState({
      workobject:{...this.state.workobject,id:data.createAgrarText.agrarText.id,},  // im formular neue id anzeigen
      selected:[data.createAgrarText.agrarText.id],  // table-select-checkbox=on bei new
      editmode:'update',
    },()=>{
      this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY);
    });
  })
  .catch(function(error) { console.log(this.constructor.name+".newObj() fehler_1="+error); });
}  

deleteObj() {
  const mymap=n=>`
    a${n}: deleteAgrarText(input: {id: ${n}}) {
      clientMutationId
      deletedAgrarTextNodeId
      agrarText { id }
    }`;
  const query=`
  mutation {${this.state.selected.map(mymap)}
  }
  `;
  console.log("delete="+query)
  PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(query, this.props.accessToken),this.constructor.name + ".deleteObj() fehler_1=")
  .then(({data}) => { 
    const s={
      editmode:'',
    };
    this.setSelectedState(s,this.props,this.createNewObject(-1));  // maske vorsorglich zuruecksetzen
    this.setState(s,()=>{this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY,true);});  // rc 2019-10-25: selektion normieren
    //console.log('delete response=',JSON.stringify({ data }));
  })
  .catch((error)=> { console.log(this.constructor.name+".deleteObj() fehler_2="+error.message); this.setState({isOpenErrorDialog:true,errorMsg:error.message}) });
  }

updateObj() {
    const query=`
    mutation {
      updateAgrarText(
        input: {
          id: ${this.state.workobject.id}
          patch: {
          `+this.setGraphqlParameter(this.state)+`
          }
        }
      ) {
        agrarText { id }
      }
    }
    `;
    console.log(query)
    PostGraphileApi.fetchRequest(this.props.environment.sc_api.url,PostGraphileApi.httpRequestData(query, this.props.accessToken),this.constructor.name + ".updateObj() fehler_1=")
    .then(({data}) => { 
      this.setState({editmode:'update',},()=>{this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY);});
      //console.log('update response=',JSON.stringify({ data }));
    })
    .catch((error)=> { console.log(this.constructor.name+".updateObj() fehler_2="+error.message); this.setState({isOpenErrorDialog:true,errorMsg:error.message}) });
  }

// -----------------------------------------------------------------------------------
// event handler
// -----------------------------------------------------------------------------------

  handleSave() {
    if (this.state.editmode==='insert') {
      this.newObj();
    } else if (this.state.editmode==='update') {
      this.updateObj();
    } else {
      console.log("handleSave(): no action!!!")
    } 
  }

  handleNew(is_new=true) {
    const newObject=this.createNewObject(0);//UUID.v4());
    const s={
      editmode: 'insert',
      selected:[],  // selected checkbox loeschen
      workobject:{...this.state.workobject,id:0,},
    }
    if (is_new) { this.setSelectedState(s,this.props,newObject); }
    this.setSelectedErrorsState(s,false);
    this.setState(s);
  }

  handleDelete() {
    this.updateProtocolLoginName(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY,this.props.environment.sc_api.url)
    .then(this.deleteObj());
  }

  handleLoad() {
    this.loadGraphql(this.LOAD_GRAPHQL(this.GRAPHQL_PARAMETER,this.props.condition_keyvals),this.STATEKEY,this.KEY);   
  }
 
  handleOpenSaveDialog() {
    if (!this.validate()) { return; }
    this.setState({clickButton:"agrarTextSaveDialog",isOpenAlertDialog:true,errorMsg:""});
  }
  
  handleOpenDeleteDialog() {
    this.setState({clickButton:"agrarTextDeleteDialog",isOpenAlertDialog:true,errorMsg:""});
  }

  handleSaveAgree() {
    this.handleSave(this.state);
    this.setState({isOpenAlertDialog:false});
  }

  handleDeleteAgree() {
    this.handleDelete(this.state);
    this.setState({isOpenAlertDialog:false});
  }

// -----------------------------------------------------------------------------------
 
  filterGlobal(data,filter) {
      const regex = (filter==='')?
        RegExp('.*'):
        RegExp('^'+Helper.quoteRegexFilter(filter).replace(/%/g, ".*").toLowerCase()+".*");//+'$');
      //console.log("regex test:  regex="+filter+" "+regex.test(n.model));
      const filterdata=data.filter(n=> this.filterRegex(regex,n));
      return filterdata;
  }

  // https://www.w3schools.com/react/react_lifecycle.asp
  // The getDerivedStateFromProps method is called right before the render method
  // https://stackoverflow.com/questions/52886075/why-is-getderivedstatefromprops-is-a-static-method
  static getDerivedStateFromProps(props, state) {
    console.log("AgrarTextTable.getDerivedStateFromProps()");
    let s={};
    if (props.filterGlobal!==state.filterGlobalOld) { s.page=0; }
    s.filterGlobalOld=props.filterGlobal;
    return s;
  }

  render() {
    console.log(this.constructor.name+'.render()');
    const { data, order, orderBy, selected, rowsPerPage, page,isLoaded } = this.state;
    const {classes,filterGlobal}=this.props;
    const filterdata=this.filterGlobal(data,filterGlobal);

//    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filterdata.length - page * rowsPerPage);
    
    // foreign table:
    const component=
     (this.state.componentNumber===0) ?
      <div className={classes.root}>
        {/*<AgrarTextEnhancedTableToolbar numSelected={selected.length} />*/}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              rows={this.ROWS}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick.bind(this)}
              onRequestSort={this.handleRequestSort.bind(this)}
              rowCount={data.length}
            />
      {(isLoaded && filterdata.length>0)?
            <TableBody>
              {Helper.stableSort(filterdata, Helper.getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = Helper.isSelected(this.state,n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id,this.state.workobject.id,this.state.data)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox"><Checkbox checked={isSelected} /></TableCell>
                      {/*<TableCell component="th" scope="row" align="right">{n.id}</TableCell>*/}
                      <TableCell padding="normal">{n.jahreszeit}</TableCell>
                      {/*<TableCell padding="normal">{n.gebiet}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.zustand}</TableCell>*/}
                      <TableCell padding="normal">{n.text}</TableCell>
                      {/*<TableCell padding="normal">{this.DTF.format(new Date(n.date))}</TableCell>*/}
                      {/*<TableCell align="right">{n.idJahreszeit}</TableCell>*/}
                      {/*<TableCell align="right">{n.idGebiet}</TableCell>*/}
                      {/*<TableCell align="right">{n.idZustand}</TableCell>*/}
                      {/*<TableCell align="right">{n.gesperrt}</TableCell>*/}
                      {/*<TableCell padding="normal">{n.protocolText}</TableCell>*/}
                    </TableRow>
                  );
                })}
{/*                
              {emptyRows > 0 && (
                <TableRow style={{ height: 30 * emptyRows }}>
                  <TableCell colSpan={this.GRAPHQL_PARAMETER_LENGTH} />
                </TableRow>
              )}
*/}              
            </TableBody>
            : (isLoaded && filterdata.length===0)
            ? <TableStatus status={"Leer"} /> 
            : <TableStatus status={"Lade..."} /> 
            }    
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, ROWS_PER_PAGE, 500]}
          component="div"
          count={filterdata.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage.bind(this)}
          onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
        />
      <AgrarTextForm 
        workobject={this.state.workobject} 
        workerrors={this.state.workerrors}

        handleTextfieldChange={this.handleTextfieldChange.bind(this)} 
        handleCheckboxChange={this.handleCheckboxChange.bind(this)} 
        handleOpenSaveDialog={this.handleOpenSaveDialog.bind(this)} 
        handleNew={this.handleNew.bind(this)} 
        handleOpenDeleteDialog={this.handleOpenDeleteDialog.bind(this)} 
        handleLoad={this.handleLoad.bind(this)} 
        handleTabChange={this.handleTabChange.bind(this)} 

        tabvalue={this.state.tabvalue}
        loginName={this.props.loginName} 
        handleTextfieldChangeDate={this.handleTextfieldChangeDate.bind(this)} 

        condition_keyvals={this.props.condition_keyvals}
        
        environment={this.props.environment}
        accessToken={this.props.accessToken}
      />
      <AlertDialog 
        text              ={"Agrar Text '"+this.state.workobject.gebiet+"' "+(this.state.clickButton==="agrarTextSaveDialog"?"speichern?":"löschen?")} 
        buttonDisagreeText={(this.state.clickButton==="agrarTextSaveDialog"?"Nicht Speichern":"Nicht Löschen")} 
        buttonAgreeText   ={(this.state.clickButton==="agrarTextSaveDialog"?"Speichern":"Löschen")} 
        isOpenAlertDialog={this.state.isOpenAlertDialog} 
        handleClose={this.handleAlertDialogClose.bind(this)} 
        handleAgree={this.state.clickButton==="agrarTextSaveDialog"?this.handleSaveAgree.bind(this):this.handleDeleteAgree.bind(this)} 
        handleDisagree={this.handleAlertDialogDisagree.bind(this)}
      />
      <AlertDialog 
        text={this.state.errorMsg} 
        buttonDisagreeText={""} 
        buttonAgreeText={"OK"} 
        isOpenAlertDialog={this.state.isOpenErrorDialog} 
        handleClose={this.handleAlertDialogClose.bind(this)} 
        handleAgree={this.handleAlertDialogDisagree.bind(this)} 
        handleDisagree={this.handleAlertDialogDisagree.bind(this)}
      />
      </div> 
   :"" 
  ;    
   
   return component;
  }
}

AgrarTextTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(AgrarTextTable.STYLES)(AgrarTextTable);
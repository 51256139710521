import React from 'react';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

/*
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
*/

/*
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];
*/

const useStyles = makeStyles({
  table: {
    minWidth: 400,
    //whiteSpace: 'nowrap',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  innertable: {
    padding: '2px',
    whiteSpace: 'nowrap',
  },
  test: {
    paddingTop: '6px',
    paddingBottom: '6px',
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  if (props.csvArray!==null) { 
    //console.log(props.csvArray)
  } else {
    console.log("props.csvArray noch nicht fertig")
  }
  function MyDayOfWeek(date) {
    const weekdayNames=['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag']  // Sunday - Saturday : 0 - 6
    const newdate = new Date(date)
    const day = newdate.getUTCDay()
    return weekdayNames[day]
  }
  if (props.csvArray[1][0] === "Hamburg drei Tage" || props.csvArray[1][0] === "Hamburg morgen") {
    //props.csvArray[1][0].shift()
    const header = []
    //console.log(props.csvArray[1][1][0].dtg)
    var wetterDatum
    if (props.csvArray[1][0] === "Hamburg drei Tage") {
      wetterDatum = props.csvArray[1][4][0] + ' bis ' + props.csvArray[1][4][2]
      header.push("Hamburg-Umgebung");
      header.push(MyDayOfWeek(props.csvArray[1][1][0].dtg));
      header.push(MyDayOfWeek(props.csvArray[1][2][0].dtg));
      header.push(MyDayOfWeek(props.csvArray[1][3][0].dtg))
    } else if (props.csvArray[1][0] === "Hamburg morgen") {
      wetterDatum = props.csvArray[1][4]
      header.push("Hamburg-Umgebung");
      header.push("Morgens");
      header.push("Mittags");
      header.push("Abends")
    }
    

    
//console.log(header)
    return (
      <TableContainer component={Paper}>
        <h3>Wetter für Hamburg und Umgebung für den {wetterDatum}</h3>
        <Table className={classes.table} aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            {header.map((tmp, i) => (
            <StyledTableCell align='center'>{header[i]}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.innertable}>
            <TableContainer component={Paper}>
            <Table className={classes.innertable} aria-label="customized table" size="small">
              <TableHead>
                <TableRow>
                {props.csvArray[0].map((col,i) => ( (i===0) ?
                  <StyledTableCell key={col} align={"center"}>{props.csvArray[2][col]}</StyledTableCell>
                  : ''
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {props.csvArray[1][1].map((row) => (
           <StyledTableRow key={JSON.stringify(row)}> {/* TODO key geht besser!? */}
           {props.csvArray[0].map((col,i) => ( (i===0)?              
              <StyledTableCell className={classes.innertable} key={row[props.csvArray[0][0]]+col} align="left">{row[col]}</StyledTableCell>
              : ''
              ))}
           </StyledTableRow>
          ))}
              </TableBody>
            </Table>
            </TableContainer>
            </TableCell>
            <TableCell className={classes.innertable}>
      <TableContainer component={Paper}>
        <Table className={classes.innertable} aria-label="customized table" size="small">
          <TableHead>
          <TableRow key={"myheader"}>
          {props.csvArray[0].map((col,i) => ( (i===0 || i===1) ?
          ''
          :<StyledTableCell key={col} align={(i===0)?"left":"right"}>{props.csvArray[2][col]}</StyledTableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.csvArray[1][1].map((row) => (
           <StyledTableRow key={JSON.stringify(row)}> {/* TODO key geht besser!? */}
           {props.csvArray[0].map((col,i) => ( (i===0 || i===1)?              
              ''
              :<StyledTableCell className={classes.innertable} key={row[props.csvArray[0][0]]+col} align="center">{row[col]}</StyledTableCell>
           ))}
           </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </TableCell>
    <TableCell className={classes.innertable}>
          <TableContainer component={Paper}>
          <Table className={classes.innertable} aria-label="customized table" size="small">
            <TableHead>
            <TableRow key={"myheader"}>
            {props.csvArray[0].map((col,i) => ( (i===0 || i===1) ?
              ''
              :<StyledTableCell key={col} align={(i===0)?"left":"right"}>{props.csvArray[2][col]}</StyledTableCell>
            ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.csvArray[1][2].map((row) => (
             <StyledTableRow key={JSON.stringify(row)}> {/* TODO key geht besser!? */}
             {props.csvArray[0].map((col,i) => ( (i===0 || i===1) ?
                ''
                :<StyledTableCell className={classes.innertable} key={row[props.csvArray[0][0]]+col} align="center">{row[col]}</StyledTableCell>
             ))}
             </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </TableCell>
      <TableCell className={classes.innertable}>
      <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table" size="small">
            <TableHead>
            <TableRow key={"myheader"}>
            {props.csvArray[0].map((col,i) => ( (i===0 || i===1) ?
              ''
              :<StyledTableCell key={col} align={(i===0)?"left":"right"}>{props.csvArray[2][col]}</StyledTableCell>
            ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.csvArray[1][3].map((row) => (
             <StyledTableRow key={JSON.stringify(row)}> {/* TODO key geht besser!? */}
             {props.csvArray[0].map((col,i) => ( (i===0 || i===1) ?
                ''
                :<StyledTableCell className={classes.innertable} key={row[props.csvArray[0][0]]+col} align="center">{row[col]}</StyledTableCell>
             ))}
             </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </TableCell>
      </TableRow>
      </TableBody>
      </Table>
      </TableContainer>
    );
  }
  //////////////////////////////////////////////////////////////////////////////////
  else if (props.csvArray[1][0] === "windskala") {
    console.log(props.csvArray)
    return (
    <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="customized table" size="small">
      <TableHead>
        <TableRow key={"myheader"}>
        {props.csvArray[1][1][0].map((col,i) => (
          <StyledTableCell align={(i===0)?"left":"right"}>{props.csvArray[1][1][0][i]}</StyledTableCell>
        ))}
        </TableRow>
      </TableHead>
      <TableBody>
          {props.csvArray[1][1].map((row,i) => (
            (i===0)?              
            ""
            :
           <StyledTableRow > {/* TODO key geht besser!? */}
           {props.csvArray[1][1][1].map((col,i) => (
             (row[i]==="\\")?
             <StyledTableCell align="right">{""}</StyledTableCell>
             :
             (row[i]==="-\"-")?
             ""
             : <StyledTableCell align={(i===0)?"left":"right"} rowSpan={(row[i]==="schwacher Wind")?"3":(row[i]==="Tropischer Sturm")?"4":"1"}>{row[i]}</StyledTableCell>
           ))}
           </StyledTableRow>
          ))}
        </TableBody>
    </Table>
  </TableContainer>
    )}
    else if (props.csvArray[1][0] === "begriffserklaerung") {
      
      const mySubHeaderstyle = {
        color: "white",
        backgroundColor: "black"
      }
      return (
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table" size="small">

        <TableBody>
            {props.csvArray[1][1].map((row,i) => (
             <StyledTableRow > {/* TODO key geht besser!? */}
             {props.csvArray[1][1][1].map((col,i) => (
               (row[i]==="Bewölkung" || row[i]==="räumliche Verbreitung" || row[i]==="zeitliche Andauer")?
               
               
                 <StyledTableCell align={"left"} style={mySubHeaderstyle} colSpan={4}>{row[i]}</StyledTableCell>
                 :
                 (row[0]==="Bewölkung" || row[0]==="räumliche Verbreitung" || row[0]==="zeitliche Andauer")?
                 ""
                :
                (row[i]==="\\")?
                <StyledTableCell align="left"></StyledTableCell>
                :
                <StyledTableCell align="left">{row[i]}</StyledTableCell>
             ))}
             </StyledTableRow>
            ))}
          </TableBody>
      </Table>
    </TableContainer>
      )}
      else if (props.csvArray[1][0] === "Wassertemperaturen") {
        const mySubHeaderstyle = {
          color: "white",
          backgroundColor: "black",
        }
        return (
          
        <TableContainer component={Paper}>
          <h2>{props.csvArray[1][0]} {props.csvArray[1][1]}</h2>
          <h3>{props.csvArray[1][2]}</h3>
          <h3>{props.csvArray[1][3]}</h3>
        <Table className={classes.table} aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            {props.csvArray[1][4].map((tmp, i) => (
            <StyledTableCell align='left'>{props.csvArray[1][4][i]}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
          <TableBody>
              {props.csvArray[1][5].map((row,i) => (
                (row==="Niedersachsen" || row==="Schleswig-Holstein" || row==="Mecklenburg/Vorpommern")?
               <StyledTableRow > {/* TODO key geht besser!? */}
                  <StyledTableCell align={"left"} style={mySubHeaderstyle} colSpan={3}>{row}</StyledTableCell>
               </StyledTableRow>
               :
                <StyledTableRow >
                  <StyledTableCell align="left">{row}</StyledTableCell>
                  <StyledTableCell align="left">{props.csvArray[1][6][i]}</StyledTableCell>
                  <StyledTableCell align="left">{props.csvArray[1][7]}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
        </Table>
      </TableContainer>
        )}
  else {     
  let minTemperatur
  let maxTemperatur
  if (props.csvArray[1][props.csvArray[1].length-1][0] === "minmaxTemperaturen") {
  minTemperatur = props.csvArray[1][props.csvArray[1].length-1][1]
  maxTemperatur = props.csvArray[1][props.csvArray[1].length-1][2]
  props.csvArray[1].pop()
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table" size="small">
        <TableHead>
          <TableRow key={"myheader"}>
          {props.csvArray[0].map((col,i) => (
            <StyledTableCell key={col} align={(i===0)?"left":"right"}>{props.csvArray[2][col]}</StyledTableCell>
          ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.csvArray[1].map((row) => (
           <StyledTableRow key={JSON.stringify(row)}> {/* TODO key geht besser!? */}
           {props.csvArray[0].map((col,i) => (
              (i===0)?              
              <StyledTableCell key={row[props.csvArray[0][0]]+col} component="th" scope="row">
                {row[col]}
               </StyledTableCell>
               :(col === "tt" && row[col] !== "---")? 
               (col === "tt" && row[col].match(/[-]?\d+/)[0] === minTemperatur)?
               <StyledTableCell key={row[props.csvArray[0][0]]+col} align="right" style={{backgroundColor:'rgba(0, 128, 0, 0.5)'}}>{row[col]}</StyledTableCell>
               : (col === "tt" && row[col].match(/[-]?\d+/)[0] === maxTemperatur)?
               <StyledTableCell key={row[props.csvArray[0][0]]+col} align="right" style={{backgroundColor:'rgba(255, 0, 0, 0.5)'}}>{row[col]}</StyledTableCell>
              :<StyledTableCell key={row[props.csvArray[0][0]]+col} align="right">{row[col]}</StyledTableCell>
              :<StyledTableCell key={row[props.csvArray[0][0]]+col} align="right">{row[col]}</StyledTableCell>
           ))}
           </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
}
import { scaleUtc } from "d3-scale"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTheme, VictoryZoomContainer } from "victory"
import { useAccessContext } from "../../modules/AccessContext"
import { SpotWeatherTeaser } from "./common"
import { defaultColorOfParam, defaultInterpolationVictory, isLineParam, lineParamTransformation, xAxisTimeFormat } from "../../modules/layout"
import useStyles from "./styles"

const SpotWeatherGraphicOnlyTeaser = ({ loopIndex, update }) => {
    const myStyles = useStyles()
    const { t } = useTranslation()
    const accessContext = useAccessContext()
  
    const [zoom, setZoom] = useState({})
    let handleZoom = function (domain) {
      setZoom({ zoomDomain: domain })
    }
  
    const {weather} = SpotWeatherTeaser(accessContext, t, loopIndex, update) 
  
    let lineValue = (d, pIndex, pShort) => {
      let rawValue = d["param" + (pIndex + 1)]
      let parsed = parseInt(rawValue)
  
      if (isNaN(parsed)) {
        return null
      } else {
        return lineParamTransformation(pShort, parsed)
      }
    }
  
    let xAxisFormat = (timeValue) => xAxisTimeFormat(timeValue, t)
  
    return (
      <div>
        {weather && (
          <div className={myStyles.dashboardCardMedia}>
            <VictoryChart
              width={600}
              height={250}
              scale={{ x: scaleUtc() }}
              theme={VictoryTheme.material}
              containerComponent={
                <VictoryZoomContainer
                  zoomDimension='x'
                  zoomDomain={zoom.zoomDomain}
                  onZoomDomainChange={handleZoom}
                />
              }
            >
              {weather.params.map(
                (p, pIndex) =>
                  isLineParam(p[0]) && (
                    <VictoryLine
                      key={p[0]}
                      interpolation={defaultInterpolationVictory}
                      style={{
                        data: {
                          stroke: defaultColorOfParam(p[0]),
                          strokeWidth: 1,
                        },
                      }}
                      data={weather.values}
                      name={p[0]}
                      x='timeAsDate'
                      y={(d) => lineValue(d, pIndex, p[0])}
                    />
                  )
              )}
              <VictoryAxis
                tickFormat={xAxisFormat}
                style={{ tickLabels: { fontSize: 10 } }}
              />
            </VictoryChart>
          </div>
        )}
      </div>
    )
  }

  export default SpotWeatherGraphicOnlyTeaser
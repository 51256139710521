const METEO_CONSTANTS = {
    paramDescriptionType: 'param_name_fullname_unit_typeformat',
    paramWarningsType: 'warn_expressions_colors',
    maxParams: 20,
    spotweatherParamDescType: 'header_text',
    spotweatherParamColorType: 'header_warn_colors',
    spotweatherParamWarnExprType: 'header_warn_expression',
    spotweatherParamValueType: 'values',
}

export {
    METEO_CONSTANTS
}
  
  
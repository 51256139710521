import PostGraphileApi from '../modules/PostGraphileApi';
import React from 'react';
class MyDialogTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], 
    };
  };
  
  // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  // -> The recommended upgrade path for most use cases is to move data-fetching into componentDidMount
  componentDidMount() { // wird beim Öffnen der Dialogmaske aufgerufen (nach dem 1. Rendern)
    //console.log(this.constructor.name+'.componentDidMount()');
    const request="{ teletextJpgs(condition:{}) { nodes { filename,imageBase64}}}"
    const STATEKEY='data';
    const KEY='agrarTexts';     
    this.loadGraphql(request,STATEKEY,KEY,true); 
  }
  
loadGraphql(graphqlQuery,stateKey,graphqlKey,isComponentDidMount=false) { 
  console.log(this.constructor.name+" graphqlQuery="+graphqlQuery);
  console.log("url="+this.props.environment.sc_api.url)
  const url="http://grib-gis-01.local/service-center/graphql"
  PostGraphileApi.fetchRequest(
    url, // this.props.environment.sc_api.url,
    PostGraphileApi.httpRequestData(graphqlQuery, null), // this.props.accessToken <-> null
    this.constructor.name + ".loadGraphql() fehler"
  )
  .then(({data}) => { // <- hier in data kommt die komplette antwort!!!
    console.log(JSON.stringify(data[graphqlKey].nodes)) // das aus der response rausholen, was wir wollen
    this.setState({[stateKey]:data[graphqlKey].nodes,isLoaded:true}); // ... und im state speichern 
    //return data[graphqlKey].nodes; 
  })   
  .catch((error)=> { console.log(this.constructor.name+".loadGraphql() fehler_2="+error.message); });
}

render() {
    console.log(this.constructor.name+'.render()');
    const { data } = this.state;

    return <p>Test</p>
  }
}

export default MyDialogTest
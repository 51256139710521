import { getWWSymbol } from "../../modules/layout"

const WeatherIconComponent = ( { row, pIndex, cloudsIndex } ) => {
    if (pIndex !== undefined) {
      let wwCode = parseFloat(row["param" + (pIndex + 1)])
      let wwClouds
      if (cloudsIndex !== undefined) {
      wwClouds = parseFloat(row["param" + (cloudsIndex)])
      } else {
        wwClouds = 0
      }
      let wwSymbolNumber = getWWSymbol(wwCode, wwClouds)
       
      if (wwSymbolNumber !== undefined) {
        let imgName = "/images/w" + wwSymbolNumber + ".png"
        let size = 20
        return (
          <>
            <img src={imgName} width={size} height={size} alt="specific weather icon"/>
          </>
        )
      }
    }
    return ("")
  }
  
  export { WeatherIconComponent }
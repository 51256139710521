import React from "react"
import { SpotWeatherDataTableMeteoTeaser } from "../SpotWeatherDataTable/SpotWeatherDataTableMeteoTeaser"
import useStyles from "./styles"
import { useAccessContext } from "../../modules/AccessContext"
import { SpotWeatherTeaser } from "./common"
import { useTranslation } from "react-i18next"

const SpotWeatherTableOnlyTeaser = ({loopIndex, update }) => {
    const { t } = useTranslation()
    const myStyles = useStyles()
    const accessContext = useAccessContext() 
    const {weather} = SpotWeatherTeaser(accessContext, t, loopIndex, update) 

    return (    
      <div className={myStyles.dashboardCardMedia}>
    
        {weather && (
          <SpotWeatherDataTableMeteoTeaser
          values={weather.values}
          params={weather.params}
          warnings={weather.warnings}
          colors={weather.colors}
          />
        ) }
      </div>
    )
  }  
  
  export { SpotWeatherTableOnlyTeaser }
  




import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import {Button,Tooltip} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
//import Icon from '@mui/material/Icon';
import DeleteIcon from '@mui/icons-material/Delete';
//import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
//import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
//import { isNullOrUndefined } from 'util';

// -----------------------------------------------------------------------

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
});


function IconLabelButtons(props) {
  const { classes, handleOpenSaveDialog, handleNew, handleOpenDeleteDialog} = props; // , handleLoad
  return (
    <div>
 
      {/*<Tooltip title="Lade Agrar Text von DB">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleLoad}>
          <CloudDownloadIcon className={classes.rightIcon} />
          Laden
        </Button>
      </Tooltip>*/} 

{(!(props.isEmptyTable || props.isNullId))?
      <Tooltip title="Lösche Agrar Text aus DB">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleOpenDeleteDialog}>
          <DeleteIcon className={classes.rightIcon} />
          Löschen
        </Button>
      </Tooltip> 
:''}  

      <Tooltip title="Neuer Agrar Text">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleNew}>
          <AddIcon className={classes.rightIcon}/>
          Neu
        </Button>
      </Tooltip> 

{/*
{(!(props.isEmptyTable || props.isNullId || Helper.isNullOrUndefined(props.condition_keyvals))?
      <Tooltip title="Kopiere Agrar Text">
        <Button variant="contained" color="primary" className={classes.button} onClick={()=>{handleNew(false);}}>
          <AddIcon className={classes.rightIcon}/>
          Copy
        </Button>
      </Tooltip> 
:''}  
*/}      
{(!props.isEmptyTable)?      
      <Tooltip title="Speichere Agrar Text in DB">
        <Button variant="contained" color="primary" className={classes.button} onClick={handleOpenSaveDialog}>
          <DoneIcon className={classes.rightIcon}/>
          Speichern
        </Button>
      </Tooltip>  
:''}  
</div>

  );
}

IconLabelButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IconLabelButtons);